import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'allPermissionTemplateService',
  methods: [{
    name: 'application',
    url: '/allPermissionTemplate/method/application',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dml-ft" }, [
    _c("p", { staticClass: "dml-dec" }, [_vm._v("网页应用")]),
    _c(
      "div",
      { staticClass: "dml-form" },
      [
        _c(
          "el-form",
          {
            ref: "appH5Form",
            attrs: {
              "label-position": "top",
              model: _vm.appH5Form,
              rules: _vm.appH5Rules,
              "label-width": "80px"
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "一行个数", prop: "w" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择个数" },
                    on: { change: _vm.onWchange },
                    model: {
                      value: _vm.appH5Form.w,
                      callback: function($$v) {
                        _vm.$set(_vm.appH5Form, "w", $$v)
                      },
                      expression: "appH5Form.w"
                    }
                  },
                  [
                    _c("el-option", { attrs: { label: "1个", value: "1" } }),
                    _c("el-option", { attrs: { label: "2个", value: "2" } })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._l(_vm.appH5Form.h5s, function(h5, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    _c("span", [_vm._v("网页应用" + _vm._s(index + 1))]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "跳转链接" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入跳转链接",
                            clearable: ""
                          },
                          model: {
                            value: h5.url,
                            callback: function($$v) {
                              _vm.$set(h5, "url", $$v)
                            },
                            expression: "h5.url"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "图片上传（500kB以内）",
                          prop: "h5s." + index + ".image",
                          rules: {
                            required: true,
                            message: "请上传图片",
                            trigger: "change"
                          }
                        }
                      },
                      [
                        _c("cropper", {
                          attrs: {
                            initUrl: h5.image,
                            conf: _vm.imageItem,
                            fixedNumber: _vm.imageItem.fixedNumber,
                            upFile: _vm.imageItem.upFunc,
                            disabled: _vm.imageItem.disabled,
                            upFileUrl: _vm.imageItem.upFileUrl,
                            geFile: _vm.imageItem.geFileUrlFunc,
                            limit: _vm.imageItem.limit,
                            accept: _vm.imageItem.accept,
                            dataIndex: index
                          },
                          on: { "on-change": _vm.editorChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            })
          ],
          2
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mainsrp-operation" },
      [
        _c(
          "el-button",
          {
            staticClass: "dy-button-small",
            attrs: { type: "button", round: "" },
            on: { click: _vm.onCancel }
          },
          [_vm._v("取消")]
        ),
        _c(
          "el-button",
          {
            staticClass: "dy-button-small",
            attrs: { type: "danger", round: "" },
            on: { click: _vm.onDelModule }
          },
          [_vm._v("删除")]
        ),
        _c(
          "el-button",
          {
            staticClass: "dy-button-small",
            attrs: { type: "success", round: "" },
            on: { click: _vm.onSave }
          },
          [_vm._v("确定")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
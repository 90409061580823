import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PaymentSubjectItemUtil',
  data: function data() {
    return {
      paymentItems: [],
      checkList: [],
      checkboxListMap: {},
      checkboxValueMap: {},
      sortKey: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {//this.initData()
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      this.paymentItems = [];

      if (this.dataSource["items"] && this.dataSource["items"].length > 0) {
        this.paymentItems = this.dataSource["items"];
      } else {}

      this.initCheckboxValue();
    },
    clearRadio: function clearRadio(k) {
      if (this.checkList.length > 0) {
        var index = this.checkList.indexOf(this.checkboxValueMap[k]);

        if (index !== -1) {
          this.checkList.splice(index, 1);
        }
      }

      this.checkboxValueMap[k] = '';
      this.handleChange();
    },
    initCheckboxValue: function initCheckboxValue() {
      this.checkList = [];
      var checkboxValueMap = {};
      var checkboxListMap = {}; // key 为itemId,选择类型，// 00 可选，01必填，10 同项选一不必填，11  同项选一必填

      var _sortKey = [];

      if (this.paymentItems.length > 0) {
        this.paymentItems.sort(function (a, b) {
          return a.aesSort - b.aesSort;
        });

        for (var i in this.paymentItems) {
          var item = this.paymentItems[i];
          var key = "".concat(item.itemId, ",").concat(item.selType, ",").concat(item.name);

          if (!_sortKey.includes(key)) {
            _sortKey.push(key);
          }

          if (!checkboxListMap[key]) {
            checkboxListMap[key] = [];
          }

          if (!checkboxValueMap[key]) {
            //
            if (this.getIsRadioForKey(key)) {
              checkboxValueMap[key] = '';
            } else {
              checkboxValueMap[key] = [];
            }
          } // item.ikey = i


          var _item = {
            "ikey": item.ikey,
            'itemId': item.itemId,
            'name': item.name,
            'amount': item.amount,
            'aesSort': item.aesSort,
            'about': item.about,
            'selType': item.selType,
            // 'checked': false,
            'disabled': false
          };

          if (_item.selType == '01' || _item.selType == '11') {
            // _item['checked'] = true
            if (this.getIsRadioForKey(key)) {
              if (checkboxValueMap[key] == '') {
                checkboxValueMap[key] = _item.ikey; //默认选中值

                this.checkList.push(_item.ikey);
              }
            } else {
              _item.disabled = true;
              checkboxValueMap[key].push(_item.ikey); //默认选中值

              this.checkList.push(_item.ikey);
            }
          }

          checkboxListMap[key].push(_item);
        }
      }

      this.sortKey = _sortKey;
      this.checkboxListMap = checkboxListMap;
      this.checkboxValueMap = checkboxValueMap;
      this.handleChange();
    },
    getIsRadioForKey: function getIsRadioForKey(key) {
      if ((key === null || key === void 0 ? void 0 : key.indexOf(',')) >= 0) {
        var _ks = key.split(',');

        if (_ks.length >= 2) {
          switch (_ks[1]) {
            case '00':
              return false;

            case '01':
              return false;

            case '10':
              return true;

            case '11':
              return true;

            default:
              return false;
          }
        }
      }

      return false;
    },
    isRadioRequired: function isRadioRequired(k) {
      var ks = k.split(',');

      if (ks.length >= 2) {
        if (ks[1] == '10') {
          return true;
        } else {
          return false;
        }
      } else {
        if (k == '10') {
          return true;
        } else {
          return false;
        }
      }
    },
    radioGroupChange: function radioGroupChange(evt) {
      this.checkList.push(evt);
      this.handleChange();
    },
    checkboxGroupChange: function checkboxGroupChange(evt) {
      this.checkList.push(evt);
      this.handleChange();
    },
    handleChange: function handleChange() {
      var _v = {
        checkboxValueMap: this.checkboxValueMap,
        checkList: this.checkList
      }; // console.log("handleChange")
      // // console.log(this.checkboxValueMap)
      // // console.log(this.checkList)
      // for (let key in this.checkboxValueMap) {
      //   console.log(key)
      // }

      this.$emit('on-change', _v, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  },
  filters: {
    parentalIdentityFilter: function parentalIdentityFilter(val) {
      if (val === 0) {
        return "其他";
      } else if (val === 1) {
        return "父亲";
      } else if (val === 2) {
        return "母亲";
      } else if (val === 3) {
        return "爷爷";
      } else if (val === 4) {
        return "奶奶";
      } else if (val === 5) {
        return "外公";
      } else if (val === 6) {
        return "外婆";
      }
    },
    timeFilter: function timeFilter(val) {
      if (val > 0) {
        return moment.parseZone(val).utcOffset('+08:00').format('YYYY-MM-DD HH:mm:ss');
      } else {
        return "";
      }
    },
    amountFilter: function amountFilter(val) {
      if (val > 0) {
        return "：" + parseFloat(val / 100).toFixed(2) + "元";
      } else {
        return "：0元";
      }
    },
    selTypeFilter: function selTypeFilter(val) {
      if (val === '00') {
        return '【可选】';
      } else if (val === '01') {
        return '【必选】';
      } else if (val === '10') {
        return '【同项选一不必填】';
      } else if (val === '11') {
        return '【同项选一必填】';
      } else {
        return '';
      }
    },
    aboutFilter: function aboutFilter(val) {
      if (val !== '') {
        return "(" + val + ")";
      } else {
        return '';
      }
    },
    payMethodFilter: function payMethodFilter(val) {
      if (val === 'CHANNEL_TYPE_CASH') {
        return '线下收款';
      } else {
        return '线上交费';
      }
    }
  }
};
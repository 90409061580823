import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'graduationFilingService',
  methods: [{
    name: 'list',
    url: '/graduationFiling/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/graduationFiling/method/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
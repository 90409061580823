var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isloaded
        ? _c("el-cascader", {
            staticStyle: { width: "100%" },
            attrs: {
              placeholder: "省/市/区",
              props: _vm.props,
              filterable: ""
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.value,
              callback: function($$v) {
                _vm.value = $$v
              },
              expression: "value"
            }
          })
        : _vm._e(),
      _c("el-input", {
        staticStyle: { "margin-top": "10px" },
        attrs: { placeholder: "请输入完整校区地址" },
        on: { change: _vm.handleChange },
        model: {
          value: _vm.address,
          callback: function($$v) {
            _vm.address = $$v
          },
          expression: "address"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
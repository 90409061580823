var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { placeholder: "请选择" },
          on: { change: _vm.editorChange },
          model: {
            value: _vm.nameType,
            callback: function($$v) {
              _vm.nameType = $$v
            },
            expression: "nameType"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      ),
      _vm.nameType === "0"
        ? _c("div", { staticClass: "mainsrp-form" }, [
            _c(
              "div",
              { staticClass: "m-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "classNameForm",
                    attrs: {
                      "label-position": "top",
                      model: _vm.classNameForm,
                      rules: _vm.classNameRules,
                      "label-width": "100px",
                      "show-message": false,
                      size: "medium"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-mn" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "自建班名（最多6个汉字）",
                              prop: "className",
                              size: "small"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder:
                                  "自定义名称年级内唯一（升级时不变）",
                                maxlength: "6"
                              },
                              on: { blur: _vm.editorChange },
                              model: {
                                value: _vm.classNameForm.className,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.classNameForm,
                                    "className",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "classNameForm.className"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
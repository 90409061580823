var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dml-ft" }, [
    _c(
      "p",
      {
        staticClass: "dml-dec",
        staticStyle: {
          "border-bottom": "1px solid #eee",
          "padding-bottom": "8px",
          "font-size": "16px",
          color: "#333",
          "font-weight": "700"
        }
      },
      [_vm._v("五翻图")]
    ),
    _c(
      "div",
      { staticClass: "dml-form" },
      [
        _c(
          "el-form",
          {
            ref: "fiveFlipForm",
            attrs: {
              "label-position": "top",
              model: _vm.fiveFlipForm,
              "label-width": "80px"
            }
          },
          [
            _vm._l(_vm.fiveFlipForm.fiveFlips, function(fiveFlip, index) {
              return [
                _c(
                  "div",
                  { key: index },
                  [
                    _c("span", [_vm._v("五翻图" + _vm._s(index + 1))]),
                    _c(
                      "el-form-item",
                      { attrs: { label: "跳转链接" } },
                      [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入跳转链接",
                            clearable: ""
                          },
                          model: {
                            value: fiveFlip.url,
                            callback: function($$v) {
                              _vm.$set(fiveFlip, "url", $$v)
                            },
                            expression: "fiveFlip.url"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "图片上传（460x300像素，500kB以内）",
                          prop: "fiveFlips." + index + ".image",
                          rules: {
                            required: true,
                            message: "请上传图片",
                            trigger: "change"
                          }
                        }
                      },
                      [
                        _c("cropper", {
                          attrs: {
                            initUrl: fiveFlip.image,
                            conf: _vm.fiveFlipImageItem,
                            fixedNumber: _vm.fiveFlipImageItem.fixedNumber,
                            upFile: _vm.fiveFlipImageItem.upFunc,
                            disabled: _vm.fiveFlipImageItem.disabled,
                            upFileUrl: _vm.fiveFlipImageItem.upFileUrl,
                            geFile: _vm.fiveFlipImageItem.geFileUrlFunc,
                            limit: _vm.fiveFlipImageItem.limit,
                            accept: _vm.fiveFlipImageItem.accept,
                            dataIndex: index
                          },
                          on: { "on-change": _vm.editorChange }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            })
          ],
          2
        ),
        _c(
          "el-button",
          {
            staticStyle: { "margin-bottom": "20px", "margin-top": "20px" },
            attrs: { type: "primary", round: "", size: "mini" },
            on: { click: _vm.addFiveFlip }
          },
          [_vm._v("添加一张")]
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mainsrp-operation" },
      [
        _c(
          "el-button",
          {
            staticClass: "dy-button-small",
            attrs: { type: "button", round: "" },
            on: { click: _vm.onCancel }
          },
          [_vm._v("取消")]
        ),
        _c(
          "el-button",
          {
            staticClass: "dy-button-small",
            attrs: { type: "danger", round: "" },
            on: { click: _vm.onDelModule }
          },
          [_vm._v("删除")]
        ),
        _c(
          "el-button",
          {
            staticClass: "dy-button-small",
            attrs: { type: "success", round: "" },
            on: { click: _vm.onSave }
          },
          [_vm._v("确定")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
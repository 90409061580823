var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _c("h3", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.student.relatedStudentName) +
                  _vm._s("(" + _vm.student.className + ")")
              )
            ])
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", [_vm._v("提交时间：ddsdsds")])
          ]),
          _c("el-col", { attrs: { span: 24 } }, [
            _c("span", [
              _vm._v(
                "提交人：" +
                  _vm._s(_vm.student.relatedStudentName) +
                  " " +
                  _vm._s(
                    _vm._f("parentalIdentityFilter")(
                      _vm.student.parentalIdentity
                    )
                  )
              )
            ])
          ])
        ],
        1
      ),
      _vm.config.models.length > 0
        ? _c("modelForm", { ref: "model", attrs: { config: _vm.config } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 4 } }, [_vm._v("班主任")]),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.classAdviser,
                    callback: function($$v) {
                      _vm.classAdviser = $$v
                    },
                    expression: "classAdviser"
                  }
                },
                _vm._l(_vm.staffs, function(item) {
                  return _c("el-option", {
                    key: item._id,
                    attrs: { label: item.name, value: item._id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider"),
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 4 } }, [_vm._v("副班主任")]),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.classAdviserDeputy,
                    callback: function($$v) {
                      _vm.classAdviserDeputy = $$v
                    },
                    expression: "classAdviserDeputy"
                  }
                },
                _vm._l(_vm.staffs, function(item) {
                  return _c("el-option", {
                    key: item._id,
                    attrs: { label: item.name, value: item._id }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("el-divider"),
      _vm._l(_vm.subjects, function(item, index) {
        return [
          _c(
            "el-row",
            { key: index },
            [
              _c("el-col", { attrs: { span: 4 } }, [_vm._v(_vm._s(item.name))]),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "请选择" },
                      on: { change: _vm.handleChange },
                      model: {
                        value: item.teachers,
                        callback: function($$v) {
                          _vm.$set(item, "teachers", $$v)
                        },
                        expression: "item.teachers"
                      }
                    },
                    _vm._l(_vm.staffs, function(sitem) {
                      return _c("el-option", {
                        key: sitem._id,
                        attrs: { label: sitem.name, value: sitem._id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-divider", { key: index })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
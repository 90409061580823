var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _c(
          "div",
          { staticClass: "dy-form-hd" },
          [
            _c("h3", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.title))]),
            _c("AuthorityExplain")
          ],
          1
        )
      ]),
      _c("modelForm", { ref: "model", attrs: { config: _vm.config } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VueEditor } from 'vue2-editor';
import utils from '@/libs/util';
import axios from 'axios';
export default {
  name: 'Editor',
  data: function data() {
    return {
      content: '',
      editorSettings: {}
    };
  },
  components: {
    VueEditor: VueEditor
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
  },
  props: {
    value: String,
    valName: String,
    isOnlyRead: Boolean,
    upFile: Function,
    placeholder: String
  },
  created: function created() {
    this.content = this.value; // this.editorSettings.readOnly = this.isOnlyRead
  },
  computed: {
    disabled: function disabled() {
      return this.isOnlyRead;
    },
    isBase64: function isBase64() {
      //console.debug(this.upFile)
      if (this.upFile) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    updateData: function updateData(val) {
      //console.debug(val)
      this.$emit('on-change', val, this.valName);
    },
    handleImageAdded: function handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      //console.debug(file)
      // const self = this
      var reader = new window.FileReader();

      reader.onload = function (ef) {
        var _this = this;

        // const data = {
        //   filename: file.name,
        //   type: file.type,
        //   content: ef.target.result,
        //   group: 'editor'
        // }
        if (this.upFileUrl) {
          var formData = new FormData();
          formData.append('file', file);
          axios({
            headers: {
              Authorization: "Bearer ".concat(utils.cookies.get('token')),
              'Content-Type': 'multipart/form-data;charse=UTF-8'
            },
            url: this.upFileUrl,
            method: 'post',
            data: formData,
            processData: false,
            // 告诉axios不要去处理发送的数据(重要参数)
            contentType: false // 告诉axios不要去设置Content-Type请求头

          }).then(function (res) {
            //console.debug(res)
            var currentPic = res.data;

            if (_this.geFile) {
              currentPic = _this.geFile({
                path: {
                  id: res.data
                }
              });
            }

            Editor.insertEmbed(cursorLocation, 'image', currentPic);
            resetUploader();
          });
        } // if (self.upFile) {
        //   self.upFile({ body: data })
        //     .then((da) => {
        //       //console.debug(da)
        //       const url = da.url // Get url from response
        //       Editor.insertEmbed(cursorLocation, 'image', url)
        //       resetUploader()
        //     }).catch((err) => { //console.debug(err) })
        // }

      };

      reader.readAsDataURL(file);
    }
  },
  watch: {
    value: function value(val) {
      this.content = val;
    },
    content: function content(val) {
      //console.debug(val)
      this.updateData(val);
    }
  }
};
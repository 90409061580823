import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'enrolmentPlanOrderService',
  methods: [{
    name: 'list',
    url: '/enrolmentPlanOrder/method/list/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/enrolmentPlanOrder/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/enrolmentPlanOrder/method/order',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top", "label-width": "80px" } },
        [
          _vm._l(_vm.paymentItems, function(item) {
            return [
              _c(
                "el-form-item",
                { key: item.ikey, attrs: { label: item.rename } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "50%" },
                    attrs: {
                      "controls-position": "right",
                      min: 0,
                      precision: 2,
                      size: "small"
                    },
                    on: { blur: _vm.handleChange },
                    model: {
                      value: item.amount,
                      callback: function($$v) {
                        _vm.$set(
                          item,
                          "amount",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "item.amount"
                    }
                  })
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
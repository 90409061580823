var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.customFormData, function(cf, index) {
        return [
          _c(
            "div",
            { key: cf.key + index, staticStyle: { "margin-bottom": "8px" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 4 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入内容" },
                        model: {
                          value: cf.name,
                          callback: function($$v) {
                            _vm.$set(cf, "name", $$v)
                          },
                          expression: "cf.name"
                        }
                      })
                    ],
                    1
                  ),
                  cf.type != "NATION" &&
                  cf.type != "BM" &&
                  cf.type != "SELECT" &&
                  cf.type != "MULTIPLE_SELECT" &&
                  cf.type != "RADIO" &&
                  cf.type != "CHECKBOX"
                    ? _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入填写说明" },
                            model: {
                              value: cf.about,
                              callback: function($$v) {
                                _vm.$set(cf, "about", $$v)
                              },
                              expression: "cf.about"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  cf.type === "NATION" ||
                  cf.type === "BM" ||
                  cf.type === "SELECT" ||
                  cf.type === "MULTIPLE_SELECT" ||
                  cf.type === "RADIO" ||
                  cf.type === "CHECKBOX"
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入填写说明" },
                            model: {
                              value: cf.about,
                              callback: function($$v) {
                                _vm.$set(cf, "about", $$v)
                              },
                              expression: "cf.about"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  cf.type === "NATION" ||
                  cf.type === "BM" ||
                  cf.type === "SELECT" ||
                  cf.type === "RADIO"
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择默认值",
                                clearable: ""
                              },
                              model: {
                                value: cf.value,
                                callback: function($$v) {
                                  _vm.$set(cf, "value", $$v)
                                },
                                expression: "cf.value"
                              }
                            },
                            _vm._l(cf.selVal, function(o, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: o.name, value: o.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  cf.type === "MULTIPLE_SELECT" || cf.type === "CHECKBOX"
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择默认值",
                                clearable: "",
                                multiple: ""
                              },
                              on: {
                                change: function($event) {
                                  return _vm.onMultipleChange(cf, index)
                                }
                              },
                              model: {
                                value: cf.value,
                                callback: function($$v) {
                                  _vm.$set(cf, "value", $$v)
                                },
                                expression: "cf.value"
                              }
                            },
                            _vm._l(cf.selVal, function(o, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: o.name, value: o.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: cf.mustHave,
                            callback: function($$v) {
                              _vm.$set(cf, "mustHave", $$v)
                            },
                            expression: "cf.mustHave"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "非必填", value: "0" }
                          }),
                          _c("el-option", {
                            attrs: { label: "必填", value: "1" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c(
                      "div",
                      { staticClass: "btn-s" },
                      [
                        index < _vm.customFormData.length - 1
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "0px" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveDown(index)
                                  }
                                }
                              },
                              [_vm._v("下移")]
                            )
                          : _vm._e(),
                        index < _vm.customFormData.length - 1
                          ? _c("span", [_vm._v("|")])
                          : _vm._e(),
                        index > 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "0px" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.moveUp(index)
                                  }
                                }
                              },
                              [_vm._v("上移")]
                            )
                          : _vm._e(),
                        index > 0 ? _c("span", [_vm._v("|")]) : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "0px", color: "red" },
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.delSelVal(index)
                              }
                            }
                          },
                          [_vm._v("移除")]
                        ),
                        _c("span", [_vm._v("|")]),
                        cf.havRelation == 0 &&
                        cf.isRelation == 0 &&
                        (cf.type === "SELECT" || cf.type === "RADIO")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "0px" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onAssociation(index)
                                  }
                                }
                              },
                              [_vm._v("选项关联")]
                            )
                          : _vm._e(),
                        cf.havRelation == 0 &&
                        cf.isRelation == 0 &&
                        (cf.type === "SELECT" || cf.type === "RADIO")
                          ? _c("span", [_vm._v("|")])
                          : _vm._e(),
                        cf.havRelation == 1 &&
                        cf.isRelation == 0 &&
                        (cf.type === "SELECT" || cf.type === "RADIO")
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "0px" },
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onAssociation(index)
                                  }
                                }
                              },
                              [_vm._v("编辑关联")]
                            )
                          : _vm._e(),
                        cf.havRelation == 1 &&
                        cf.isRelation == 0 &&
                        (cf.type === "SELECT" || cf.type === "RADIO")
                          ? _c("span", [_vm._v("|")])
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]
      }),
      _c(
        "el-button",
        {
          attrs: { type: "text" },
          on: {
            click: function($event) {
              _vm.dialogVisible = true
            }
          }
        },
        [_vm._v("添加表单项")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "表单字段",
            visible: _vm.dialogVisible,
            width: "30%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "div",
                      {
                        staticClass: "clearfix",
                        attrs: { slot: "header" },
                        slot: "header"
                      },
                      [_c("span", [_vm._v("预设字段")])]
                    ),
                    _c("el-divider"),
                    _vm._l(_vm.systemFields, function(sf) {
                      return _c(
                        "div",
                        { key: sf._id, staticClass: "text item" },
                        [
                          _vm.systemFields.length > 0 &&
                          sf._id === "customField"
                            ? _c("el-divider")
                            : _vm._e(),
                          _c(
                            "el-row",
                            [
                              _c("el-col", { attrs: { span: 22 } }, [
                                _vm._v(_vm._s(sf.name))
                              ]),
                              _c(
                                "el-col",
                                { attrs: { span: 2 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function($event) {
                                          return _vm.addField(sf)
                                        }
                                      }
                                    },
                                    [_vm._v("添加")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    })
                  ],
                  2
                )
              ])
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c("CustomFieldUtil", {
        attrs: { customFieldDialogVisible: _vm.customFieldDialogVisible },
        on: { "on-change": _vm.addCustomField }
      }),
      _c("CustomFieldEditUtil", {
        attrs: {
          customFieldEditDialogVisible: _vm.customFieldEditDialogVisible,
          fieldData: _vm.fieldData
        },
        on: { "on-change": _vm.addCustomField }
      }),
      _c("OptionAssociationUtil", {
        attrs: {
          optionAssociationDialogVisible: _vm.optionAssociationDialogVisible,
          fieldData: _vm.fieldData,
          customFormData: _vm.customFormData
        },
        on: { "on-change": _vm.addAssociation }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PaymentSubjectItemUtil',
  data: function data() {
    return {
      paymentItems: [],
      formData: {
        paymentSubjectItems: [{
          itemId: "",
          about: "",
          amount: "",
          selType: "",
          ikey: ""
        }]
      },
      paymentItemRules: {
        name: [{
          required: true,
          message: '请输入字段名称',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择字段类型',
          trigger: 'change'
        }]
      },
      isDisabled: false
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {//this.initData()
  },
  computed: {},
  components: {},
  methods: {
    addSelVal: function addSelVal() {
      this.formData.paymentSubjectItems.push({
        itemId: "",
        about: "",
        amount: "",
        selType: ""
      });
    },
    delSelVal: function delSelVal(index) {
      this.formData.paymentSubjectItems.splice(index, 1);
      this.handleChange();
    },
    //下移
    moveDown: function moveDown(index) {
      if (this.formData.paymentSubjectItems[index + 1]) {
        this.formData.paymentSubjectItems[index] = this.formData.paymentSubjectItems.splice(index + 1, 1, this.formData.paymentSubjectItems[index])[0];
      }

      this.handleChange();
    },
    //上移
    moveUp: function moveUp(index) {
      if (this.formData.paymentSubjectItems[index - 1]) {
        this.formData.paymentSubjectItems[index] = this.formData.paymentSubjectItems.splice(index - 1, 1, this.formData.paymentSubjectItems[index])[0];
      }

      this.handleChange();
    },
    //获取缴费项目
    getPaymentItem: function getPaymentItem() {
      var _this = this;

      this.$api.apis.paymentItemService.all({
        params: {
          "suitableApply.groupId": this.$route.meta.groupId
        }
      }).then(function (data) {
        _this.paymentItems = data;
      });
    },
    initData: function initData() {
      if (this.conf.disabled) {
        this.isDisabled = true;
      }

      this.formData.paymentSubjectItems = [];

      if (this.dataSource[this.conf.valName] && this.dataSource[this.conf.valName].length > 0) {
        for (var i = 0; i < this.dataSource[this.conf.valName].length; i++) {
          var _item = this.dataSource[this.conf.valName][i];
          var item = {
            itemId: "",
            about: "",
            amount: "",
            selType: ""
          };
          item.itemId = _item.itemId;
          item.about = _item.about;
          item.selType = _item.selType;
          item.amount = parseFloat(_item.amount / 100).toFixed(2);
          item.ikey = _item.ikey;
          this.formData.paymentSubjectItems.push(item);
        }

        this.handleChange();
      } else {
        this.formData.paymentSubjectItems = [{
          itemId: "",
          about: "",
          amount: "",
          selType: "",
          ikey: ""
        }];
      }

      this.getPaymentItem();
    },
    handleChange: function handleChange() {
      var _v = [];

      for (var i = 0; i < this.formData.paymentSubjectItems.length; i++) {
        var _paymentSubjectItem = this.formData.paymentSubjectItems[i];

        if (_paymentSubjectItem.itemId != "" || _paymentSubjectItem.amount != "" && _paymentSubjectItem.amount != undefined || _paymentSubjectItem.selType !== "") {
          _v.push(_paymentSubjectItem);
        }
      }

      this.$emit('on-change', _v, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
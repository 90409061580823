import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'studentChargeReconciliationService',
  methods: [{
    name: 'list',
    url: '/studentChargeReconciliation/method/list',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  inject: ['customFieldRefresh'],
  name: 'OptionAssociationUtil',
  data: function data() {
    return {
      dialogVisible: false,
      fatherData: [],
      sonData: [],
      fatherRadio: '',
      sonCheck: [],
      associationData: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object,
    optionAssociationDialogVisible: Boolean,
    fieldData: Object,
    customFormData: Array
  },
  created: function created() {},
  computed: {},
  components: {},
  methods: {
    handleClose: function handleClose() {
      this.dialogVisible = false;
      this.fatherRadio = "";
      this.sonCheck = [];
      this.customFieldRefresh();
    },
    onSubmit: function onSubmit() {
      // if (this.fatherRadio == ""){
      //   this.$message.error('请选择关联项');
      //   return false
      // }
      // if (!this.sonCheck || this.sonCheck.length<=0){
      //   this.$message.error('请选择被关联项');
      //   return false
      // }
      // console.log("onSubmit")
      // console.log(this.associationData)
      // let _v = {
      //   associationData:this.associationData,
      // }
      this.$emit('on-change', this.associationData);
      this.handleClose();
    },
    onClear: function onClear() {
      this.sonCheck = [];
    },
    onFatherRadioChange: function onFatherRadioChange() {
      if (this.fatherRadio != "") {
        for (var i = 0; i < this.associationData.length; i++) {
          if (this.associationData[i].fatherRadio == this.fatherRadio) {
            this.sonCheck = this.associationData[i].children;
            break;
          }
        }
      }
    },
    onSonCheckboxChange: function onSonCheckboxChange() {
      if (this.fatherRadio != "") {
        for (var i = 0; i < this.associationData.length; i++) {
          if (this.associationData[i].fatherRadio == this.fatherRadio) {
            this.associationData[i].children = this.sonCheck;
            break;
          }
        }
      }
    },
    initData: function initData() {
      if (this.dialogVisible) {
        this.associationData = [];

        if (this.fieldData && this.fieldData.selVal) {
          this.fatherData = this.fieldData.selVal;

          for (var i = 0; i < this.fatherData.length; i++) {
            this.associationData.push({
              fatherRadio: this.fatherData[i].value,
              children: []
            });
          }
        }

        this.sonData = [];

        if (this.customFormData) {
          for (var _i = 0; _i < this.customFormData.length; _i++) {
            var isAdd = true;
            var cus = this.customFormData[_i];

            if (cus.key == this.fieldData.key) {
              isAdd = false;
            }

            if (cus.havRelation == 1) {
              //是否有其他关联（父）
              isAdd = false;
            }

            if (isAdd) {
              this.sonData.push(cus); //拼装被关联数据

              if (cus.relationItem && cus.relationItem.length > 0) {
                for (var j = 0; j < cus.relationItem.length; j++) {
                  var _relationItem = cus.relationItem[j];

                  if (_relationItem.key == this.fieldData.key) {
                    for (var z = 0; z < this.associationData.length; z++) {
                      if (this.associationData[z].fatherRadio == _relationItem.value) {
                        this.associationData[z].children.push(cus.key);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  watch: {
    optionAssociationDialogVisible: {
      handler: function handler() {
        if (this.optionAssociationDialogVisible) {
          this.dialogVisible = this.optionAssociationDialogVisible;
          this.initData();
        }
      },
      // 深度观察
      deep: true
    }
  }
};
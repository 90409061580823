import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ContentUtil',
  data: function data() {
    return {
      contentDatas: [{
        key: "BREAKFAST",
        name: "早餐",
        switchValue: false,
        amount: "",
        isDisabled: true
      }, {
        key: "LUNCH",
        name: "午餐",
        switchValue: false,
        amount: "",
        isDisabled: true
      }, {
        key: "DINNER",
        name: "晚餐",
        switchValue: false,
        amount: "",
        isDisabled: true
      }, {
        key: "ADDMEALMORNING",
        name: "上午加餐",
        switchValue: false,
        amount: "",
        isDisabled: true
      }, {
        key: "ADDMEALAFTERNOON",
        name: "下午加餐",
        switchValue: false,
        amount: "",
        isDisabled: true
      }],
      totalAmount: ""
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    onSwitchChange: function onSwitchChange(index) {
      if (this.contentDatas[index].switchValue) {
        this.contentDatas[index].isDisabled = false;
      } else {
        this.contentDatas[index].amount = "";
        this.contentDatas[index].isDisabled = true;
      }

      this.onAmountChange();
    },
    onAmountChange: function onAmountChange() {
      var _totalAmount = 0;

      for (var i = 0; i < this.contentDatas.length; i++) {
        var _cd = this.contentDatas[i];

        if (_cd.amount && typeof Number(_cd.amount) === 'number') {
          var _amount = Number(_cd.amount) * 100;

          _amount = Math.round(_amount);
          _totalAmount = _totalAmount + _amount;
        }
      }

      if (_totalAmount > 0) {
        this.totalAmount = parseFloat(_totalAmount / 100).toFixed(2);
      } else {
        this.totalAmount = "";
      }

      this.handleChange();
    },
    //总金额
    onTotalAmountChange: function onTotalAmountChange() {
      this.handleChange();
    },
    initData: function initData() {
      if (this.dataSource && this.dataSource.content) {
        for (var i = 0; i < this.contentDatas.length; i++) {
          for (var j = 0; j < this.dataSource.content.length; j++) {
            if (this.contentDatas[i].key == this.dataSource.content[j].key) {
              this.contentDatas[i].switchValue = true;
              this.contentDatas[i].isDisabled = false;
              this.contentDatas[i].amount = parseFloat(this.dataSource.content[j].amount / 100).toFixed(2);
            }
          }
        }

        this.onAmountChange();
      }
    },
    handleChange: function handleChange() {
      var _v = {};
      _v.content = [];
      _v.amount = 0;

      for (var i = 0; i < this.contentDatas.length; i++) {
        var _cd = this.contentDatas[i];

        if (_cd.switchValue) {
          var _amount = Number(_cd.amount) * 100;

          _amount = Math.round(_amount);

          _v.content.push({
            key: _cd.key,
            name: _cd.name,
            amount: _amount,
            sort: i
          });
        }
      }

      if (this.totalAmount) {
        var _totalAmount = Number(this.totalAmount) * 100;

        _v.amount = Math.round(_totalAmount);
      }

      this.$emit('on-change', _v, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'customFormService',
  methods: [{
    name: 'list',
    url: '/customForm/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/customForm/method/{id}/{tag}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'add',
    url: '/customForm/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/customForm/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/customForm/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'all',
    url: '/customForm/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findSystemField',
    url: '/customForm/method/findSystemField',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'fieldkey',
    url: '/customForm/method/fieldkey',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
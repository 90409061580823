import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.join";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.trim";
import "core-js/modules/web.dom-collections.for-each";
import _typeof from "/Users/liuyutao/opt/wuxi1/campus_sys_merchant/web/node_modules/@babel/runtime/helpers/esm/typeof";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import util from '@/libs/util.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import * as config from './config';
dayjs.extend(utc);
dayjs.extend(timezone);
export default {
  name: 'Query',
  data: function data() {
    return {
      isFold: false,
      conf: config.queryConfigBase
    };
  },
  props: {
    config: Object
  },
  created: function created() {
    this.conf = config.queryConfig(this.config);
    this.initQueryString(); // //console.debug(this.$route)
    //  进行异步参数赋值

    this.conf.query.forEach(function (item) {
      item.asyncValue && item.asyncValue(item);
    });
  },
  components: {},
  methods: {
    getOpt: function getOpt(op) {
      var opt = '';

      switch (op.toLocaleUpperCase()) {
        case 'EQ':
          opt = '';
          break;

        case 'NE':
          opt = '!';
          break;

        case 'LT':
          opt = '<';
          break;

        case 'GT':
          opt = '>';
          break;

        case 'LE':
          opt = '<=';
          break;

        case 'GE':
          opt = '>=';
          break;

        case 'LIKE':
          opt = '.';
          break;

        case 'BETWEEN':
          opt = '~=';
          break;
      }

      return opt;
    },
    delOpt: function delOpt(val) {
      var i = -1;

      switch (val[0]) {
        case '!':
          i = 0;
          break;

        case '<':
          i = 0;
          break;

        case '>':
          i = 0;
          break;

        case '.':
          i = 0;
          break;
      }

      switch (val[0] + val[1]) {
        case '<=':
          i = 1;
          break;

        case '>=':
          i = 1;
          break;

        case '~=':
          i = 1;
          break;
      } //console.debug(val)
      //console.debug(val.substring(i + 1, val.length))


      return val.substring(i + 1, val.length);
    },
    getUrlParamsStr: function getUrlParamsStr() {
      var _this = this;

      var _url = [];
      this.conf.query.forEach(function (item) {
        if (item.value != null && item.value !== undefined) {
          if (typeof item.value === 'string' && item.value.trim().length > 0 || typeof item.value === 'number' && item.value || _typeof(item.value) === 'object' && item.value) {
            if (_url.length > 0) {
              _url.push('&');
            }

            _url.push(item.valName + '=');

            var opt = _this.getOpt(item.opt);

            var _v = item.value;

            if (item.type === 'date') {
              if (dayjs(item.value).isValid) {
                _v = dayjs(item.value).tz('Asia/Shanghai').valueOf() ? dayjs(item.value).tz('Asia/Shanghai').valueOf().toString() : '';
              } else {
                _v = '';
              }
            }

            if (item.type === 'amount') {
              _v = item.value * 100;
            }

            _url.push(opt + _v); //

          }
        }
      }); // //console.debug(_url.join(''))

      return _url.join('');
    },
    getUrlParams: function getUrlParams() {
      var _this2 = this;

      var _params = {};
      var _query = {}; //

      this.conf.query.forEach(function (item) {
        if (item.value != null && item.value !== undefined) {
          if (item.value.constructor === String && item.value.trim().length > 0 || item.value.constructor === Number && (item.value || item.value === 0) || item.value.constructor === Object && item.value || item.value.constructor === Array && item.value.length > 0) {
            var opt = _this2.getOpt(item.opt);

            var _v = item.value;

            if (item.type === 'date') {
              if (dayjs(item.value).isValid) {
                _v = dayjs(item.value).tz('Asia/Shanghai').valueOf() ? dayjs(item.value).tz('Asia/Shanghai').valueOf().toString() : '';
              } else {
                _v = '';
              }
            }

            if (item.type === 'amount') {
              _v = item.value * 100;
            }

            _params[item.valName] = opt + _v;
            _query[item.valName] = opt + _v; //
          }
        }
      }); //

      if (util.other.objectCount(_query) > 0) {
        this.$router.push({
          query: _query
        });
      }

      return _params;
    },
    getUrlParamsPage: function getUrlParamsPage() {
      var _this3 = this;

      var _params = {};
      var _query = {}; //

      this.conf.query.forEach(function (item) {
        if (item.value != null && item.value !== undefined) {
          if (item.value.constructor === String && item.value.trim().length > 0 || item.value.constructor === Number && (item.value || item.value === 0) || item.value.constructor === Object && item.value || item.value.constructor === Array && item.value.length > 0) {
            var opt = _this3.getOpt(item.opt);

            var _v = item.value;

            if (item.type === 'date') {
              if (dayjs(item.value).isValid) {
                _v = dayjs(item.value).valueOf();
              } else {
                _v = '';
              }
            }

            if (item.type === 'amount') {
              _v = item.value * 100;
            }

            _params[item.valName] = opt + _v;
            _query[item.valName] = opt + _v; //
          }
        }
      }); // page

      if (this.$route.query.page) {
        _query.page = this.$route.query.page;
        _params.page = this.$route.query.page;
      } //


      if (util.other.objectCount(_query) > 0) {
        this.$router.push({
          query: _query
        });
      }

      return _params;
    },
    initQueryString: function initQueryString() {
      var _this4 = this;

      //console.log(this.$route.query)
      if (this.$route.query) {
        (function () {
          var query = _this4.$route.query;

          var _loop = function _loop(k) {
            _this4.conf.query.forEach(function (q) {
              if (q.valName === k) {
                var v = _this4.delOpt(query[k]);

                if (q.type === 'date') {
                  q.value = new Date(parseInt(v));
                } else {
                  q.value = v;
                }
              }
            });
          };

          for (var k in query) {
            _loop(k);
          }
        })();
      }
    },
    onFold: function onFold() {
      this.isFold = !this.isFold;
    },
    editorChange: function editorChange(val, valName) {
      this.conf.query.forEach(function (q) {
        if (q.valName === valName) {
          q.value = val;
        }
      });
    }
  }
};
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import utils from '@/libs/util';
import ExcelUpUtil from '../util/ExcelUpUtil';
export default {
  mixins: [utils.mixin.modelMixin, utils.mixin.modelEditMixin],
  provide: function provide() {
    return {
      refresh: this.refresh
    };
  },
  name: 'wagesAdd',
  data: function data() {
    var _this = this;

    var checkExcelFile = function checkExcelFile(rule, value, callback) {
      if (!_this.formData.excelFile.name) {
        return callback(new Error('请上传上传表格'));
      } else {
        callback();
      }
    };

    return {
      formData: {
        affiliationMonth: "",
        excelFile: {}
      },
      rules: {
        affiliationMonth: [{
          type: 'date',
          required: true,
          message: '请选择所属月份',
          trigger: 'change'
        }],
        excelFile: [{
          validator: checkExcelFile,
          trigger: 'blur'
        }]
      },
      errMessages: [],
      failCount: 0,
      successCount: 0,
      repeatCount: 0
    };
  },
  created: function created() {
    this.errMessages = [];
    this.failCount = 0;
    this.successCount = 0;
    this.repeatCount = 0;
  },
  mounted: function mounted() {
    this.$nextTick(function () {});
  },
  components: {
    ExcelUpUtil: ExcelUpUtil
  },
  methods: {
    goBack: function goBack() {
      this.$store.dispatch('d2admin/page/close', {
        tagName: this.$route.path
      });
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!window.FileReader) {
            window.alert('您的浏览器版本过低');
          }

          _this2.$confirm('是否确认上传?', '导入确认', {
            confirmButtonText: '确认上传',
            cancelButtonText: '取消返回',
            type: 'warning'
          }).then(function () {
            // this.$message({
            //   type: 'success',
            //   message: '删除成功!'
            // });
            var _affiliationMonth = 0;

            if (_this2.formData.affiliationMonth) {
              _affiliationMonth = parseInt(_this2.formData.affiliationMonth);
            } // 采用简介上传


            var _formData = new FormData();

            _formData.append('file', _this2.formData.excelFile);

            _formData.append('affiliationMonth', _affiliationMonth);

            var _url = "";

            if (window.location.host.indexOf('localhost') > -1) {
              _url = 'http://localhost:8091/api/masterWages/method/wagesPublish/' + _affiliationMonth;
            } else if (window.location.host.indexOf('114.116.102.201') > -1) {
              _url = 'http://114.116.102.201:31141/api/masterWages/method/wagesPublish/' + _affiliationMonth;
            } else {
              _url = 'https://sch.rankee.cn/api/masterWages/method/wagesPublish/' + _affiliationMonth;
            }

            axios({
              headers: {
                Authorization: "Bearer ".concat(utils.cookies.get('token')),
                'Content-Type': 'multipart/form-data;charse=UTF-8'
              },
              url: _url,
              method: 'post',
              data: _formData,
              // body:{
              //   affiliationMonth:_affiliationMonth
              // },
              processData: false,
              // 告诉axios不要去处理发送的数据(重要参数)
              contentType: false // 告诉axios不要去设置Content-Type请求头

            }).then(function (res) {
              if (res.data.errCode == 500 || res.data.repeatCount > 0) {
                // this.$message({
                //   showClose: true,
                //   message: res.data.errMessage,
                //   type: 'error',
                //   duration: 0
                // });
                _this2.failCount = res.data.failCount;
                _this2.successCount = res.data.successCount;
                _this2.repeatCount = res.data.repeatCount;

                var _errMessages = res.data.errMessage.split("；");

                _this2.errMessages = [];

                if (_errMessages.length > 0) {
                  for (var i = 0; i < _errMessages.length; i++) {
                    if (_errMessages[i]) {
                      _this2.errMessages.push(_errMessages[i].substring(0, _errMessages[i].length - 1) + "。");
                    }
                  }
                }
              } else {
                _this2.$message({
                  type: "success",
                  message: "发布成功!"
                });

                _this2.goBack();
              }
            }).catch(function (err) {
              // 上传失败则显示上传失败，如多图则从图片列表删除图片
              console.log(err);
            }); //ddd
          }).catch(function () {// this.$message({
            //   type: 'info',
            //   message: '已取消删除'
            // });          
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    refresh: function refresh(val, file) {
      this.formData.excelFile = file;
    }
  }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.grades, function(item, index) {
        return [
          _c(
            "el-row",
            { key: index },
            [
              _c("el-col", { attrs: { span: 4 } }, [_vm._v(_vm._s(item.name))]),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { multiple: "", placeholder: "请选择" },
                      on: { change: _vm.handleChange },
                      model: {
                        value: item.gradeLeaders,
                        callback: function($$v) {
                          _vm.$set(item, "gradeLeaders", $$v)
                        },
                        expression: "item.gradeLeaders"
                      }
                    },
                    _vm._l(_vm.staffs, function(sitem) {
                      return _c("el-option", {
                        key: sitem._id,
                        attrs: { label: sitem.name, value: sitem._id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("el-divider", { key: index })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'studentRefundApplyService',
  methods: [{
    name: 'list',
    url: '/studentRefundApply/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'reject',
    url: '/studentRefundApply/method/reject',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'consentRefundApply',
    url: '/studentRefundApply/method/consentRefundApply',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
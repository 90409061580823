import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/libs/util';
import paymentRecords from '../util/PaymentRecordsUtil.vue';
export default {
  mixins: [utils.mixin.modelMixin, utils.mixin.modelEditMixin],
  provide: function provide() {
    return {
      close: this.onReturn
    };
  },
  name: 'masterOrderInfo',
  data: function data() {
    return {
      studentName: "",
      studentClass: "",
      masterOrderId: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.$nextTick(function () {
      this.initData();
    });
  },
  components: {
    paymentRecords: paymentRecords
  },
  methods: {
    initData: function initData() {
      var _this = this;

      this.masterOrderId = this.$route.params.id;
      this.options = [];
      this.$api.apis.masterOrderService.getById({
        path: {
          id: this.$route.params.id
        }
      }).then(function (data) {
        if (data) {
          if (data.relatedStudent) {
            _this.studentName = data.relatedStudent.name;

            if (data.relatedStudent.class) {
              _this.studentClass = "(" + data.relatedStudent.class.name + ")";
            }
          }
        }
      });
    },
    onReturn: function onReturn() {
      this.$store.dispatch('d2admin/page/close', {
        tagName: this.$route.path
      });
    }
  }
};
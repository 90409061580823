var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "span",
      { staticClass: "d2-mr btn-text", staticStyle: { "font-size": "14px" } },
      [
        _vm._v(
          _vm._s(_vm.info.name ? "你好 " + _vm.info.name : "未登录") + " "
        ),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: { effect: "dark", content: "修改密码", placement: "bottom" }
          },
          [
            _c("el-button", {
              staticStyle: { padding: "2px" },
              attrs: { type: "primary", icon: "el-icon-s-tools", circle: "" },
              nativeOn: {
                click: function($event) {
                  return _vm.changePassword($event)
                }
              }
            })
          ],
          1
        ),
        _c(
          "el-tooltip",
          {
            staticClass: "item",
            attrs: { effect: "dark", content: "退出登录", placement: "bottom" }
          },
          [
            _c("el-button", {
              staticStyle: { padding: "2px", "margin-left": "2px" },
              attrs: {
                type: "danger",
                icon: "el-icon-switch-button",
                circle: ""
              },
              nativeOn: {
                click: function($event) {
                  return _vm.logOff($event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ParentDepartmentUtil',
  data: function data() {
    return {
      value: [],
      options: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      var _this = this;

      this.options = [];

      excludingOwn: "";

      var params = {};

      if (this.$route.params.id && this.$route.params.id != "") {
        params = {
          excludingOwn: this.$route.params.id
        };
      }

      this.$api.apis.merchantDepartmentService.all({
        params: params
      }).then(function (data) {
        if (data && data.length > 0) {
          _this.options = _this.packagTreeDataFn(data, 0);

          if (_this.dataSource["parent"] && _this.dataSource["parent"].parentIds) {
            _this.value = _this.dataSource["parent"].parentIds;

            _this.handleChange();
          }
        }
      });
    },
    packagTreeDataFn: function packagTreeDataFn(dataList, parent_id) {
      var result = [],
          temp;

      for (var i in dataList) {
        if (dataList[i].parentId == parent_id) {
          temp = this.packagTreeDataFn(dataList, dataList[i]._id);
          result.push({
            id: dataList[i]._id,
            value: dataList[i]._id,
            label: dataList[i].name,
            sortLower: dataList[i].sortLower,
            level: dataList[i].level,
            parentNum: dataList[i].parentNum,
            children: temp,
            leaf: false
          });
        }
      }

      for (var j in result) {
        for (var k in result[j].children) {
          result[j].children[k].children.sort(this.compare("sortLower"));
        }

        result[j].children.sort(this.compare("sortLower"));
      }

      result.sort(this.compare("sortLower"));
      return result;
    },
    //排序
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    handleChange: function handleChange() {
      this.$emit('on-change', this.value, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'auth',
  methods: [{
    name: 'login',
    url: '/auth/login',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'out',
    url: '/auth/out',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'vcode',
    url: '/auth/vcode',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'smsCode',
    url: '/auth/sms/code',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'phoneExist',
    url: '/auth/phone/exist',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'forgetPas',
    url: '/auth/forget/pas',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
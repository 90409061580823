var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "审核" },
                  on: { change: _vm.handleChange },
                  model: {
                    value: _vm.auditValue,
                    callback: function($$v) {
                      _vm.auditValue = $$v
                    },
                    expression: "auditValue"
                  }
                },
                _vm._l(_vm.audits, function(o, i) {
                  return _c("el-option", {
                    key: i,
                    attrs: { label: o.label, value: o.value }
                  })
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
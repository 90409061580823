var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mainsrp-form" }, [
    _c(
      "div",
      { staticClass: "m-form" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            attrs: {
              "label-position": "top",
              model: _vm.dataSource,
              rules: _vm.rules,
              "label-width": "100px",
              size: "medium"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "form-mn" },
              _vm._l(_vm.conf.models, function(item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: index,
                    attrs: {
                      label: item.labelName,
                      "label-width": item.labelWidth,
                      prop: item.valName,
                      size: "small"
                    }
                  },
                  [
                    _vm.conf.ope === "view"
                      ? [
                          item.type === "editor"
                            ? _c("editor", {
                                attrs: {
                                  value: _vm.dataSource[item.valName],
                                  isOnlyRead: true,
                                  upFile: item.upFunc,
                                  upFileUrl: item.upFileUrl,
                                  geFile: item.geFileUrlFunc
                                }
                              })
                            : item.type === "cropper"
                            ? _c("cropper", {
                                attrs: {
                                  initUrl: _vm.dataSource[item.valName],
                                  conf: item,
                                  fixedNumber: item.fixedNumber,
                                  disabled: true
                                },
                                on: { "on-change": _vm.editorChange }
                              })
                            : _c("span", [
                                item.filterRow
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("generalFilter")(
                                            _vm.dataSource,
                                            item.filter
                                          )
                                        )
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("generalFilter")(
                                            _vm.dataSource,
                                            item.filter,
                                            item.valName
                                          )
                                        )
                                      )
                                    ])
                              ]),
                          item.des
                            ? _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "13px" } },
                                  [_vm._v(_vm._s(item.des))]
                                )
                              ])
                            : _vm._e()
                        ]
                      : _vm.conf.ope !== "view"
                      ? [
                          item.type === "label"
                            ? _c("span", [
                                item.filterRow
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("generalFilter")(
                                            _vm.dataSource,
                                            item.filter
                                          )
                                        )
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("generalFilter")(
                                            _vm.dataSource,
                                            item.filter,
                                            item.valName
                                          )
                                        )
                                      )
                                    ])
                              ])
                            : item.type === "text"
                            ? _c("el-input", {
                                class: item.classObject,
                                attrs: {
                                  placeholder: item.about,
                                  maxlength: item.maxlength
                                    ? item.maxlength
                                    : 50,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dataSource,
                                      item.valName,
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "number" || item.type === "amount"
                            ? _c("el-input", {
                                class: item.classObject,
                                attrs: {
                                  maxlength: item.maxlength
                                    ? item.maxlength
                                    : 10,
                                  placeholder: item.about,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dataSource,
                                      item.valName,
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "counterNumber"
                            ? _c("el-input-number", {
                                class: item.classObject,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "controls-position": "right",
                                  placeholder: item.about,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dataSource,
                                      item.valName,
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "textAppend"
                            ? _c(
                                "el-input",
                                {
                                  class: item.classObject,
                                  attrs: {
                                    maxlength: item.maxlength
                                      ? item.maxlength
                                      : 50,
                                    placeholder: item.about,
                                    disabled: item.disabled
                                  },
                                  model: {
                                    value: _vm.dataSource[item.valName],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dataSource,
                                        item.valName,
                                        $$v
                                      )
                                    },
                                    expression: "dataSource[item.valName]"
                                  }
                                },
                                [
                                  _c("template", { slot: "append" }, [
                                    _vm._v(_vm._s(item.append))
                                  ])
                                ],
                                2
                              )
                            : item.type === "year"
                            ? _c("el-date-picker", {
                                class: item.classObject,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy年",
                                  type: "year",
                                  placeholder: item.about,
                                  "value-format": "timestamp",
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "month"
                            ? _c("el-date-picker", {
                                class: item.classObject,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  format: "yyyy年MM月",
                                  type: "month",
                                  "value-format": "timestamp",
                                  placeholder: item.about,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "date"
                            ? _c("el-date-picker", {
                                class: item.classObject,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "timestamp",
                                  format: "yyyy/MM/dd",
                                  type: "date",
                                  placeholder: item.about,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "datetime"
                            ? _c("el-date-picker", {
                                class: item.classObject,
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "value-format": "timestamp",
                                  format: "yyyy/MM/dd HH:mm:ss",
                                  type: "datetime",
                                  placeholder: item.about,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "datetimerange"
                            ? _c("el-date-picker", {
                                class: item.classObject,
                                attrs: {
                                  format: "yyyy/MM/dd HH:mm:ss",
                                  type: "datetimerange",
                                  "value-format": "timestamp",
                                  placeholder: item.about,
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  "default-time": ["00:00:00", "23:59:59"],
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "select"
                            ? _c(
                                "el-select",
                                {
                                  class: item.classObject,
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: item.about,
                                    disabled: item.disabled
                                  },
                                  model: {
                                    value: _vm.dataSource[item.valName],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dataSource,
                                        item.valName,
                                        $$v
                                      )
                                    },
                                    expression: "dataSource[item.valName]"
                                  }
                                },
                                _vm._l(item.selValue, function(o, i) {
                                  return _c("el-option", {
                                    key: i,
                                    attrs: { label: o.text, value: o.value }
                                  })
                                }),
                                1
                              )
                            : item.type === "select_custom"
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: item.about,
                                    filterable: "",
                                    disabled: item.disabled
                                  },
                                  model: {
                                    value: _vm.dataSource[item.valName],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dataSource,
                                        item.valName,
                                        $$v
                                      )
                                    },
                                    expression: "dataSource[item.valName]"
                                  }
                                },
                                _vm._l(item.selValue, function(o, i) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: i,
                                      staticStyle: { height: "150px" },
                                      attrs: { label: o.text, value: o.value }
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: {
                                          float: "left",
                                          margin: "15px"
                                        },
                                        attrs: {
                                          width: "120",
                                          height: "120",
                                          src: o.img,
                                          alt: ""
                                        }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            color: "#8492a6",
                                            "font-size": "13px"
                                          }
                                        },
                                        [_vm._v(_vm._s(o.text))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            : item.type === "select_filterable"
                            ? _c(
                                "el-select",
                                {
                                  class: item.classObject,
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: item.about,
                                    filterable: "",
                                    disabled: item.disabled
                                  },
                                  model: {
                                    value: _vm.dataSource[item.valName],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dataSource,
                                        item.valName,
                                        $$v
                                      )
                                    },
                                    expression: "dataSource[item.valName]"
                                  }
                                },
                                _vm._l(item.selValue, function(o, i) {
                                  return _c("el-option", {
                                    key: i,
                                    attrs: { label: o.text, value: o.value }
                                  })
                                }),
                                1
                              )
                            : item.type === "select_multiple"
                            ? _c(
                                "el-select",
                                {
                                  class: item.classObject,
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: "",
                                    multiple: "",
                                    placeholder: item.about,
                                    disabled: item.disabled
                                  },
                                  model: {
                                    value: _vm.dataSource[item.valName],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dataSource,
                                        item.valName,
                                        $$v
                                      )
                                    },
                                    expression: "dataSource[item.valName]"
                                  }
                                },
                                _vm._l(item.selValue, function(o, i) {
                                  return _c("el-option", {
                                    key: i,
                                    attrs: { label: o.text, value: o.value }
                                  })
                                }),
                                1
                              )
                            : item.type === "radio_group"
                            ? _c(
                                "el-radio-group",
                                {
                                  class: item.classObject,
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: item.about,
                                    disabled: item.disabled
                                  },
                                  model: {
                                    value: _vm.dataSource[item.valName],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dataSource,
                                        item.valName,
                                        $$v
                                      )
                                    },
                                    expression: "dataSource[item.valName]"
                                  }
                                },
                                _vm._l(item.selValue, function(o, i) {
                                  return _c(
                                    "el-radio",
                                    { key: i, attrs: { label: o.value } },
                                    [_vm._v(_vm._s(o.text))]
                                  )
                                }),
                                1
                              )
                            : item.type === "checkbox_group"
                            ? _c(
                                "el-checkbox-group",
                                {
                                  class: item.classObject,
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: item.about,
                                    disabled: item.disabled
                                  },
                                  model: {
                                    value: _vm.dataSource[item.valName],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.dataSource,
                                        item.valName,
                                        $$v
                                      )
                                    },
                                    expression: "dataSource[item.valName]"
                                  }
                                },
                                _vm._l(item.selValue, function(o, i) {
                                  return _c(
                                    "el-checkbox",
                                    { key: i, attrs: { label: o.value } },
                                    [_vm._v(_vm._s(o.text))]
                                  )
                                }),
                                1
                              )
                            : item.type === "cascader"
                            ? _c("el-cascader", {
                                class: item.classObject,
                                attrs: {
                                  options: item.selValue,
                                  placeholder: item.about,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "transfer"
                            ? _c("el-transfer", {
                                class: item.classObject,
                                attrs: {
                                  data: item.selValue,
                                  titles: item.titles
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "password"
                            ? _c("el-input", {
                                class: item.classObject,
                                attrs: {
                                  type: "password",
                                  placeholder: item.about,
                                  maxlength: item.maxlength
                                    ? item.maxlength
                                    : 50,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.dataSource,
                                      item.valName,
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "textarea"
                            ? _c("el-input", {
                                class: item.classObject,
                                attrs: {
                                  type: "textarea",
                                  rows: item.textRows,
                                  placeholder: item.about,
                                  disabled: item.disabled
                                },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "editor"
                            ? _c("editor", {
                                class: item.classObject,
                                attrs: {
                                  value: _vm.dataSource[item.valName],
                                  valName: item.valName,
                                  upFile: item.upFunc,
                                  upFileUrl: item.upFileUrl,
                                  geFile: item.geFileUrlFunc,
                                  placeholder: item.about,
                                  isOnlyRead: item.isOnlyRead
                                },
                                on: { "on-change": _vm.editorChange }
                              })
                            : item.type === "tinymce"
                            ? _c("tinymce", {
                                attrs: {
                                  height: 320,
                                  width: 640,
                                  isOnlyRead: item.isOnlyRead,
                                  upFile: item.upFunc,
                                  upFileUrl: item.upFileUrl,
                                  geFile: item.geFileUrlFunc,
                                  valName: item.valName
                                },
                                on: { "on-change": _vm.editorChange },
                                model: {
                                  value: _vm.dataSource[item.valName],
                                  callback: function($$v) {
                                    _vm.$set(_vm.dataSource, item.valName, $$v)
                                  },
                                  expression: "dataSource[item.valName]"
                                }
                              })
                            : item.type === "cropper"
                            ? _c("cropper", {
                                class: item.classObject,
                                attrs: {
                                  initUrl: _vm.dataSource[item.valName],
                                  conf: item,
                                  fixedNumber: item.fixedNumber,
                                  upFile: item.upFunc,
                                  disabled: item.disabled,
                                  upFileUrl: item.upFileUrl,
                                  geFile: item.geFileUrlFunc,
                                  limit: item.limit,
                                  accept: item.accept
                                },
                                on: { "on-change": _vm.editorChange }
                              })
                            : item.type === "custom"
                            ? _c(item.component, {
                                tag: "component",
                                class: item.classObject,
                                attrs: {
                                  dataSource: _vm.dataSource,
                                  conf: item
                                },
                                on: {
                                  "on-change": _vm.editorChange,
                                  "on-change-other": _vm.editorChangeOther
                                }
                              })
                            : _vm._e(),
                          item.des
                            ? _c("el-col", { attrs: { span: 24 } }, [
                                _c(
                                  "span",
                                  { staticStyle: { "font-size": "13px" } },
                                  [_vm._v(_vm._s(item.des))]
                                )
                              ])
                            : item.type === "tip"
                            ? _c("div", { staticClass: "tips_box" }, [
                                _vm._v(" " + _vm._s(item.tiptxt) + " ")
                              ])
                            : _vm._e()
                        ]
                      : _vm._e()
                  ],
                  2
                )
              }),
              1
            )
          ]
        )
      ],
      1
    ),
    _vm.conf.hasOperation
      ? _c(
          "div",
          { staticClass: "mainsrp-operation" },
          [
            _vm._l(_vm.conf.operation, function(item, index) {
              return [
                !item.showFunc || item.showFunc(_vm.dataSource)
                  ? _c(
                      "el-button",
                      {
                        key: index,
                        staticClass: "dy-button-small",
                        attrs: {
                          type: item.type ? item.type : "button",
                          round: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.testSubmit(
                              _vm.dataSource,
                              item.doFunc,
                              item
                            )
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.labelName))]
                    )
                  : _vm._e()
              ]
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
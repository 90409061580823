//
//
//
//
//
//
//
//
//
import utils from '@/libs/util';
import moment from "moment";
export default {
  mixins: [utils.mixin.modelMixin, utils.mixin.modelEditMixin],
  name: 'messageNotificationInfo',
  data: function data() {
    var _this = this;

    return {
      config: {
        ope: 'view',
        sourceFunc: this.$api.apis.messageNotificationService.getById,
        sourceFuncArgs: {
          path: {
            id: this.$route.params.id
          }
        },
        models: [{
          labelName: '信息内容',
          valName: 'message',
          about: '请输入信息内容',
          type: 'textarea',
          textRows: 5,
          value: ''
        }, {
          labelName: '发送人',
          valName: 'sourceName',
          about: '请输入信息内容',
          type: 'text',
          value: ''
        }, {
          labelName: '发布时间',
          valName: 'insertTime',
          about: '',
          type: 'text',
          value: '',
          filter: function filter(val) {
            if (val !== "" && val !== null && val !== undefined) {
              return moment.parseZone(val).utcOffset('+08:00').format('YYYY-MM-DD HH:mm:ss');
            }
          }
        }],
        operation: [{
          // name: 'add',
          labelName: '返回',
          // 操作名称
          hasValidator: false,
          // 是否需要验证
          doFunc: function doFunc() {
            // this.$emit('closeSelectedTag', this.$route)
            // this.$refs.layout.closeSelectedTag(this.$route)
            //this.$router.back()
            _this.$refs.model.closeCurrentPage();
          } // 操作方法

        }]
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.$nextTick(function () {});
  },
  components: {},
  methods: {}
};
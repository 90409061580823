import "core-js/modules/es.string.trim";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import * as config from './config'
import util from '@/libs/util';
export default {
  name: 'Pagination',
  data: function data() {
    return {
      // pageSource: this.$utils.copy(config.pageSourceConfigBase)
      pageNumber: 0
    };
  },
  props: {
    pageSource: Object
  },
  created: function created() {
    // this.pageSource = config.pageSourceConfig(this.config)
    //console.debug(this.pageSource)
    this.pageNumber = this.pageSource.number + 1;
  },
  computed: {
    pageList: function pageList() {
      var p = [];

      if (this.pageSource.totalPages <= this.pageSource.size || this.pageSource.totalPages <= 5) {
        // 判断总页数如果小于等于分页的长度，若小于则直接显示
        for (var i = 1; i <= this.pageSource.totalPages; i++) {
          p.push(i);
        }
      } else {
        // 总页数大于分页长度（此时分为三种情况：1.左边没有...2.右边没有...3.左右都有...）
        // 计算中心偏移量
        var offset = (this.pageSource.size - 1) / 2;

        if (offset < 5) {
          offset = 5;
        }

        if (this.pageSource.number + 1 <= offset) {
          // 左边没有...
          for (var _i = 1; _i <= offset + 1; _i++) {
            p.push(parseInt(_i));
          }

          p.push('...');
          p.push(this.pageSource.totalPages);
        } else if (this.pageSource.number + 1 > this.pageSource.totalPages - offset) {
          p.push(1);
          p.push('...');

          for (var _i2 = offset + 1; _i2 >= 1; _i2--) {
            p.push(this.pageSource.totalPages - parseInt(_i2));
          }

          p.push(this.pageSource.totalPages);
        } else {
          // 最后一种情况，两边都有...
          p.push(1);
          p.push('...');

          for (var _i3 = Math.ceil(offset / 2); _i3 >= 1; _i3--) {
            var _p = parseInt(this.pageSource.number) - parseInt(_i3);

            if (_p !== 1) {
              p.push(parseInt(this.pageSource.number) - parseInt(_i3));
            }
          }

          p.push(this.pageSource.number);

          for (var _i4 = 1; _i4 <= offset / 2; _i4++) {
            p.push(parseInt(this.pageSource.number) + parseInt(_i4));
          }

          p.push('...');
          p.push(this.pageSource.totalPages);
        }
      } //console.debug(p)


      return p;
    }
  },
  components: {},
  methods: {
    goto: function goto() {
      this.changeCurrentPage(this.pageNumber);
    },
    prevPage: function prevPage() {
      if (!this.pageSource.first) {
        var _query = util.other.copy(this.$route.query); // _query['page'] = this.pageSource.number - 2


        this.$router.push({
          query: _query
        });
        this.$emit('prev-page');
      }
    },
    nextPage: function nextPage() {
      if (!this.pageSource.last) {
        var _query = util.other.copy(this.$route.query); // _query['page'] = this.pageSource.number + 2


        this.$router.push({
          query: _query
        }); // this.emitPage(1)

        this.$emit('next-page');
      }
    },
    changeCurrentPage: function changeCurrentPage(index) {
      if (index - 1 !== this.pageSource.number) {
        var _query = util.other.copy(this.$route.query); // _query['page'] = index - 1
        // _query['page'] = index


        this.$router.push({
          query: _query
        }); // this.emitPage(index - 1)emitPage

        this.emitPage(index);
      }
    },
    emitPage: function emitPage(page) {
      this.$emit('on-page', page);
    },
    change: function change(event) {
      var val = event.target.value.trim(); // 只能是正整数或空,可根据需求修改正则

      if (/^[1-9]\d*$|^$/.test(val)) {
        if (this.pageSource.totalPages < val) {
          this.pageNumber = this.pageSource.totalPages;
          event.target.value = this.pageNumber;
        } else {
          this.pageNumber = val;
        }
      } else {
        event.target.value = this.pageNumber;
      }

      var _query = util.other.copy(this.$route.query);

      _query.page = this.pageNumber - 1;
      this.$router.push({
        query: _query
      });
      this.emitPage(this.pageNumber - 1);
    }
  },
  watch: {
    '$route.query.page': function $routeQueryPage(val) {
      //console.debug(val)
      this.$emit('on-page', val);
    },
    config: function config(val) {//console.debug(val)
    },
    'pageSource.number': function pageSourceNumber(val) {
      this.pageNumber = val + 1;
    }
  }
};
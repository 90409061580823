//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PositionUtil',
  data: function data() {
    return {
      options: [],
      value: "",
      positionNameForm: {
        positionName: ""
      },
      positionNameRules: {
        positionName: [{
          required: true,
          message: '请填写位置名称',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.init();
  },
  computed: {},
  components: {},
  methods: {
    onChange: function onChange() {
      this.editorChange();
    },
    init: function init() {
      var _this = this;

      //获取所有分类
      this.$api.apis.fieldClassroomService.findPositionAll({
        path: {},
        params: {}
      }).then(function (d) {
        _this.options = d;
      });

      if (this.dataSource["fieldClassroomPosition"] && this.dataSource["fieldClassroomPosition"].id) {
        this.value = this.dataSource["fieldClassroomPosition"].id;
      }

      this.editorChange();
    },
    editorChange: function editorChange() {
      var _d = {
        positionId: this.value,
        positionName: this.positionNameForm.positionName
      };
      this.$emit('on-change', _d, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        //this.val = this.dataSource[this.conf.valName]
        this.init();
      },
      // 深度观察
      deep: true
    }
  }
};
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'MasterOrderEditItemUtil',
  data: function data() {
    return {
      paymentItems: [],
      checkList: [],
      dialogFormVisible: false,
      form: {
        amount: '',
        originalAmount: '',
        amountStr: '',
        originalAmountStr: '',
        ikey: ""
      },
      totalAmount: 0,
      realityAmount: 0,
      reductionAmount: 0
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {//this.initData()
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      var _this = this;

      this.checkList = [];
      this.paymentItems = [];

      if (this.dataSource["items"] && this.dataSource["items"].length > 0) {
        this.getSubOrderAll().then(function (subIkeys) {
          if (subIkeys && subIkeys.length > 0) {
            for (var i = 0; i < subIkeys.length; i++) {
              if (_this.checkList.indexOf(subIkeys[i]) <= -1) {
                _this.checkList.push(subIkeys[i]);
              }
            }
          }

          _this.paymentItems = _this.dataSource["items"];

          for (var _i in _this.paymentItems) {
            var item = _this.paymentItems[_i];
            _this.paymentItems[_i].isChecked = false;
            _this.paymentItems[_i].isDisabled = false;
            var _name = item.name;

            if (item.about) {
              _name = _name + "(" + item.about + ")";
            }

            if (item.selType === '00') {
              _name = '【可选】' + _name;
            } else if (item.selType === '01') {
              _name = '【必选】' + _name;
              _this.paymentItems[_i].isChecked = true;
              _this.paymentItems[_i].isDisabled = true;

              if (_this.checkList.indexOf(_this.paymentItems[_i].ikey) <= -1) {
                _this.checkList.push(_this.paymentItems[_i].ikey);
              }
            } else if (item.selType === '10') {
              _name = '【同项选一不必填】' + _name;
            } else if (item.selType === '11') {
              _name = '【同项选一必填】' + _name;
            }

            _this.paymentItems[_i].rename = _name;

            if (_this.paymentItems[_i].amount > 0) {
              _this.paymentItems[_i].amountStr = parseFloat(_this.paymentItems[_i].amount / 100).toFixed(2);
            } else {
              _this.paymentItems[_i].amountStr = 0;
            }

            if (!_this.paymentItems[_i].originalAmount) {
              _this.paymentItems[_i].originalAmount = _this.paymentItems[_i].amount;
            }

            if (_this.paymentItems[_i].originalAmount > 0) {
              _this.paymentItems[_i].originalAmountStr = parseFloat(_this.paymentItems[_i].originalAmount / 100).toFixed(2);
            } else {
              _this.paymentItems[_i].originalAmountStr = 0;
            }

            if (_this.checkList.indexOf(_this.paymentItems[_i].ikey) > -1) {
              _this.paymentItems[_i].isChecked = true;
            }
          }

          _this.handleCheckedCitiesChange();
        });
      } else {}
    },
    //获取子订单
    getSubOrderAll: function getSubOrderAll() {
      var _this2 = this;

      if (this.$route.params.id) {
        return new Promise(function (resolve) {
          _this2.$api.apis.masterOrderService.subOrder({
            path: {
              id: _this2.$route.params.id
            }
          }).then(function (data) {
            //this.records = data
            var _subIkeys = [];

            if (data) {
              for (var i = 0; i < data.length; i++) {
                var _items = data[i].items;

                for (var j = 0; j < _items.length; j++) {
                  _subIkeys.push(_items[j].ikey);
                }
              }
            }

            resolve(_subIkeys);
          });
        });
      } else {
        return [];
      }
    },
    handleCheckedCitiesChange: function handleCheckedCitiesChange(value) {
      this.totalAmount = 0;
      this.realityAmount = 0;

      for (var i = 0; i < this.paymentItems.length; i++) {
        if (this.checkList.indexOf(this.paymentItems[i].ikey) > -1) {
          this.totalAmount = this.totalAmount + this.paymentItems[i].originalAmount;
          this.realityAmount = this.realityAmount + this.paymentItems[i].amount;
          this.paymentItems[i].isChecked = true;
        } else {
          this.paymentItems[i].isChecked = false;
        }
      }

      this.reductionAmount = this.totalAmount - this.realityAmount;

      if (this.totalAmount > 0) {
        this.totalAmount = parseFloat(this.totalAmount / 100).toFixed(2);
      } else {
        this.totalAmount = 0;
      }

      if (this.realityAmount > 0) {
        this.realityAmount = parseFloat(this.realityAmount / 100).toFixed(2);
      } else {
        this.realityAmount = 0;
      }

      if (this.reductionAmount > 0) {
        this.reductionAmount = parseFloat(this.reductionAmount / 100).toFixed(2);
      } else {
        this.reductionAmount = 0;
      }

      this.handleChange();
    },
    //添加优惠
    onPreferential: function onPreferential(ikey) {
      for (var i = 0; i < this.paymentItems.length; i++) {
        if (this.paymentItems[i].ikey === ikey) {
          this.form.ikey = ikey;
          this.form.amount = this.paymentItems[i].amount;
          this.form.originalAmount = this.paymentItems[i].originalAmount;
          this.form.amountStr = this.paymentItems[i].amountStr;
          this.form.originalAmountStr = this.paymentItems[i].originalAmountStr;
          this.dialogFormVisible = true;
          break;
        }
      }
    },
    handleClose: function handleClose() {
      this.dialogFormVisible = false;
      this.form.ikey = "";
      this.form.amount = 0;
      this.form.originalAmount = 0;
      this.form.amountStr = "";
      this.form.originalAmountStr = "";
    },
    onConfirm: function onConfirm() {
      for (var i = 0; i < this.paymentItems.length; i++) {
        if (this.paymentItems[i].ikey === this.form.ikey) {
          this.paymentItems[i].amount = parseInt(parseFloat(this.form.amountStr) * 100);
          this.paymentItems[i].amountStr = this.form.amountStr;
          break;
        }
      }

      this.handleCheckedCitiesChange();
      this.handleClose();
    },
    handleChange: function handleChange() {
      this.$emit('on-change', this.paymentItems, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  },
  filters: {
    parentalIdentityFilter: function parentalIdentityFilter(val) {
      if (val === 0) {
        return "其他";
      } else if (val === 1) {
        return "父亲";
      } else if (val === 2) {
        return "母亲";
      } else if (val === 3) {
        return "爷爷";
      } else if (val === 4) {
        return "奶奶";
      } else if (val === 5) {
        return "外公";
      } else if (val === 6) {
        return "外婆";
      }
    },
    timeFilter: function timeFilter(val) {
      if (val > 0) {
        return moment.parseZone(val).utcOffset('+08:00').format('YYYY-MM-DD HH:mm:ss');
      } else {
        return "";
      }
    },
    amountFilter: function amountFilter(val) {
      if (val > 0) {
        return "：" + parseFloat(val / 100).toFixed(2) + "元";
      } else {
        return "：0元";
      }
    },
    selTypeFilter: function selTypeFilter(val) {
      if (val === '00') {
        return '【可选】';
      } else if (val === '01') {
        return '【必选】';
      } else if (val === '10') {
        return '【同项选一不必填】';
      } else if (val === '11') {
        return '【同项选一必填】';
      } else {
        return '';
      }
    },
    aboutFilter: function aboutFilter(val) {
      if (val !== '') {
        return "(" + val + ")";
      } else {
        return '';
      }
    },
    payMethodFilter: function payMethodFilter(val) {
      if (val === 'CHANNEL_TYPE_CASH') {
        return '线下收款';
      } else {
        return '线上交费';
      }
    }
  }
};
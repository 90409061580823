var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-tree", {
        ref: "openRangeTree",
        attrs: {
          data: _vm.treeData,
          props: _vm.props,
          "highlight-current": true,
          "node-key": "id",
          "show-checkbox": "",
          "default-checked-keys": _vm.defaultCheckedKeys
        },
        on: { "check-change": _vm.handleCheckChange }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
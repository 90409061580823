import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'paymentSubjectService',
  methods: [{
    name: 'list',
    url: '/paymentSubject/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/paymentSubject/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/paymentSubject/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'add',
    url: '/paymentSubject/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/paymentSubject/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'deleteAll',
    url: '/paymentItem/method/deleteAll',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'findPaymentChanneAll',
    url: '/paymentItem/method/findPaymentChanneAll',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'all',
    url: '/paymentItem/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'save',
    url: '/paymentSubject/method/save',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'importSave',
    url: '/paymentSubject/method/importSave',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'importAdd',
    url: '/paymentSubject/method/importAdd',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'cancel',
    url: '/paymentSubject/method/cancel/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'statistics',
    url: '/paymentSubject/method/statistics/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upPayTime',
    url: '/paymentSubject/method/upPayTime',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'itemAboutAll',
    url: '/paymentSubject/method/itemAboutAll/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'itemAll',
    url: '/paymentSubject/method/itemAll/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'studentTagService',
  methods: [{
    name: 'list',
    url: '/studentTag/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/studentTag/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'add',
    url: '/studentTag/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/studentTag/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/studentTag/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'deleteAll',
    url: '/studentTag/method/deleteAll',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'all',
    url: '/studentTag/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'removeStudent',
    url: '/studentTag/method/removeStudent/{id}/{studentId}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'batchRemoveStudent',
    url: '/studentTag/method/batchRemoveStudent/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'addStudent',
    url: '/studentTag/method/addStudent',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'addStaff',
    url: '/studentTag/method/addStaff',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'removeStaff',
    url: '/studentTag/method/removeStaff/{id}/{staffId}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'batchRemoveStaff',
    url: '/studentTag/method/batchRemoveStaff/{id}',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
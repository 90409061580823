import "core-js/modules/es.function.name";
import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'MasterOrderEditItemUtil',
  data: function data() {
    return {
      paymentItems: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {//this.initData()
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      this.paymentItems = [];

      if (this.dataSource["items"] && this.dataSource["items"].length > 0) {
        this.paymentItems = this.dataSource["items"];

        for (var i in this.paymentItems) {
          var item = this.paymentItems[i];
          var _name = item.name;

          if (item.about) {
            _name = _name + "(" + item.about + ")";
          }

          if (item.selType === '00') {
            _name = _name + '【可选】';
          } else if (item.selType === '01') {
            _name = _name + '【必选】';
          } else if (item.selType === '10') {
            _name = _name + '【同项选一不必填】';
          } else if (item.selType === '11') {
            _name = _name + '【同项选一必填】';
          }

          this.paymentItems[i].rename = _name;

          if (this.paymentItems[i].amount > 0) {
            this.paymentItems[i].amount = parseFloat(parseFloat(this.paymentItems[i].amount / 100).toFixed(2));
          } else {
            this.paymentItems[i].amount = 0;
          }
        }
      } else {}
    },
    handleChange: function handleChange() {
      this.$emit('on-change', this.paymentItems, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  },
  filters: {
    parentalIdentityFilter: function parentalIdentityFilter(val) {
      if (val === 0) {
        return "其他";
      } else if (val === 1) {
        return "父亲";
      } else if (val === 2) {
        return "母亲";
      } else if (val === 3) {
        return "爷爷";
      } else if (val === 4) {
        return "奶奶";
      } else if (val === 5) {
        return "外公";
      } else if (val === 6) {
        return "外婆";
      }
    },
    timeFilter: function timeFilter(val) {
      if (val > 0) {
        return moment.parseZone(val).utcOffset('+08:00').format('YYYY-MM-DD HH:mm:ss');
      } else {
        return "";
      }
    },
    amountFilter: function amountFilter(val) {
      if (val > 0) {
        return "：" + parseFloat(val / 100).toFixed(2) + "元";
      } else {
        return "：0元";
      }
    },
    selTypeFilter: function selTypeFilter(val) {
      if (val === '00') {
        return '【可选】';
      } else if (val === '01') {
        return '【必选】';
      } else if (val === '10') {
        return '【同项选一不必填】';
      } else if (val === '11') {
        return '【同项选一必填】';
      } else {
        return '';
      }
    },
    aboutFilter: function aboutFilter(val) {
      if (val !== '') {
        return "(" + val + ")";
      } else {
        return '';
      }
    },
    payMethodFilter: function payMethodFilter(val) {
      if (val === 'CHANNEL_TYPE_CASH') {
        return '线下收款';
      } else {
        return '线上交费';
      }
    }
  }
};
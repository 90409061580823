var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { disabled: _vm.isDisabled, placeholder: "请选择" },
          on: { change: _vm.scopeTypeChange },
          model: {
            value: _vm.scopeType,
            callback: function($$v) {
              _vm.scopeType = $$v
            },
            expression: "scopeType"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      ),
      _vm.scopeType == "gradeAndClass"
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px", padding: "12px 0" } },
            [
              _c("el-tree", {
                ref: "selectStudentsTree",
                attrs: {
                  data: _vm.treeData,
                  props: _vm.props,
                  "highlight-current": true,
                  "default-checked-keys": _vm.defaultCheckedKeys,
                  "node-key": "id",
                  "show-checkbox": ""
                },
                on: {
                  "check-change": _vm.handleCheckChange,
                  "node-click": _vm.handleCheck
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.scopeType == "tag"
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px", padding: "12px 0" } },
            [
              _c("StudentTagTreeUtil", {
                attrs: {
                  isDisabled: _vm.isDisabled,
                  scopeType: _vm.scopeType,
                  rawData: _vm.rawData
                },
                on: { "on-change": _vm.editorChange }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticStyle: { display: "flex", "align-items": "center" } },
        [
          _c("div", { staticStyle: { "margin-right": "8px" } }, [
            _vm._v(" 各班代管 ")
          ]),
          _c("el-switch", {
            attrs: {
              "active-color": "#13ce66",
              "inactive-color": "#ff4949",
              "active-value": "CLASS",
              "inactive-value": "NO",
              disabled: _vm.isDisabled
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.isEscrow,
              callback: function($$v) {
                _vm.isEscrow = $$v
              },
              expression: "isEscrow"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'calendarService',
  methods: [{
    name: 'info',
    url: '/calendar/method/info',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "StaffUtil",
  data: function data() {
    return {
      options: [],
      value: "",
      loading: false
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  mounted: function mounted() {},
  created: function created() {},
  computed: {},
  components: {},
  methods: {
    remoteMethod: function remoteMethod(query) {
      var _this = this;

      if (query !== "") {
        this.loading = true;
        this.$api.apis.staffService.all({
          params: {
            name: query
          }
        }).then(function (data) {
          _this.loading = false;
          _this.options = data.filter(function (item) {
            return item.name.indexOf(query) > -1;
          });
        });
      } else {
        this.options = [];
      }
    },
    selectChange: function selectChange() {
      if (this.value) {
        this.$emit('on-change', this.value, this.conf.valName);
      }
    }
  },
  watch: {// dataSource:{
    //   handler(){
    //     this.val = this.dataSource[this.conf.valName]
    //   },
    //   // 深度观察
    //   deep:true
    // }
  }
};
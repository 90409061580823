var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%", "margin-bottom": "16px" },
          attrs: { placeholder: "请选择" },
          on: { change: _vm.onChange },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _c("el-option", { attrs: { label: "新建位置", value: "newAdd" } }),
          _vm._l(_vm.options, function(item) {
            return _c("el-option", {
              key: item._id,
              attrs: { label: item.name, value: item._id }
            })
          })
        ],
        2
      ),
      _vm.value === "newAdd"
        ? _c("div", { staticClass: "mainsrp-form" }, [
            _c(
              "div",
              { staticClass: "m-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "positionNameForm",
                    attrs: {
                      "label-position": "top",
                      model: _vm.positionNameForm,
                      ruless: _vm.positionNameRules,
                      "show-message": false,
                      "label-width": "100px",
                      size: "medium"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-mn" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "位置名称（最多20个汉字）",
                              prop: "positionName",
                              size: "small"
                            }
                          },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入位置名称",
                                maxlength: "20"
                              },
                              on: { blur: _vm.editorChange },
                              model: {
                                value: _vm.positionNameForm.positionName,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.positionNameForm,
                                    "positionName",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "positionNameForm.positionName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
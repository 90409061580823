//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ApplicationUtil',
  data: function data() {
    return {
      suitableApplys: [],
      checkList: [],
      primaryApplys: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      var _this = this;

      this.checkList = [];
      this.primaryApplys = []; //[{name:"校园日常收费",_id:"everydayCharging"}]

      this.suitableApplys = [];

      if (this.dataSource[this.conf.valName]) {
        for (var j in this.dataSource[this.conf.valName]) {
          if (this.dataSource[this.conf.valName][j].groupId) {
            this.checkList.push(this.dataSource[this.conf.valName][j].groupId);
          }
        }
      }

      this.$api.apis.paymentItemService.findSuitableApplyAll({
        params: {}
      }).then(function (data) {
        if (data) {
          var da = _this.packagTreeDataFn(data);

          _this.suitableApplys = da;
          _this.primaryApplys = data;
        }
      });
    },
    packagTreeDataFn: function packagTreeDataFn(dataList) {
      var result = [];

      for (var i in dataList) {
        if (result.length == 0) {
          result.push({
            menuGroupName: dataList[i].menuGroupName,
            sort: dataList[i].sort,
            groups: [{
              groupName: dataList[i].groupName,
              groupId: dataList[i].groupId,
              groupSort: dataList[i].groupSort
            }]
          });
        } else {
          var isExist = false;
          var existIndex = -1;

          for (var j in result) {
            if (dataList[i].menuGroupName == result[j].menuGroupName) {
              isExist = true;
              existIndex = j;
              break;
            }
          }

          if (isExist) {
            result[existIndex].groups.push({
              groupName: dataList[i].groupName,
              groupId: dataList[i].groupId,
              groupSort: dataList[i].groupSort
            });
          } else {
            result.push({
              menuGroupName: dataList[i].menuGroupName,
              sort: dataList[i].sort,
              groups: [{
                groupName: dataList[i].groupName,
                groupId: dataList[i].groupId,
                groupSort: dataList[i].groupSort
              }]
            });
          }
        }
      }

      for (var _j in result) {
        result[_j].groups.sort(this.compare("groupSort"));
      }

      result.sort(this.compare("sort"));
      return result;
    },
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    handleChange: function handleChange() {
      var _v = [];

      for (var i = 0; i < this.checkList.length; i++) {
        this.$set(this.checkList, i, this.checkList[i]);

        for (var j in this.primaryApplys) {
          if (this.checkList[i] == this.primaryApplys[j].groupId) {
            _v.push({
              groupId: this.checkList[i],
              groupName: this.primaryApplys[j].groupName
            });
          }
        }
      }

      this.$emit('on-change', _v, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AuthorityExplain',
  data: function data() {
    return {
      closable: true,
      dess: []
    };
  },
  props: {},
  created: function created() {
    this.dess = [this.$route.meta.explain];
  },
  components: {},
  methods: {
    tagClose: function tagClose(titleTag) {
      this.dess.splice(this.dess.indexOf(titleTag), 1);
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'staffWalletService',
  methods: [{
    name: 'list',
    url: '/staffWallet/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/staffWallet/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'card',
    url: '/staffWallet/method/card/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'binding',
    url: '/staffWallet/method/binding',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'bindingReading',
    url: '/staffWallet/method/bindingReading',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'unbind',
    url: '/staffWallet/method/unbind',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'loss',
    url: '/staffWallet/method/loss',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'revokeLoss',
    url: '/staffWallet/method/revokeLoss',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'bindingNew',
    url: '/staffWallet/method/bindingNew',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'bindingReadingNew',
    url: '/staffWallet/method/bindingReadingNew',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'cashReceiptsInfo',
    url: '/staffWallet/method/cashReceiptsInfo/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'cashReceipts',
    url: '/staffWallet/method/cashReceipts',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
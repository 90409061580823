//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import cropper from '../../../components/zhc-crud/Cropper';
import utils from '@/libs/util';
export default {
  inject: ['cancel'],
  name: 'FiveFlipCharUtil',
  data: function data() {
    return {
      mark: "",
      fiveFlipForm: {
        fiveFlips: [{
          url: "",
          image: ""
        }]
      },
      fiveFlipImageItem: {
        labelName: '图片上传',
        valName: 'image',
        about: '图片上传',
        type: 'cropper',
        value: '',
        closeCropper: true,
        fileSize: 512000,
        fileSizeStr: "500KB",
        upFileUrl: this.$api.apis.fileService.upFileUrl()
      }
    };
  },
  props: {
    moduleMark: String
  },
  components: {
    cropper: cropper
  },
  created: function created() {
    this.init();
  },
  methods: {
    editorChange: function editorChange(val, valName, index) {
      if (valName === 'image') {
        this.fiveFlipForm.fiveFlips[index][valName] = val;
      }
    },
    addFiveFlip: function addFiveFlip() {
      if (this.fiveFlipForm.fiveFlips.length >= 5) {
        this.$message.error('最多添加5张');
      } else {
        this.fiveFlipForm.fiveFlips.push({
          url: "",
          image: ""
        });
      }
    },
    init: function init() {
      var _this = this;

      this.$api.apis.wxHomeConfigService.findModuleData({
        params: {
          type: "banner",
          mark: this.moduleMark
        }
      }).then(function (d) {
        if (d && d.mark) {
          _this.mark = d.mark;
        } else {
          _this.mark = "";
        }

        if (d && d.data && d.data.length > 0) {
          _this.fiveFlipForm.fiveFlips = d.data;
        } else {
          _this.fiveFlipForm.fiveFlips = [{
            url: "",
            image: ""
          }];
        }
      });
    },
    onCancel: function onCancel() {
      this.cancel();
    },
    onDelModule: function onDelModule() {
      var _this2 = this;

      //删除模块
      this.$confirm('是否删除五翻图?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (!_this2.mark) {
          _this2.cancel();

          return;
        }

        var _body = {
          type: "banner",
          mark: _this2.mark
        };

        _this2.$api.apis.wxHomeConfigService.delModule({
          body: _body,
          isAes: true,
          aesKey: utils.cookies.get('salt')
        }).then(function () {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.cancel();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    onSave: function onSave() {
      var _this3 = this;

      this.$refs["fiveFlipForm"].validate(function (valid) {
        if (valid) {
          var _body = {
            type: "banner",
            data: _this3.fiveFlipForm.fiveFlips,
            mark: _this3.mark
          };

          _this3.$api.apis.wxHomeConfigService.addModuleData({
            body: _body,
            isAes: true,
            aesKey: utils.cookies.get('salt')
          }).then(function () {
            _this3.$message({
              type: 'success',
              message: '保存成功!'
            });

            _this3.init();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  watch: {// dataSource:{
    //   handler(){
    //     this.val = this.dataSource[this.conf.valName]
    //   },
    //   // 深度观察
    //   deep:true
    // }
  }
};
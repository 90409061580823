var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.affiliationCategor
        ? _c("el-cascader", {
            staticStyle: { width: "100%" },
            attrs: {
              placeholder: "请选择所属类目",
              options: _vm.options,
              props: { checkStrictly: true },
              clearable: ""
            },
            on: { change: _vm.handleChange },
            model: {
              value: _vm.affiliationCategor,
              callback: function($$v) {
                _vm.affiliationCategor = $$v
              },
              expression: "affiliationCategor"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { placeholder: "请选择" },
          on: { change: _vm.scopeTypeChange },
          model: {
            value: _vm.scopeType,
            callback: function($$v) {
              _vm.scopeType = $$v
            },
            expression: "scopeType"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.label, value: item.value }
          })
        }),
        1
      ),
      _vm.scopeType == "gradeAndClass"
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("el-tree", {
                ref: "selectStudentsTree",
                attrs: {
                  data: _vm.treeData,
                  props: _vm.props,
                  "highlight-current": true,
                  "default-checked-keys": _vm.defaultCheckedKeys,
                  "node-key": "id",
                  "show-checkbox": "",
                  lazy: "",
                  load: _vm.loadNode
                },
                on: { "check-change": _vm.handleCheckChange }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.scopeType == "tag"
        ? _c(
            "div",
            { staticStyle: { "margin-top": "10px" } },
            [
              _c("StudentTagTreeUtil", {
                attrs: { scopeType: _vm.scopeType, rawData: _vm.rawData },
                on: { "on-change": _vm.editorChange }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
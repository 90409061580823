import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.iterator";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Cropper from './CropperUtil';
import util from '@/libs/util.js';
import axios from 'axios';
export default {
  name: 'uploader',
  props: {
    upFile: Function,
    upFileUrl: String,
    geFile: Function,
    dataSource: Object,
    conf: Object,
    dataIndex: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    // multiple: {
    //     // 多图开关
    //     type: Boolean,
    //     default: true
    // },
    initUrl: {
      // 初始图片链接
      default: ''
    },
    fixedNumber: {
      // 剪裁框比例设置
      default: function _default() {
        return [3, 1];
      }
    },
    width: {
      // 单图剪裁框宽度
      type: Number,
      default: 178
    },
    height: {
      // 单图剪裁框高度
      type: Number,
      default: 178
    }
  },
  computed: {
    limit: function limit() {
      return this.conf.limit ? this.conf.limit : 5;
    },
    accept: function accept() {
      return this.conf.accept ? this.conf.accept : 'image/jpg,image/png';
    }
  },
  data: function data() {
    return {
      multiple: true,
      file: '',
      // 当前被选择的图片文件
      imageUrl: '',
      // 单图情况框内图片链接
      dialogImageUrl: '',
      // 多图情况弹窗内图片链接
      uploadList: [],
      // 上传图片列表
      reupload: true,
      // 控制"重新上传"开关
      dialogVisible: false,
      // 展示弹窗开关
      cropperModel: false,
      // 剪裁组件弹窗开关
      reuploadWidth: this.height * 0.7 // 动态改变”重新上传“大小

    };
  },
  updated: function updated() {
    if (this.$refs.vueCropper) {
      this.$refs.vueCropper.Update();
    }
  },
  watch: {
    initUrl: function initUrl(val) {
      // 监听传入初始化图片
      // console.info('watch')
      // console.info(val)
      if (val || val === '') {
        if (typeof this.initUrl === 'string') {
          this.imageUrl = val;

          if (this.conf.multiple != null && this.conf.multiple !== undefined) {
            this.multiple = this.conf.multiple;
          } else {
            this.multiple = false;
          }
        } else {
          this.uploadList = this.formatImgArr(val);

          if (this.conf.multiple != null && this.conf.multiple !== undefined) {
            this.multiple = this.conf.multiple;
          } else {
            this.multiple = true;
          }
        }
      }
    }
  },
  mounted: function mounted() {
    if (typeof this.initUrl === 'string') {
      this.imageUrl = this.initUrl;

      if (this.conf.multiple != null && this.conf.multiple !== undefined) {
        this.multiple = this.conf.multiple;
      } else {
        this.multiple = false;
      }
    } else {
      this.uploadList = this.formatImgArr(this.initUrl);

      if (this.conf.multiple != null && this.conf.multiple !== undefined) {
        this.multiple = this.conf.multiple;
      } else {
        this.multiple = true;
      }
    }
  },
  methods: {
    /** **************************** multiple多图情况 **************************************/
    handlePreview: function handlePreview(file) {
      // 点击进行图片展示
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove: function handleRemove(file, fileList) {
      // 删除图片后更新图片文件列表并通知父级变化
      this.uploadList = fileList;
      this.$emit('imgupload', this.formatImgArr(this.uploadList));
      this.$emit('on-change', this.formatImgArr(this.uploadList), this.conf.valName, this.dataIndex);
    },
    consoleFL: function consoleFL(file, fileList) {
      var _this = this;

      //console.debug('consoleFL')
      // 弹出剪裁框，将当前文件设置为文件
      var fileType = this.conf.accept ? this.conf.accept : 'image/jpeg，image/jpg，image/png';
      var fileSize = this.conf.fileSize ? this.conf.fileSize : 20971520;
      var fileSizeStr = this.conf.fileSizeStr ? this.conf.fileSizeStr : "20M";

      if (fileType.indexOf(file.raw.type) === -1) {
        var fileTypeArr = fileType.split('image/');
        this.$message.error('只能上传' + fileTypeArr.join(' ') + '格式图片');
        this.uploadList.splice(this.uploadList.length, 1);
      } else {
        if ('image/jpeg，image/jpg，image/png'.indexOf(file.raw.type) > -1) {
          //图片大于20M
          if (file.raw.size > fileSize) {
            this.$message.error('只能上传小于' + fileSizeStr + '图片');
            this.uploadList.splice(this.uploadList.length, 1);
            return false;
          }
        }

        this.file = file;
        this.uploadList = fileList;

        if (!this.conf.closeCropper) {
          this.cropperModel = true;
        } else {
          var reader = new window.FileReader();

          reader.onload = function (ef) {
            _this.upload(ef.target.result, file.raw);
          };

          reader.readAsDataURL(file.raw);
        }
      }
    },

    /************************************************************************************/

    /** **************************** single单图情况 **************************************/
    handlePreviewSingle: function handlePreviewSingle(file) {
      // 点击进行图片展示
      //console.debug(file)
      if (this.file) {
        this.dialogImageUrl = this.file.url;
      } else {
        this.dialogImageUrl = this.imageUrl;
      }

      this.dialogVisible = true;
    },
    mouseEnter: function mouseEnter() {
      // 鼠标划入显示“重新上传”
      this.$refs.reupload.style.display = 'block';

      if (this.$refs.failUpload.style.display === 'block') {
        this.$refs.failUpload.style.display = 'none';
      }

      this.$refs.singleImg.style.opacity = '0.6';
    },
    mouseLeave: function mouseLeave() {
      // 鼠标划出隐藏“重新上传”
      this.$refs.reupload.style.display = 'none';
      this.$refs.singleImg.style.opacity = '1';
    },
    handleCrop: function handleCrop(file) {
      var _this2 = this;

      // 点击弹出剪裁框
      //console.debug('handleCrop')
      var fileType = this.conf.accept ? this.conf.accept : 'image/jpeg，image/jpg，image/png';
      var fileSize = this.conf.fileSize ? this.conf.fileSize : 20971520;
      var fileSizeStr = this.conf.fileSizeStr ? this.conf.fileSizeStr : "20M";

      if (fileType.indexOf(file.raw.type) === -1) {
        var fileTypeArr = fileType.split('image/');
        this.$message.error('只能上传' + fileTypeArr.join(' ') + '格式图片');
      } else {
        if ('image/jpeg，image/jpg，image/png'.indexOf(file.raw.type) > -1) {
          //图片大于20M
          if (file.raw.size > fileSize) {
            this.$message.error('只能上传小于' + fileSizeStr + '图片');
            return false;
          }
        }

        file.url = URL.createObjectURL(file.raw); // this.cropperModel = true

        this.file = file;

        if (!this.conf.closeCropper) {
          this.cropperModel = true;
        } else {
          var reader = new window.FileReader();

          reader.onload = function (ef) {
            _this2.upload(ef.target.result, file.raw);
          };

          reader.readAsDataURL(file.raw);
        } // this.imageUrl = file.url

      }
    },
    upload: function upload(val, file) {
      var _this3 = this;

      // 自定义upload事件
      if (!this.multiple) {
        // 如果单图，则显示正在上传
        this.$refs.uploading.style.display = 'block';
      }

      var self = this;

      if (!window.FileReader) {
        window.alert('您的浏览器版本过低');
      } // let reader = new window.FileReader()
      // const data = {
      //   filename: new Date().getTime(),
      //   type: 'png',
      //   content: val,
      //   channel: 'oss',
      //   group: self.conf.group || 'common'
      // }
      // 采用简介上传


      if (this.upFileUrl) {
        var upFileType = this.conf.accept ? this.conf.accept : 'image/jpeg，image/jpg，image/png'; //上传类型

        var fileType = "";

        if (upFileType.indexOf(file.type) > -1) {
          fileType = "img";
        }

        var formData = new FormData();

        if (file.name === "imgName") {
          if (file.type == "image/png") {
            formData.append('file', file, "cropperImg.png");
          } else if (file.type == "image/jpeg") {
            formData.append('file', file, "cropperImg.jpeg");
          } else if (file.type == "image/jpg") {
            formData.append('file', file, "cropperImg.jpg");
          } else {
            formData.append('file', file);
          }
        } else {
          formData.append('file', file);
        }

        formData.append('fileType', fileType);
        axios({
          headers: {
            Authorization: "Bearer ".concat(util.cookies.get('token')),
            'Content-Type': 'multipart/form-data;charse=UTF-8'
          },
          url: this.upFileUrl,
          method: 'post',
          data: formData,
          processData: false,
          // 告诉axios不要去处理发送的数据(重要参数)
          contentType: false // 告诉axios不要去设置Content-Type请求头

        }).then(function (res) {
          // console.log('上传返回')
          // console.log(res)
          if (!self.multiple) {
            // 上传完成后隐藏正在上传
            self.$refs.uploading.style.display = 'none';
          }

          var currentPic = res.data;

          if (_this3.geFile) {
            currentPic = _this3.geFile({
              path: {
                id: res.data
              }
            });
          }

          if (self.multiple) {
            self.uploadList.pop();
            self.uploadList.push({
              url: currentPic,
              uid: '111',
              name: "\u56FE".concat(self.uploadList.length + 1)
            });
            self.$emit('imgupload', self.formatImgArr(self.uploadList));
            self.$emit('on-change', self.formatImgArr(self.uploadList), self.conf.valName, self.dataIndex);
          } else {
            _this3.file.url = currentPic;
            self.$emit('imgupload', currentPic);
            self.$emit('on-change', currentPic, self.conf.valName, self.dataIndex);
          }
        }).catch(function () {
          // 上传失败则显示上传失败，如多图则从图片列表删除图片
          if (!self.multiple) {
            self.$refs.failUpload.style.display = 'block';
          } else {
            self.uploadList.pop();
          }
        }); // this.upFile().then((da) => {
        //   //console.debug(file)
        //   formData.append('uploadSubPath', data.group)
        // }).catch(() => {
        //   // 上传失败则显示上传失败，如多图则从图片列表删除图片
        //   if (!self.multiple) {
        //     self.$refs.failUpload.style.display = 'block'
        //   } else {
        //     self.uploadList.pop()
        //   }
        // })
      } // 这里是直接上传
      // if (this.upFile) {
      //   this.upFile({ body: data })
      //     .then((da) => {
      //       if (!self.multiple) {
      //         // 上传完成后隐藏正在上传
      //         self.$refs.uploading.style.display = 'none'
      //       }
      //       const currentPic = da.url
      //       if (self.multiple) {
      //         self.uploadList.pop()
      //         self.uploadList.push({
      //           url: currentPic,
      //           uid: '111',
      //           name: `图${self.uploadList.length + 1}`
      //         })
      //         self.$emit('imgupload', self.formatImgArr(self.uploadList))
      //         self.$emit('on-change', self.formatImgArr(self.uploadList), self.conf.valName)
      //       } else {
      //         this.file.url = currentPic
      //         self.$emit('imgupload', currentPic)
      //         self.$emit('on-change', currentPic, self.conf.valName)
      //       }
      //     }).catch(() => {
      //       // 上传失败则显示上传失败，如多图则从图片列表删除图片
      //       if (!self.multiple) {
      //         self.$refs.failUpload.style.display = 'block'
      //       } else {
      //         self.uploadList.pop()
      //       }
      //     })
      // }
      //
      // self.$api.apis.method.upFile({body: data})
      //     .then((da)=>{
      //             if (!self.multiple) {
      //                 // 上传完成后隐藏正在上传
      //                 self.$refs.uploading.style.display = 'none'
      //             }
      //             const currentPic = da.url
      //             if (self.multiple) {
      //                 self.uploadList.pop()
      //                 self.uploadList.push({
      //                     url: currentPic,
      //                     uid: '111'
      //                 })
      //                 self.$emit('imgupload', self.formatImgArr(self.uploadList))
      //                 self.$emit('on-change',self.formatImgArr(self.uploadList),self.conf.valName)
      //             } else {
      //                 this.file.url = currentPic
      //                 self.$emit('imgupload', currentPic)
      //                 self.$emit('on-change',currentPic,self.conf.valName)
      //             }
      //     }).catch(()=>{
      //             // 上传失败则显示上传失败，如多图则从图片列表删除图片
      //             if (!self.multiple) {
      //                 self.$refs.failUpload.style.display = 'block'
      //             } else {
      //                 self.uploadList.pop()
      //             }
      // })


      this.cropperModel = false;
    },
    formatImgArr: function formatImgArr(arr) {
      if (arr) {
        var result = arr.map(function (item, index) {
          if (typeof item === 'string') {
            return {
              url: item,
              uid: "index".concat(index),
              name: "\u56FE".concat(index + 1)
            };
          } else {
            return item.url;
          }
        });
        return result;
      } else {
        return [];
      }
    },
    beforeClose: function beforeClose(done) {
      //console.debug(done)
      this.uploadList.pop();
      this.cropperModel = false;
    },
    handleExceed: function handleExceed() {
      this.$notify.error({
        // title: response.status,
        title: '错误提示',
        message: "\u6700\u591A\u53EA\u80FD\u4E0A\u4F20".concat(this.limit, "\u5F20\u56FE\u7247")
      });
    }
  },
  components: {
    Cropper: Cropper
  }
};
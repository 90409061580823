//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'PaymentTimeUtil',
  data: function data() {
    return {
      startDate: "",
      endDate: "",
      pickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000; // - 86400000是否包括当天
          //return v.getTime() < new Date().getTime() ;// - 86400000是否包括当天
        }
      },
      endPickerOptions: {
        disabledDate: function disabledDate(v) {
          return v.getTime() < new Date().getTime() - 86400000; // - 86400000是否包括当天
          // return v.getTime() < new Date().getTime() ;// - 86400000是否包括当天
        }
      }
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {//this.initData()
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      this.startDate = "";
      this.endDate = "";

      if (this.dataSource["timeAttr"]) {
        if (this.dataSource["timeAttr"]["startTime"] > 0) {
          this.startDate = this.dataSource["timeAttr"]["startTime"];
        }

        if (this.dataSource["timeAttr"]["stopTime"] > 0) {
          this.endDate = this.dataSource["timeAttr"]["stopTime"];
        }

        this.handleChange();
      }
    },
    handleChange: function handleChange() {
      var _startDate = 0;
      var _endDate = 0;

      if (this.startDate != "") {
        _startDate = this.startDate;
      }

      if (this.endDate != "") {
        _endDate = this.endDate;
      }

      this.$emit('on-change', {
        startTime: _startDate,
        stopTime: _endDate
      }, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
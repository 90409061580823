import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'enrolmentPlanService',
  methods: [{
    name: 'list',
    url: '/enrolmentPlan/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/enrolmentPlan/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/enrolmentPlan/method/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'limitEdit',
    url: '/enrolmentPlan/method/limitEdit/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'release',
    url: '/enrolmentPlan/method/release/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'close',
    url: '/enrolmentPlan/method/close/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'all',
    url: '/enrolmentPlan/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'add',
    url: '/enrolmentPlan/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/enrolmentPlan/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'statistics',
    url: '/enrolmentPlan/method/statistics/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AffiliationCategorUtil',
  data: function data() {
    return {
      options: [],
      affiliationCategor: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      var _this = this;

      this.$api.apis.wagesCategoryService.all({
        params: {
          "isSystem,BOOL": "false",
          "level,INT": "<3"
        }
      }).then(function (data) {
        if (data) {
          var da = _this.packagTreeDataFn(data, "000000000000000000000000");

          da.push({
            value: 'yiji',
            label: '一级类目',
            sort: 0
          });
          da.sort(_this.compare("sort"));
          _this.options = da;

          if (_this.dataSource["oneWagesCategory"] && _this.dataSource["oneWagesCategory"].id && _this.dataSource["oneWagesCategory"].id != "000000000000000000000000") {
            _this.affiliationCategor = [];

            _this.affiliationCategor.push(_this.dataSource["oneWagesCategory"].id);

            if (_this.dataSource["twoWagesCategory"] && _this.dataSource["twoWagesCategory"].id && _this.dataSource["twoWagesCategory"].id != "000000000000000000000000") {
              _this.affiliationCategor.push(_this.dataSource["twoWagesCategory"].id);
            }
          } else {
            _this.affiliationCategor = ["yiji"];
          }

          _this.handleChange();
        }
      });
    },
    packagTreeDataFn: function packagTreeDataFn(dataList, parent_id) {
      var result = [],
          temp;

      for (var i in dataList) {
        if (dataList[i].oneWagesCategory.id == parent_id) {
          temp = this.packagTreeDataFn(dataList, dataList[i]._id);

          if (temp.length > 0) {
            result.push({
              value: String(dataList[i]._id),
              label: dataList[i].name,
              sort: dataList[i].sort,
              level: dataList[i].level,
              children: temp
            });
          } else {
            result.push({
              value: String(dataList[i]._id),
              label: dataList[i].name,
              sort: dataList[i].sort,
              level: dataList[i].level //children: temp,

            });
          }
        }
      }

      for (var j in result) {
        if (result[j].children) {
          result[j].children.sort(this.compare("sort"));
        }
      }

      result.sort(this.compare("sort"));
      return result;
    },
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    handleChange: function handleChange() {
      var oneWagesCategoryId = "";
      var twoWagesCategoryId = "";

      if (this.affiliationCategor.length > 0) {
        if (this.affiliationCategor.length == 2) {
          oneWagesCategoryId = this.affiliationCategor[0];
          twoWagesCategoryId = this.affiliationCategor[1];
        } else if (this.affiliationCategor.length == 1) {
          oneWagesCategoryId = this.affiliationCategor[0];
        }
      }

      var _v = {
        oneWagesCategoryId: oneWagesCategoryId,
        twoWagesCategoryId: twoWagesCategoryId
      };
      this.$emit('on-change', _v, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import cropper from '../../../components/zhc-crud/Cropper';
import utils from '@/libs/util';
export default {
  inject: ['cancel'],
  name: 'AdUtil',
  data: function data() {
    return {
      mark: "",
      adForm: {
        w: "1",
        ads: [{
          url: "",
          image: ""
        }]
      },
      adRules: {
        url: [],
        image: []
      },
      imageItem: {
        labelName: '图片上传',
        valName: 'image',
        about: '图片上传',
        type: 'cropper',
        value: '',
        closeCropper: true,
        fileSize: 512000,
        fileSizeStr: "500KB",
        upFileUrl: this.$api.apis.fileService.upFileUrl()
      }
    };
  },
  props: {
    moduleMark: String
  },
  components: {
    cropper: cropper
  },
  created: function created() {
    this.init();
  },
  methods: {
    editorChange: function editorChange(val, valName, index) {
      if (valName === 'image') {
        this.adForm.ads[index][valName] = val;
      }
    },
    onWchange: function onWchange() {
      if (Number(this.adForm.w) == 1) {
        var ads = [];
        ads.push(this.adForm.ads[0]);
        this.adForm.ads = ads;
      } else if (Number(this.adForm.w) == 2) {
        if (this.adForm.ads.length >= 2) {
          var _ads = [];

          _ads.push(this.adForm.ads[0]);

          _ads.push(this.adForm.ads[1]);

          this.adForm.ads = _ads;
        } else {
          this.adForm.ads.push({
            url: "",
            image: ""
          });
        }
      }
    },
    init: function init() {
      var _this = this;

      this.$api.apis.wxHomeConfigService.findModuleData({
        params: {
          type: "ad",
          mark: this.moduleMark
        }
      }).then(function (d) {
        if (d && d.mark) {
          _this.mark = d.mark;
        } else {
          _this.mark = "";
        }

        _this.adForm.w = "1";

        if (d.w != 0) {
          _this.adForm.w = d.w + "";
        }

        if (d && d.data && d.data.length > 0) {
          _this.adForm.ads = d.data;
        } else {
          _this.adForm.ads = [{
            url: "",
            image: ""
          }];
        }
      });
    },
    onCancel: function onCancel() {
      this.cancel();
    },
    onDelModule: function onDelModule() {
      var _this2 = this;

      //删除模块
      this.$confirm('是否删除广告位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (!_this2.mark) {
          _this2.cancel();

          return;
        }

        var _body = {
          type: "ad",
          mark: _this2.mark
        };

        _this2.$api.apis.wxHomeConfigService.delModule({
          body: _body,
          isAes: true,
          aesKey: utils.cookies.get('salt')
        }).then(function () {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.cancel();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    onSave: function onSave() {
      var _this3 = this;

      this.$refs["adForm"].validate(function (valid) {
        if (valid) {
          var _body = {
            type: "ad",
            data: _this3.adForm.ads,
            w: Number(_this3.adForm.w),
            mark: _this3.mark
          };

          _this3.$api.apis.wxHomeConfigService.addModuleData({
            body: _body,
            isAes: true,
            aesKey: utils.cookies.get('salt')
          }).then(function () {
            _this3.$message({
              type: 'success',
              message: '保存成功!'
            });

            _this3.init();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  },
  watch: {
    moduleMark: {
      handler: function handler() {
        this.init();
      },
      // 深度观察
      deep: true
    }
  }
};
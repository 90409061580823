import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'commonService',
  methods: [{
    name: 'findAreas',
    url: '/common/method/findAreas',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findBusinessDistrict',
    url: '/common/method/findBusinessDistrict/{areaCode}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findIndustryClass',
    url: '/tag/method/findIndustryClass',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
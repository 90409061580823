var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        [
          _c(
            "el-checkbox-group",
            {
              on: { change: _vm.handleCheckedCitiesChange },
              model: {
                value: _vm.checkList,
                callback: function($$v) {
                  _vm.checkList = $$v
                },
                expression: "checkList"
              }
            },
            [
              _vm._l(_vm.paymentItems, function(item) {
                return [
                  _c(
                    "el-col",
                    { key: item.ikey, staticClass: "das", attrs: { span: 24 } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { label: item.ikey, disabled: item.isDisabled }
                        },
                        [
                          _c(
                            "el-row",
                            { staticStyle: { width: "700px" } },
                            [
                              _c("el-col", { attrs: { span: 12 } }, [
                                _c("div", [
                                  _c("span", [
                                    _vm._v(" " + _vm._s(item.rename + ": "))
                                  ]),
                                  _c("span", [
                                    _vm._v(_vm._s(item.amountStr + "元"))
                                  ]),
                                  item.originalAmountStr != item.amountStr
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "text-decoration": "line-through",
                                            "margin-left": "10px"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              "(原" +
                                                item.originalAmountStr +
                                                "元)"
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              ]),
                              _c("el-col", { attrs: { span: 8 } }, [
                                item.isChecked &&
                                item.originalAmountStr == item.amountStr
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "JavaScript:;" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onPreferential(
                                                item.ikey
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("添加优惠")]
                                      )
                                    ])
                                  : _vm._e(),
                                item.isChecked &&
                                item.originalAmountStr != item.amountStr
                                  ? _c("div", [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "JavaScript:;" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onPreferential(
                                                item.ikey
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("修改优惠")]
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _c("div", [
            _c("span", [
              _vm._v(
                "费用合计：" +
                  _vm._s(_vm.totalAmount) +
                  "元 优惠减免：" +
                  _vm._s(_vm.reductionAmount) +
                  "元 应缴金额：" +
                  _vm._s(_vm.realityAmount) +
                  "元"
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "优惠金额",
            visible: _vm.dialogFormVisible,
            width: "40%",
            "label-position": "top",
            "label-width": "100px",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "el-form",
            { attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原金额" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: true },
                    model: {
                      value: _vm.form.originalAmountStr,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "originalAmountStr", $$v)
                      },
                      expression: "form.originalAmountStr"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "优惠后金额" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.form.amountStr,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "amountStr", $$v)
                      },
                      expression: "form.amountStr"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onConfirm } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
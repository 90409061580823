var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "query2",
            { ref: "query", attrs: { config: _vm.queryConfig } },
            [
              _c("h3", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.title))]),
              _c("AuthorityExplain")
            ],
            1
          )
        ],
        1
      ),
      [
        _c(
          "div",
          { staticClass: "dy-mobile-layout" },
          [
            _c("div", { staticClass: "dml-hd" }, [
              _c("p", { staticClass: "dml-dec" }, [_vm._v("选择配置功能")]),
              _c("ul", { staticClass: "dml-menu" }, [
                _c(
                  "li",
                  { class: _vm.type == "advertisement" ? "current" : "" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "JavaScript:;" },
                        on: {
                          click: function($event) {
                            return _vm.addModule("ad")
                          }
                        }
                      },
                      [_vm._v("广告位")]
                    )
                  ]
                ),
                _c(
                  "li",
                  { class: _vm.type == "fiveFlipChar" ? "current" : "" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "JavaScript:;" },
                        on: {
                          click: function($event) {
                            return _vm.addModule("banner")
                          }
                        }
                      },
                      [_vm._v("五翻图")]
                    )
                  ]
                ),
                _c("li", { class: _vm.type == "appH5" ? "appH5" : "" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "JavaScript:;" },
                      on: {
                        click: function($event) {
                          return _vm.addModule("appH5")
                        }
                      }
                    },
                    [_vm._v("网页应用")]
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "dml-bd" }, [
              _c("div", { staticClass: "iphone-8" }, [
                _c("div", { staticClass: "i8-hd" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/pic-phone-hd.svg"),
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "tit" }, [_vm._v("首页")])
                ]),
                _c(
                  "div",
                  { staticClass: "i8-bd" },
                  [
                    _vm._l(_vm.modules, function(mo, index) {
                      return [
                        mo.type === "banner"
                          ? _c(
                              "div",
                              {
                                key: "bra" + index,
                                staticClass: "swiper",
                                on: {
                                  click: function($event) {
                                    return _vm.onFrom("banner", mo.mark)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "item" },
                                  [
                                    _c(
                                      "el-carousel",
                                      {
                                        attrs: {
                                          trigger: "click",
                                          height: "165px"
                                        }
                                      },
                                      _vm._l(mo.data, function(d) {
                                        return _c(
                                          "el-carousel-item",
                                          { key: d.mark },
                                          [
                                            d.image
                                              ? _c("img", {
                                                  attrs: {
                                                    src: d.image,
                                                    alt: ""
                                                  }
                                                })
                                              : _c("img", {
                                                  attrs: {
                                                    src: require("@/assets/images/banner-swiper-1.png"),
                                                    alt: ""
                                                  }
                                                })
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }),
                    _c(
                      "div",
                      { staticClass: "ad" },
                      [
                        _vm._l(_vm.modules, function(mo, index) {
                          return [
                            mo.type === "ad"
                              ? _c(
                                  "el-row",
                                  { key: "ad" + index, attrs: { gutter: 10 } },
                                  [
                                    mo.w === 2
                                      ? _vm._l(mo.data, function(d) {
                                          return _c(
                                            "el-col",
                                            {
                                              key: d.mark,
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onFrom(
                                                        "ad",
                                                        mo.mark
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  d.image
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: d.image,
                                                          alt: ""
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/banner-ad-1.jpg"),
                                                          alt: ""
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        })
                                      : _vm._e(),
                                    mo.w === 1 || mo.w === 0
                                      ? [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onFrom(
                                                        "ad",
                                                        mo.mark
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  mo.data[0] && mo.data[0].image
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: mo.data[0].image,
                                                          alt: ""
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/banner-ad-1.jpg"),
                                                          alt: ""
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "entrance current" },
                      [
                        _vm._l(_vm.modules, function(mo, index) {
                          return [
                            mo.type === "course"
                              ? _c(
                                  "el-row",
                                  {
                                    key: "course" + index,
                                    attrs: { gutter: 10 }
                                  },
                                  [
                                    mo.w === 2
                                      ? _vm._l(mo.data, function(d) {
                                          return _c(
                                            "el-col",
                                            {
                                              key: d.mark,
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onFrom(
                                                        "course",
                                                        mo.mark
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  d.image
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: d.image,
                                                          alt: ""
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/banner-entrance-1.jpg"),
                                                          alt: ""
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        })
                                      : _vm._e(),
                                    mo.w === 1 || mo.w === 0
                                      ? [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onFrom(
                                                        "course",
                                                        mo.mark
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  mo.data[0] && mo.data[0].image
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: mo.data[0].image,
                                                          alt: ""
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/banner-entrance-1.jpg"),
                                                          alt: ""
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    ),
                    _c(
                      "div",
                      { staticClass: "ad" },
                      [
                        _vm._l(_vm.modules, function(mo, index) {
                          return [
                            mo.type === "appH5"
                              ? _c(
                                  "el-row",
                                  {
                                    key: "appH5" + index,
                                    attrs: { gutter: 10 }
                                  },
                                  [
                                    mo.w === 2
                                      ? _vm._l(mo.data, function(d) {
                                          return _c(
                                            "el-col",
                                            {
                                              key: d.mark,
                                              attrs: { span: 12 }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onFrom(
                                                        "appH5",
                                                        mo.mark
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  d.image
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: d.image,
                                                          alt: ""
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/banner-ad-1.jpg"),
                                                          alt: ""
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        })
                                      : _vm._e(),
                                    mo.w === 1 || mo.w === 0
                                      ? [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 24 } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "item",
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.onFrom(
                                                        "appH5",
                                                        mo.mark
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  mo.data[0] && mo.data[0].image
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: mo.data[0].image,
                                                          alt: ""
                                                        }
                                                      })
                                                    : _c("img", {
                                                        attrs: {
                                                          src: require("@/assets/images/banner-ad-1.jpg"),
                                                          alt: ""
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        })
                      ],
                      2
                    )
                  ],
                  2
                )
              ])
            ]),
            _vm.type === "banner"
              ? _c("FiveFlipCharUtil", { attrs: { moduleMark: _vm.mark } })
              : _vm._e(),
            _vm.type === "ad"
              ? _c("AdUtil", { attrs: { moduleMark: _vm.mark } })
              : _vm._e(),
            _vm.type === "course"
              ? _c("CourseUtil", { attrs: { moduleMark: _vm.mark } })
              : _vm._e(),
            _vm.type === "appH5"
              ? _c("AppH5Util", { attrs: { moduleMark: _vm.mark } })
              : _vm._e()
          ],
          1
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SubjectUtil',
  data: function data() {
    return {
      subjects: [],
      staffs: [],
      classStaffs: [],
      deputyClassStaffs: [],
      gradeLeaderStaffs: [],
      classAdviser: "",
      classAdviserDeputy: ""
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      var _this = this;

      this.options = [];
      this.$api.apis.teachingArrangeService.findSubjectsAndTeacher({
        path: {
          id: this.$route.params.id
        }
      }).then(function (data) {
        if (data) {
          _this.subjects = data.subjects;
          _this.staffs = data.staffs;
          var isHandleChange = false;

          for (var i = 0; i < _this.subjects.length; i++) {
            var st = [];

            if (_this.dataSource && _this.dataSource["subjectTeacher"]) {
              for (var j = 0; j < _this.dataSource["subjectTeacher"].length; j++) {
                var subjectTeacher = _this.dataSource["subjectTeacher"][j];

                if (subjectTeacher.subject.tag == _this.subjects[i].tag) {
                  for (var z = 0; z < subjectTeacher.teachers.length; z++) {
                    st.push(subjectTeacher.teachers[z].id);
                  }

                  break;
                }
              }

              isHandleChange = true;
            }

            _this.subjects[i]["teachers"] = st;
          }

          if (_this.dataSource && _this.dataSource["classAdviser"] && _this.dataSource["classAdviser"].id != "000000000000000000000000") {
            _this.classAdviser = _this.dataSource["classAdviser"].id;
            isHandleChange = true;
          }

          if (_this.dataSource && _this.dataSource["classAdviserDeputy"] && _this.dataSource["classAdviserDeputy"].id != "000000000000000000000000") {
            _this.classAdviserDeputy = _this.dataSource["classAdviserDeputy"].id;
            isHandleChange = true;
          }

          if (isHandleChange) {
            _this.handleChange();
          }
        }
      });
    },
    //排序
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    handleChange: function handleChange() {
      for (var i = 0; i < this.subjects.length; i++) {
        this.$set(this.subjects, i, this.subjects[i]);
      }

      var _v = {
        classAdviser: this.classAdviser,
        classAdviserDeputy: this.classAdviserDeputy,
        subjects: this.subjects
      };
      this.$emit('on-change', _v, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        remote: "",
        "reserve-keyword": "",
        placeholder: _vm.conf.about,
        "remote-method": _vm.remoteMethod,
        loading: _vm.loading
      },
      on: { change: _vm.selectChange },
      model: {
        value: _vm.value,
        callback: function($$v) {
          _vm.value = $$v
        },
        expression: "value"
      }
    },
    _vm._l(_vm.options, function(item) {
      return _c("el-option", {
        key: item._id,
        attrs: { label: item.name, value: item._id }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
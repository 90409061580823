//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { VueCropper } from "vue-cropper";
import utils from "@/libs/util";
export default {
  name: "CropperUtil",
  data: function data() {
    return {
      previews: {},
      // 预览数据
      option: {
        img: "",
        // 裁剪图片的地址  (默认：空)
        size: 1,
        // 裁剪生成图片的质量  (默认:1)
        full: true,
        // 是否输出原图比例的截图 选true生成的图片会非常大  (默认:false)
        outputType: "png",
        // 裁剪生成图片的格式  (默认:jpg)
        canMove: true,
        // 上传图片是否可以移动  (默认:true)
        original: false,
        // 上传图片按照原始比例渲染  (默认:false)
        canMoveBox: true,
        // 截图框能否拖动  (默认:true)
        autoCrop: true,
        // 是否默认生成截图框  (默认:false)
        autoCropWidth: 400,
        // 默认生成截图框宽度  (默认:80%)
        autoCropHeight: 400,
        // 默认生成截图框高度  (默认:80%)
        fixedBox: false,
        // 固定截图框大小 不允许改变  (默认:false)
        fixed: true,
        // 是否开启截图框宽高固定比例  (默认:true)
        fixedNumber: [1.5, 1] // 截图框比例  (默认:[1:1])

      },
      downImg: "#"
    };
  },
  props: {
    imgFile: Object,
    fixedNumber: Array,
    fixed: {
      type: Boolean,
      default: true
    },
    fixedBox: {
      type: Boolean,
      default: false
    },
    canMove: {
      type: Boolean,
      default: true
    },
    canMoveBox: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    changeScale: function changeScale(num) {
      // 图片缩放
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    rotateLeft: function rotateLeft() {
      // 向左旋转
      this.$refs.cropper.rotateLeft();
    },
    rotateRight: function rotateRight() {
      // 向右旋转
      this.$refs.cropper.rotateRight();
    },
    Update: function Update() {
      // this.file = this.imgFile
      this.option.img = this.imgFile.url;
    },
    realTime: function realTime(data) {
      // 实时预览
      this.previews = data;
    },
    uploadImg: function uploadImg(type) {
      // 将剪裁好的图片回传给父组件
      event.preventDefault();
      var that = this;

      if (type === "blob") {
        this.$refs.cropper.getCropBlob(function (data) {
          var f = utils.other.blobToFile(utils.other.dataURLtoBlob(data), "imgName");
          that.$emit("upload", data, f);
        });
      } else {
        this.$refs.cropper.getCropData(function (data) {
          var f = utils.other.blobToFile(utils.other.dataURLtoBlob(data), "imgName");
          that.$emit("upload", data, f);
        });
      }
    }
  },
  components: {
    VueCropper: VueCropper
  }
};
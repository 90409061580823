import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'userService',
  methods: [{
    name: 'all',
    url: '/user/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/user/method/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
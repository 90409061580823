import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "CustomAmountUtil",
  data: function data() {
    return {
      options: [{
        text: "允许用户手动输入充值金额（仅整数）",
        value: "0"
      }, {
        text: "允许用户手动输入充值金额（含小数）",
        value: "1"
      }, {
        text: "不允许手动输入充值金额（仅按档位充值）",
        value: "2"
      }],
      value: "2",
      minAmountModel: {
        minAmount: ""
      }
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  mounted: function mounted() {},
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      if (this.dataSource["_id"] && this.dataSource["_id"] != "000000000000000000000000") {
        this.value = String(this.dataSource["customAmountType"]);

        if (this.dataSource["minAmount"] > 0) {
          this.minAmountModel.minAmount = parseFloat(this.dataSource["minAmount"] / 100).toFixed(2);
        }
      }

      this.onChange();
    },
    onChange: function onChange() {
      var _v = {
        customAmountType: this.value,
        minAmount: this.minAmountModel.minAmount
      };
      this.$emit('on-change', _v, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.index-of";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import "core-js/modules/es.string.trim";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import util from '@/libs/util.js';
import * as config from './config';
import paginationComponents from './Pagination';
export default {
  name: 'List',
  data: function data() {
    return {
      conf: util.other.copy(config.listConfigBase),
      pageSource: util.other.copy(config.pageSourceConfigBase),
      queryParams: {},
      // 其他的查询条件
      tempOperationNum: -1,
      // 当前点击的是那个列表的操作
      page: 1,
      oldPage: 0,
      // 老页面编号
      lastId: '',
      firstId: '',
      totalPages: 0,
      // page 总数
      totalElements: 0,
      // 总共多少条数
      currentPageArgs: null // 保留当前页面 查询的参数，用来再次刷新
      // changeDirection: false,//改变方向, 是否改变查询方向 true 为改变，false 为没改变

    };
  },
  props: {
    config: Object
  },
  created: function created() {
    this.conf = config.listConfig(this.config);
  },
  mounted: function mounted() {},
  components: {
    paginationComponents: paginationComponents
  },
  filters: {
    generalFilter: function generalFilter(row, fnc, valName) {
      var val = row;

      if (valName) {
        val = valName.indexOf('.') < 0 ? row[valName] : row[valName.split('.')[0]][valName.split('.')[1]];
      }

      if (fnc) {
        return fnc(val);
      } else {
        return val;
      }
    }
  },
  methods: {
    clickOperation: function clickOperation(val) {
      // this.hideOperation()
      val[0] && val[0](val[1]);
    },
    prevPage: function prevPage() {
      //console.debug('setPage')
      this.page = this.page - 1;
      this.doSelect(-1, this.conf.pageSize, this.queryParams, {
        lastId: this.firstId
      });
    },
    nextPage: function nextPage() {
      //console.debug('setPage')
      this.page = this.page + 1;
      this.doSelect(1, this.conf.pageSize, this.queryParams, {
        lastId: this.lastId
      });
    },
    setPage: function setPage(page) {
      //console.debug('setPage')
      this.oldPage = this.page;
      this.page = page;
      var opt = {
        lastId: page - this.oldPage > 0 ? this.lastId : this.firstId
      };
      this.doSelect(page - this.oldPage, this.conf.pageSize, this.queryParams, opt);
    },
    setQueryParams: function setQueryParams(queryParams) {
      this.queryParams = queryParams;
    },
    setQueryParamsOnRefresh: function setQueryParamsOnRefresh(queryParams) {
      this.queryParams = queryParams;
      this.oldPage = 0;
      this.page = 1;
      this.doSelect(1, this.conf.pageSize, this.queryParams);
    },
    onRefresh: function onRefresh() {
      //console.debug('onRefresh')
      this.doSelect(1, this.conf.pageSize, this.queryParams);
    },
    onRefreshCurrentPage: function onRefreshCurrentPage() {
      // 刷新当前页面
      //console.debug('onRefreshCurrentPage')
      if (this.currentPageArgs) {
        this._doSel(this.currentPageArgs);
      } else {
        this.onRefresh();
      }
    },
    doSelect: function doSelect(page, size, urlParams, opt) {
      //console.debug('doSelect')
      //console.debug(urlParams)
      //console.debug(opt)
      var params = {};

      for (var k in urlParams) {
        params[k] = urlParams[k];
      }

      if (page || page === 0) {
        params.page = page;
      }

      if (size || size === 0) {
        params.size = size;
      }

      if (this.conf.sort.trim().length > 0) {
        params.sort = this.conf.sort;
      }

      if (!params.page) {
        params.page = this.page;
      }

      if (opt && opt.lastId && opt.lastId !== '') {
        params.lastId = opt.lastId;
      } //console.log(params)


      var args = util.other.copy(this.conf.pageSourceFuncArgs);

      if (args.params) {
        for (var _k in params) {
          if (_k === 'where') {
            var ap = {};

            if (args.params[_k]) {
              if (typeof args.params[_k] === 'string') {
                ap = JSON.parse(args.params[_k]);
              } else {
                ap = util.other.copy(args.params[_k]);
              }
            }

            var p = {};

            if (typeof params[_k] === 'string') {
              p = JSON.parse(params[_k]);
            } else {
              p = util.other.copy(params[_k]);
            }

            for (var wk in p) {
              ap[wk] = p[wk];
            }

            args.params[_k] = JSON.stringify(ap);
          } else {
            args.params[_k] = params[_k];
          }
        }
      } else {
        args.params = params;
      }

      this._doSel(args);
    },
    _doSel: function _doSel(args) {
      var _this = this;

      this.currentPageArgs = args;
      this.conf.pageSourceFunc(args).then(function (data) {
        var first = _this.page === 1;
        var last = _this.page === _this.totalPages;
        _this.firstId = data.firstId;
        _this.lastId = data.lastId; //

        _this.pageSource.size = data.size;
        _this.pageSource.totalElements = data.totalElements;

        if (data.totalPages || data.totalPages === 0) {
          _this.totalPages = data.totalPages;
          _this.pageSource.totalPages = data.totalPages;
        } else {
          _this.pageSource.totalPages = _this.totalPages;
        }

        if (data.totalElements || data.totalElements === 0) {
          _this.totalElements = data.totalElements;
          _this.pageSource.totalElements = data.totalElements;
        } else {
          _this.pageSource.totalElements = _this.totalElements;
        }

        _this.pageSource.number = _this.page - 1;
        _this.pageSource.first = first;
        _this.pageSource.last = last;
        _this.pageSource.numberOfElements = data.numberOfElements;
        _this.pageSource.sort = data.sort;
        _this.pageSource.content = data.content; //  异步获取显示

        _this.conf.column.forEach(function (co) {
          if (co.asyncValue) {
            _this.pageSource.content.forEach(function (c) {
              co.asyncValue(c);
            });
          }
        });
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //console.debug(val)
      this.conf.multiSelectValue = val;
    },
    handleSelect: function handleSelect(val, row) {
      var _this2 = this;

      //console.debug(row)
      // this.conf.multiSelectValue = val;
      var d = []; // 需要删除的

      var ad = []; // 需要添加的

      for (var i in this.conf.multiSelectValue) {
        var item = this.conf.multiSelectValue[i];

        if (item === row) {
          d.push(i);
        } else {
          ad.push(item);
        }
      }

      d.forEach(function (i) {
        delete _this2.conf.multiSelectValue[i];
      });
      ad.forEach(function (item) {
        _this2.conf.multiSelectValue.push(item);
      });
      this.conf.multiSelectValue.forEach(function (row) {
        _this2.$refs.multipleTable.toggleRowSelection(row);
      });
    },
    expandChange: function expandChange(row, expandedRows) {
      //console.debug(expandedRows)
      if (this.conf.expand.has && this.conf.expand.asyncValue && !row.endExpandAsyncValue) {
        this.conf.expand.asyncValue(row);
        row.endExpandAsyncValue = true;
      }
    },
    sortChange: function sortChange(val) {
      //console.debug(val)
      //console.debug(this.conf.sort)
      if (val.prop && val.order) {
        // let order = 'ASC'
        var order = '-';

        if (val.order === 'ascending') {
          // order = 'ASC'
          order = '-';
        }

        if (val.order === 'descending') {
          // order = 'DESC'
          order = '';
        }

        this.conf.sort = order + '' + val.prop;
      } else {
        this.conf.sort = '';
      }

      if (this.conf.sortFunc) {
        this.conf.sortFunc && this.conf.sortFunc(val, this.conf);
      } else {
        this.onRefresh();
      }
    },
    getMultiSelectValue: function getMultiSelectValue() {
      return this.conf.multiSelectValue;
    },
    getOptShow: function getOptShow(operation, row) {
      var count = 0;

      for (var i in operation) {
        var o = operation[i];

        if (!o.showFunc || o.showFunc(row)) {
          count++;
        }
      }

      return count;
    },
    getOptShowMenu: function getOptShowMenu(operation, row) {
      // let count = 0
      var menus = [];

      for (var i in operation) {
        var o = operation[i];

        if (!o.showFunc || o.showFunc(row)) {
          // count++
          menus.push(o);
        }
      }

      return menus;
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'teachingArrangeService',
  methods: [{
    name: 'list',
    url: '/teachingArrange/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/teachingArrange/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findSubjectsAndTeacher',
    url: '/teachingArrange/method/findSubjectsAndTeacher/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findGradeAndTeacher',
    url: '/teachingArrange/method/findGradeAndTeacher',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upGradeLeader',
    url: '/teachingArrange/method/upGradeLeader',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/teachingArrange/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.placeholder
        ? _c("el-alert", {
            attrs: { title: _vm.placeholder, type: "info", closable: false }
          })
        : _vm._e(),
      !_vm.disabled
        ? _c("vue-editor", {
            staticStyle: { height: "300px", "margin-bottom": "50px" },
            attrs: {
              useCustomImageHandler: _vm.isBase64,
              height: 500,
              "disabled:": _vm.disabled
            },
            on: { imageAdded: _vm.handleImageAdded },
            model: {
              value: _vm.content,
              callback: function($$v) {
                _vm.content = $$v
              },
              expression: "content"
            }
          })
        : _vm._e(),
      _vm.disabled
        ? _c("div", {
            staticStyle: { "max-height": "300px", overflow: "auto" },
            domProps: { innerHTML: _vm._s(_vm.content) }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.array.concat";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 * 在主框架内显示
 */


var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [// 修改密码
  {
    path: 'myChangePassword',
    name: 'myChangePassword',
    meta: {
      title: '修改密码',
      auth: true
    },
    component: _import('authority/changePassword')
  }, //角色编辑
  {
    path: 'role/customEdit/:id',
    name: 'roleCustomEdit',
    meta: {
      title: '角色编辑',
      auth: true
    },
    component: _import('role/customEdit')
  }, //学生问券下一步页面
  {
    path: 'studentQuestionnaire/two/:id',
    name: 'studentQuestionnaireTwo',
    meta: {
      title: '学生问卷编辑',
      auth: true,
      cache: false,
      groupId: "studentQuestionnaire",
      groupName: "学生问卷"
    },
    component: _import('studentQuestionnaire/two')
  }, //收费主题导入编辑页面
  {
    path: 'studentCharge/editImport/:id',
    name: 'studentChargeEditImport',
    meta: {
      title: '学生收费编辑',
      auth: true
    },
    component: _import('studentCharge/editImport')
  }, //入学计划编辑部分页面
  {
    path: 'enrolment/edit/:id',
    name: 'enrolmentLimitEdit',
    meta: {
      title: '入学注册编辑',
      auth: true
    },
    component: _import('enrolment/limitEdit')
  }, //费用管理部分页面
  {
    path: 'enrolment/orderInfo/:id',
    name: 'enrolmenOrderCostInfo',
    meta: {
      title: '费用管理',
      auth: true
    },
    component: _import('enrolment/orderCostInfo')
  }, //自定义统计
  {
    path: 'studentQuestionnaire/statistics/:id',
    name: 'studentQuestionnaireStatistics',
    meta: {
      title: '调查问卷统计',
      auth: true
    },
    component: _import('studentQuestionnaire/statistics')
  }, //自定义统计
  {
    path: 'showerRule/adminCard/list/:id',
    name: 'showerAdminCardList',
    meta: {
      title: '洗浴管理员卡',
      auth: true
    },
    component: _import('showerRule/adminCard/list')
  }, // {
  //   path: 'wagesInfo/:id/:affiliationMonth',
  //   name: 'wagesInfo',
  //   meta: {
  //     title: '工资详情',
  //     auth: true,
  //     cache:false,
  //     groupId: "wages",
  //     groupName: "工资详情",
  //   },
  //   component: _import('wages/info')
  // },
  // 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true
    },
    component: _import('system/index')
  }, // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  } // 订单课程详情
  // {
  //   path: 'orderCourseInfo/:id',
  //   name: 'orderCourseInfo',
  //   meta: {
  //     title:"课程详情",
  //     auth: true
  //   },
  //   component: _import('courseOrder/courseInfo')
  // },
  ]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/login') // component: _import('system/error/404')

}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default [].concat(frameOut, errorPage);
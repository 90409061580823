var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "联系电话",
            visible: _vm.dialogVisible,
            width: "30%",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c("div", [
            _vm.sinfo.student
              ? _c(
                  "h3",
                  {
                    staticStyle: {
                      padding: "4px 8px",
                      "font-weight": "normal",
                      "font-size": "14px",
                      color: "rgb(84, 84, 84)",
                      margin: "0",
                      "border-radius": "4px",
                      background: "#f5f5f5"
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.sinfo.student.name) +
                        "(" +
                        _vm._s(
                          _vm._f("genderFilter")(_vm.sinfo.student.gender)
                        ) +
                        ") "
                    )
                  ]
                )
              : _vm._e()
          ]),
          [
            _c(
              "el-table",
              { staticStyle: { width: "100%" }, attrs: { data: _vm.parents } },
              [
                _c("el-table-column", {
                  attrs: { label: "关系", width: "80" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "name-wrapper",
                              attrs: { slot: "reference" },
                              slot: "reference"
                            },
                            [
                              _c("el-tag", { attrs: { size: "medium" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("parentalIdentityFilter")(
                                      scope.row.parentalIdentity
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "电话", width: "120" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [_c("span", [_vm._v(_vm._s(scope.row.account))])]
                      }
                    }
                  ])
                }),
                _c("el-table-column", {
                  attrs: { label: "操作" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function($event) {
                                  return _vm.handleCopy(scope.row.account)
                                }
                              }
                            },
                            [_vm._v("复制")]
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            )
          ],
          _c("span", {
            staticClass: "dialog-footer",
            attrs: { slot: "footer" },
            slot: "footer"
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
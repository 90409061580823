var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d2-layout-header-aside-group",
      class: { grayMode: _vm.grayActive },
      style: _vm.styleLayoutMainGroup
    },
    [
      _c("div", { staticClass: "d2-layout-header-aside-mask" }),
      _c(
        "div",
        {
          staticClass: "d2-layout-header-aside-content",
          attrs: { flex: "dir:top" }
        },
        [
          _c(
            "div",
            {
              staticClass: "d2-theme-header",
              style: { opacity: this.searchActive ? 0.5 : 1 },
              attrs: { "flex-box": "0", flex: "" }
            },
            [
              _c(
                "router-link",
                {
                  class: {
                    "logo-group": true,
                    "logo-transition": _vm.asideTransition
                  },
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.asideWidth
                  },
                  attrs: { to: "/index", "flex-box": "0" }
                },
                [
                  _vm.asideCollapse
                    ? _c("img", {
                        attrs: { src: require("@/assets/images/all1.png") }
                      })
                    : _c("img", {
                        attrs: { src: require("@/assets/images/all-max.png") }
                      })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "toggle-aside-btn",
                  attrs: { "flex-box": "0" },
                  on: { click: _vm.handleToggleAside }
                },
                [_c("d2-icon", { attrs: { name: "bars" } })],
                1
              ),
              _c("div", { attrs: { "flex-box": "1" } }, [
                _c("div", { staticClass: "d2-header-left" }, [
                  _c("h3", { staticClass: "tit" }, [
                    _vm._v(_vm._s("" + _vm.info.merchantName)),
                    _c("em", [_vm._v("燃课校园新质教育基础设施平台")])
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "d2-header-right", attrs: { "flex-box": "0" } },
                [_c("d2-header-user")],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d2-theme-container",
              attrs: { "flex-box": "1", flex: "" }
            },
            [
              _c(
                "div",
                {
                  ref: "aside",
                  class: {
                    "d2-theme-container-aside": true,
                    "d2-theme-container-transition": _vm.asideTransition
                  },
                  style: {
                    width: _vm.asideCollapse
                      ? _vm.asideWidthCollapse
                      : _vm.asideWidth,
                    opacity: this.searchActive ? 0.5 : 1
                  },
                  attrs: { "flex-box": "0" }
                },
                [_c("d2-menu-side")],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "d2-theme-container-main",
                  attrs: { "flex-box": "1", flex: "" }
                },
                [
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    _vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "" }
                          },
                          [
                            _c("d2-panel-search", {
                              ref: "panelSearch",
                              on: { close: _vm.searchPanelClose }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("transition", { attrs: { name: "fade-scale" } }, [
                    !_vm.searchActive
                      ? _c(
                          "div",
                          {
                            staticClass: "d2-theme-container-main-layer",
                            attrs: { flex: "dir:top" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-header",
                                attrs: { "flex-box": "0" }
                              },
                              [_c("d2-tabs")],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d2-theme-container-main-body",
                                attrs: { "flex-box": "1" }
                              },
                              [
                                _c(
                                  "transition",
                                  {
                                    attrs: {
                                      name: _vm.transitionActive
                                        ? "fade-transverse"
                                        : ""
                                    }
                                  },
                                  [
                                    _c(
                                      "keep-alive",
                                      { attrs: { include: _vm.keepAlive } },
                                      [
                                        _c("router-view", {
                                          key: _vm.routerViewKey
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
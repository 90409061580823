import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'semesterService',
  methods: [{
    name: 'all',
    url: '/semester/method/all',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
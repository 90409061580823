import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import util from '@/libs/util.js';
export default {
  inject: ['refresh'],
  name: 'ExcelUpUtil',
  props: {},
  components: {},
  data: function data() {
    return {
      limit: 1,
      dialogVisible: false,
      fileList: [] // errMessages: [],
      // failCount: 0,
      // successCount: 0,
      // repeatCount: 0,
      // accept: '',
      // grades: [],
      // branchMerchants: [],
      // gradeForm: {
      //   gradeNum: "",
      //   branchMerchantId: ""
      // },

    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onExport: function onExport() {
      var token = util.cookies.get('token');

      var _paramsStr = 'authorization=Bearer ' + token;

      var _url = '';

      if (window.location.host.indexOf('localhost') > -1) {
        _url = 'http://localhost:8091/api/excelExport/method/wagesTemplate?' + _paramsStr;
      } else if (window.location.host.indexOf('114.116.102.201') > -1) {
        _url = 'http://114.116.102.201:31141/api/excelExport/method/wagesTemplate?' + _paramsStr;
      } else if (window.location.host.indexOf('sch.rankee.cn') > -1) {
        _url = 'https://sch.rankee.cn/api/excelExport/method/wagesTemplate?' + _paramsStr;
      }

      window.open(_url);
    },
    handleExceed: function handleExceed() {
      this.$notify.error({
        // title: response.status,
        title: '错误提示',
        message: "\u6700\u591A\u53EA\u80FD\u4E0A\u4F20".concat(this.limit, "\u4EFD\u6587\u4EF6")
      });
    },
    consoleFL: function consoleFL(file, fileList) {
      var _this = this;

      // 弹出剪裁框，将当前文件设置为文件
      var fileType = '.xlsx';

      if (file.raw.name.indexOf(fileType) === -1) {
        var fileTypeArr = fileType.split('image/');
        this.$message.error('只能上传' + fileTypeArr.join(' ') + '格式文件');
        this.fileList.splice(this.fileList.length, 1);
      } else {
        //this.file = file
        this.fileList = fileList;
        var reader = new window.FileReader();

        reader.onload = function (ef) {
          //this.upload(ef.target.result, file.raw)
          _this.refresh(ef.target.result, file.raw);
        };

        reader.readAsDataURL(file.raw);
      }
    },
    handleRemove: function handleRemove(file, fileList) {//console.log(file, fileList);
    },
    handlePreview: function handlePreview(file) {//console.log(file);
    }
  },
  watch: {// excelDialogVisible: {
    //   handler () {
    //     this.dialogVisible = this.excelDialogVisible
    //     if (this.dialogVisible){
    //       this.initData()
    //     }
    //   },
    //   deep: true
    // }
  }
};
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-screen" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "m-form",
          attrs: { "label-width": "96px", size: "medium" },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "screen-hd" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 17 } },
                    [
                      _vm.conf.query.length > 0
                        ? _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _vm._l(_vm.conf.query, function(item, index) {
                                return _c(
                                  "el-col",
                                  { key: "query" + index, attrs: { span: 8 } },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "grid-content" },
                                      [
                                        [
                                          index < 2
                                            ? _c(
                                                "el-form-item",
                                                { attrs: { label: item.name } },
                                                [
                                                  item.type === "text"
                                                    ? _c("el-input", {
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            item.name,
                                                          clearable: ""
                                                        },
                                                        model: {
                                                          value: item.value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "value",
                                                              typeof $$v ===
                                                                "string"
                                                                ? $$v.trim()
                                                                : $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.value"
                                                        }
                                                      })
                                                    : item.type === "date"
                                                    ? _c("el-date-picker", {
                                                        attrs: {
                                                          format: "yyyy/MM/dd",
                                                          type: "date",
                                                          placeholder: item.name
                                                        },
                                                        model: {
                                                          value: item.value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.value"
                                                        }
                                                      })
                                                    : item.type ===
                                                      "datetimerange"
                                                    ? _c("el-date-picker", {
                                                        attrs: {
                                                          format:
                                                            "yyyy/MM/dd HH:mm:ss",
                                                          type: "datetimerange",
                                                          "value-format":
                                                            "timestamp",
                                                          "start-placeholder":
                                                            "开始日期",
                                                          "end-placeholder":
                                                            "结束日期",
                                                          "default-time": [
                                                            "00:00:00",
                                                            "23:59:59"
                                                          ]
                                                        },
                                                        model: {
                                                          value: item.value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "value",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.value"
                                                        }
                                                      })
                                                    : item.type === "select"
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            placeholder:
                                                              item.name
                                                          },
                                                          model: {
                                                            value: item.value,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.value"
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.selValue,
                                                            function(o) {
                                                              return [
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    key: o.text,
                                                                    attrs: {
                                                                      label:
                                                                        o.text,
                                                                      value:
                                                                        o.value
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : item.type ===
                                                      "select_filterable"
                                                    ? _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            filterable: "",
                                                            placeholder:
                                                              item.name
                                                          },
                                                          model: {
                                                            value: item.value,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.value"
                                                          }
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.selValue,
                                                            function(o) {
                                                              return [
                                                                _c(
                                                                  "el-option",
                                                                  {
                                                                    key: o.text,
                                                                    attrs: {
                                                                      label:
                                                                        o.text,
                                                                      value:
                                                                        o.value
                                                                    }
                                                                  }
                                                                )
                                                              ]
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : item.type === "number" ||
                                                      item.type === "amount"
                                                    ? _c("el-input", {
                                                        attrs: {
                                                          clearable: "",
                                                          placeholder: item.name
                                                        },
                                                        model: {
                                                          value: item.value,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "value",
                                                              _vm._n($$v)
                                                            )
                                                          },
                                                          expression:
                                                            "item.value"
                                                        }
                                                      })
                                                    : item.type === "custom"
                                                    ? _c(item.component, {
                                                        tag: "component",
                                                        attrs: {
                                                          value: item.value,
                                                          conf: item,
                                                          placeholder: item.name
                                                        },
                                                        on: {
                                                          "on-change":
                                                            _vm.editorChange
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ]
                                      ],
                                      2
                                    )
                                  ]
                                )
                              }),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _vm.conf.query.length >= 3
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "medium"
                                          },
                                          on: { click: _vm.onFold }
                                        },
                                        [
                                          _vm._v("更多 "),
                                          !_vm.isFold
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down"
                                              })
                                            : _vm._e(),
                                          _vm.isFold
                                            ? _c("i", {
                                                staticClass: "el-icon-arrow-up"
                                              })
                                            : _vm._e()
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 7 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c("div", { staticClass: "operation" })
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.conf.query.length > 2
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isFold,
                      expression: "isFold"
                    }
                  ],
                  staticClass: "screen-mn"
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _vm._l(_vm.conf.query, function(item, index) {
                        return [
                          index > 1
                            ? _c(
                                "el-col",
                                { key: "query" + index, attrs: { span: 8 } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "grid-content" },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: item.name } },
                                        [
                                          item.type === "text"
                                            ? _c("el-input", {
                                                attrs: {
                                                  type: "text",
                                                  placeholder: item.name,
                                                  clearable: ""
                                                },
                                                model: {
                                                  value: item.value,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "value",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "item.value"
                                                }
                                              })
                                            : item.type === "date"
                                            ? _c("el-date-picker", {
                                                attrs: {
                                                  format: "yyyy/MM/dd",
                                                  type: "date",
                                                  placeholder: item.name
                                                },
                                                model: {
                                                  value: item.value,
                                                  callback: function($$v) {
                                                    _vm.$set(item, "value", $$v)
                                                  },
                                                  expression: "item.value"
                                                }
                                              })
                                            : item.type === "datetimerange"
                                            ? _c("el-date-picker", {
                                                attrs: {
                                                  format: "yyyy/MM/dd HH:mm:ss",
                                                  type: "datetimerange",
                                                  "value-format": "timestamp",
                                                  "start-placeholder":
                                                    "开始日期",
                                                  "end-placeholder": "结束日期",
                                                  "default-time": [
                                                    "00:00:00",
                                                    "23:59:59"
                                                  ]
                                                },
                                                model: {
                                                  value: item.value,
                                                  callback: function($$v) {
                                                    _vm.$set(item, "value", $$v)
                                                  },
                                                  expression: "item.value"
                                                }
                                              })
                                            : item.type === "select"
                                            ? _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: item.name
                                                  },
                                                  model: {
                                                    value: item.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.value"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    item.selValue,
                                                    function(o) {
                                                      return [
                                                        _c("el-option", {
                                                          key: o.text,
                                                          attrs: {
                                                            label: o.text,
                                                            value: o.value
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : item.type === "select_filterable"
                                            ? _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: item.name
                                                  },
                                                  model: {
                                                    value: item.value,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.value"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    item.selValue,
                                                    function(o) {
                                                      return [
                                                        _c("el-option", {
                                                          key: o.text,
                                                          attrs: {
                                                            label: o.text,
                                                            value: o.value
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : item.type === "number" ||
                                              item.type === "amount"
                                            ? _c("el-input", {
                                                attrs: {
                                                  clearable: "",
                                                  placeholder: item.name
                                                },
                                                model: {
                                                  value: item.value,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "value",
                                                      _vm._n($$v)
                                                    )
                                                  },
                                                  expression: "item.value"
                                                }
                                              })
                                            : item.type === "custom"
                                            ? _c(item.component, {
                                                tag: "component",
                                                attrs: {
                                                  value: item.value,
                                                  conf: item,
                                                  placeholder: item.name
                                                },
                                                on: {
                                                  "on-change": _vm.editorChange
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "screen-btn" },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _vm._l(_vm.conf.globalOperation, function(
                            item,
                            index
                          ) {
                            return [
                              _c(
                                "el-button",
                                {
                                  key: "btn" + index,
                                  attrs: {
                                    type: item.type ? item.type : "",
                                    icon: item.icon ? item.icon : ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return item.doFunc(_vm.conf)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(item.labelName))]
                              )
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'GradeLeaderUtil',
  data: function data() {
    return {
      grades: [],
      staffs: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      var _this = this;

      this.options = [];
      this.$api.apis.teachingArrangeService.findGradeAndTeacher({
        path: {
          id: this.$route.params.id
        }
      }).then(function (data) {
        if (data) {
          _this.grades = data.grades;
          _this.staffs = data.staffs;

          for (var i = 0; i < _this.grades.length; i++) {
            var st = [];

            if (_this.grades[i].gradeLeader) {
              for (var j = 0; j < _this.grades[i].gradeLeader.length; j++) {
                var teacher = _this.grades[i].gradeLeader[j];
                st.push(teacher.id);
              }
            }

            _this.grades[i]["gradeLeaders"] = st;
          }

          _this.grades.sort(_this.compare("num"));
        }
      });
    },
    //排序
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    handleChange: function handleChange() {
      for (var i = 0; i < this.grades.length; i++) {
        this.$set(this.grades, i, this.grades[i]);
      } // let _v = {
      //   grades: this.grades
      // }


      this.$emit('on-change', this.grades, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
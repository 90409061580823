var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-num" },
    [
      _c(
        "el-form",
        { staticClass: "demo-form-inline", attrs: { inline: true } },
        [
          _vm._l(_vm.bottomOtherDatas, function(item, index) {
            return [
              _c("el-form-item", { key: index, attrs: { label: item.name } }, [
                _c("span", [_vm._v(_vm._s(item.value))])
              ])
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
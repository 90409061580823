//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'CampusUtil',
  data: function data() {
    return {
      options: [],
      value: "",
      positionTypeForm: {
        positionType: ""
      },
      positionTypeRules: {
        positionType: [{
          required: true,
          message: '请选择位置类型',
          trigger: 'change'
        }]
      }
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.init();
  },
  computed: {},
  components: {},
  methods: {
    onChange: function onChange() {
      if (this.value === "offCampusBase") {
        this.positionTypeForm.positionType = "";
      }

      this.editorChange();
    },
    init: function init() {
      var _this = this;

      //获取所有分支校区
      this.$api.apis.branchMerchantService.all({}).then(function (data) {
        if (data) {
          _this.options = [];

          for (var i = 0; i < data.length; i++) {
            _this.options.push({
              text: data[i].abbreviation,
              value: data[i]._id,
              type: 0
            });
          }
        }
      });

      if (this.dataSource["campus"] && this.dataSource["positionType"] != undefined) {
        this.value = this.dataSource["campus"].key;
        this.positionTypeForm.positionType = String(this.dataSource["positionType"]);
      }

      this.editorChange();
    },
    editorChange: function editorChange() {
      var campusType = 0;
      var abbreviation = "";

      if (this.value === "offCampusBase") {
        campusType = 1;
        abbreviation = "校外基地";
      }

      var _d = {
        key: this.value,
        campusType: campusType,
        positionType: this.positionTypeForm.positionType,
        abbreviation: abbreviation
      };
      this.$emit('on-change', _d, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        //this.val = this.dataSource[this.conf.valName]
        this.init();
      },
      // 深度观察
      deep: true
    }
  }
};
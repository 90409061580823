var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "query2",
            { ref: "query", attrs: { config: _vm.queryConfig } },
            [
              _c("h3", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.title))]),
              _c("AuthorityExplain")
            ],
            1
          )
        ],
        1
      ),
      _c("list2", { ref: "list", attrs: { config: _vm.listConfig } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.enrolmentName,
            visible: _vm.enrolmentAppletUrlVisible,
            width: "30%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.enrolmentAppletUrlVisible = $event
            }
          }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 24 } }, [
                _c("span", [
                  _vm._v("小程序链接：" + _vm._s(_vm.enrolmentAppletUrl))
                ])
              ])
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("关闭")
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
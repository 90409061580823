import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'wxHomeConfigService',
  methods: [{
    name: 'findModule',
    url: '/wxHomeConfig/method/findModule',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'addModule',
    url: '/wxHomeConfig/method/addModule',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'addModuleData',
    url: '/wxHomeConfig/method/addModuleData',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'findModuleData',
    url: '/wxHomeConfig/method/findModuleData',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'delModule',
    url: '/wxHomeConfig/method/delModule',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入学生数据",
            visible: _vm.dialogVisible,
            width: "30%",
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "gradeForm",
              attrs: {
                model: _vm.gradeForm,
                "label-width": "50px",
                "label-position": "left"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "年级", prop: "region" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 4 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "small",
                                placeholder: "请选择校区"
                              },
                              model: {
                                value: _vm.gradeForm.branchMerchantId,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.gradeForm,
                                    "branchMerchantId",
                                    $$v
                                  )
                                },
                                expression: "gradeForm.branchMerchantId"
                              }
                            },
                            _vm._l(_vm.branchMerchants, function(o, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: o.registeredName, value: o._id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 11 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                size: "small",
                                placeholder: "请选择年级"
                              },
                              model: {
                                value: _vm.gradeForm.gradeNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.gradeForm, "gradeNum", $$v)
                                },
                                expression: "gradeForm.gradeNum"
                              }
                            },
                            _vm._l(_vm.grades, function(o, i) {
                              return _c("el-option", {
                                key: i,
                                attrs: { label: o.name, value: o.num }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.gradeForm.gradeNum != "" && _vm.gradeForm.branchMerchantId != ""
            ? _c(
                "div",
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: "string",
                        "auto-upload": false,
                        "http-request": _vm.upload,
                        "on-change": _vm.consoleFL,
                        "file-list": _vm.uploadList,
                        limit: _vm.limit,
                        accept: _vm.accept,
                        "on-exceed": _vm.handleExceed
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v(" 只能上传小于2000行的Excel文件 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c("div", [
            (_vm.errMessages && _vm.errMessages.length > 0) ||
            _vm.repeatCount > 0
              ? _c("ul", [
                  _c("li", [
                    _vm._v("导入成功： " + _vm._s(_vm.successCount) + "条")
                  ]),
                  _c("li", { staticStyle: { color: "#f00" } }, [
                    _vm._v("导入失败： " + _vm._s(_vm.failCount) + "条")
                  ]),
                  _c("li", { staticStyle: { color: "#e6a23c" } }, [
                    _vm._v("重复数据： " + _vm._s(_vm.repeatCount) + "条")
                  ])
                ])
              : _vm._e(),
            _vm.errMessages && _vm.errMessages.length > 0
              ? _c(
                  "ul",
                  [
                    _c("li", [_vm._v("错误信息：")]),
                    _vm._l(_vm.errMessages, function(message, index) {
                      return [
                        message != ""
                          ? _c(
                              "li",
                              {
                                key: index + "_o",
                                staticStyle: { color: "#f00" }
                              },
                              [_vm._v(" " + _vm._s(message) + " ")]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                )
              : _vm._e()
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.multiple
        ? _c(
            "el-upload",
            {
              attrs: {
                action: "string",
                "list-type": "picture",
                "on-preview": _vm.handlePreview,
                "auto-upload": false,
                "on-remove": _vm.handleRemove,
                "http-request": _vm.upload,
                "on-change": _vm.consoleFL,
                "file-list": _vm.uploadList,
                disabled: _vm.disabled,
                limit: _vm.limit,
                accept: _vm.accept,
                "on-exceed": _vm.handleExceed
              }
            },
            [
              !_vm.disabled
                ? _c(
                    "el-button",
                    { attrs: { size: "small", type: "primary" } },
                    [_vm._v("点击上传")]
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip"
                    },
                    [
                      _vm._v(
                        " 最多上传" +
                          _vm._s(_vm.limit) +
                          "张，只能上传jpg/png文件，且不超过500kb "
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: "'string'",
                "auto-upload": false,
                "show-file-list": false,
                "on-change": _vm.handleCrop,
                "http-request": _vm.upload,
                accept: _vm.accept,
                disabled: _vm.disabled
              }
            },
            [
              _vm.imageUrl
                ? _c("img", {
                    ref: "singleImg",
                    staticClass: "avatar",
                    style: {
                      width: _vm.width + "px",
                      height: _vm.height + "px"
                    },
                    attrs: { src: _vm.imageUrl },
                    on: {
                      mouseenter: _vm.mouseEnter,
                      mouseleave: _vm.mouseLeave
                    }
                  })
                : _c("i", {
                    staticClass: "el-icon-plus avatar-uploader-icon",
                    style: {
                      width: _vm.width + "px",
                      height: _vm.height + "px",
                      "line-height": _vm.height + "px",
                      "font-size": _vm.height / 6 + "px"
                    }
                  }),
              _vm.imageUrl
                ? _c(
                    "div",
                    {
                      ref: "reupload",
                      style: { width: "100%" },
                      attrs: { id: "uploadIcon" },
                      on: {
                        mouseenter: _vm.mouseEnter,
                        mouseleave: _vm.mouseLeave
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-zoom-in",
                        style: {
                          color: "#2E2E2E",
                          fontSize: "25px",
                          display: "inline-block",
                          paddingRight: "15px"
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.handlePreviewSingle($event)
                          }
                        }
                      }),
                      _c("i", {
                        staticClass: "el-icon-upload",
                        style: {
                          color: "#2E2E2E",
                          fontSize: "25px",
                          display: "inline-block"
                        }
                      })
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "uploading",
                  staticClass: "reupload",
                  style: {
                    width: _vm.reuploadWidth + "px",
                    height: _vm.reuploadWidth + "px",
                    "line-height": _vm.reuploadWidth + "px",
                    "font-size": _vm.reuploadWidth / 5 + "px"
                  }
                },
                [_vm._v(" 上传中.. ")]
              ),
              _c(
                "div",
                {
                  ref: "failUpload",
                  staticClass: "reupload",
                  style: {
                    width: _vm.reuploadWidth + "px",
                    height: _vm.reuploadWidth + "px",
                    "line-height": _vm.reuploadWidth + "px",
                    "font-size": _vm.reuploadWidth / 5 + "px"
                  }
                },
                [_vm._v(" 上传失败 ")]
              )
            ]
          ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" }
          })
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.cropperModel,
            width: "800px",
            "before-close": _vm.beforeClose
          },
          on: {
            "update:visible": function($event) {
              _vm.cropperModel = $event
            }
          }
        },
        [
          _c("Cropper", {
            ref: "vueCropper",
            attrs: {
              "img-file": _vm.file,
              fixedNumber: _vm.fixedNumber,
              fixed: _vm.conf.fixed,
              fixedBox: _vm.conf.fixedBox,
              canMove: _vm.conf.canMove,
              canMoveBox: _vm.conf.canMoveBox
            },
            on: { upload: _vm.upload }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/*
ui 封装插件
 */
import List from './List';
import List2 from './List2';
import List3 from './List3';
import List4 from './List4'; //多表头

import Model from './Model';
import Model2 from './Model2';
import Query from './Query';
import Query2 from './Query2';
import AuthorityExplain from './AuthorityExplain';

function install(Vue) {
  Vue.component('list', List);
  Vue.component('list2', List2);
  Vue.component('list3', List3);
  Vue.component('list4', List4);
  Vue.component('modelForm', Model);
  Vue.component('modelForm2', Model2);
  Vue.component('query', Query);
  Vue.component('query2', Query2);
  Vue.component('AuthorityExplain', AuthorityExplain);
  console.log('安装ui插件成功 by aassaadd@qq.com');
}

export default install;
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import utils from '@/libs/util';
import paymentRecords from '../orderUtil/PaymentRecordsUtil';
export default {
  mixins: [utils.mixin.modelMixin, utils.mixin.modelEditMixin],
  name: 'enrolmentStatisticsInfo',
  data: function data() {
    return {
      studentName: "",
      studentClass: "",
      masterOrderId: ""
    };
  },
  components: {
    paymentRecords: paymentRecords
  },
  methods: {
    initData: function initData() {
      var _this = this;

      this.masterOrderId = this.$route.params.id;
      this.options = [];
      this.$api.apis.masterOrderService.getById({
        path: {
          id: this.$route.params.id
        }
      }).then(function (data) {
        if (data) {
          if (data.relatedStudent) {
            _this.studentName = data.relatedStudent.name + "(" + data.relatedUser.account + ")";
          }
        }
      });
    },
    onReturn: function onReturn() {
      this.$store.dispatch('d2admin/page/close', {
        tagName: this.$route.path
      });
    }
  },
  mounted: function mounted() {
    this.$nextTick(function () {
      this.initData();
    });
  }
};
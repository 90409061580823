import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'cardBalanceService',
  methods: [{
    name: 'list',
    url: '/cardBalance/method/list/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'staffList',
    url: '/cardBalance/method/staffList/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/cardBalance/method/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
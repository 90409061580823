//
//
//
//
//
//
//
//
// import { use } from "echarts/core";
// import { CanvasRenderer } from "echarts/renderers";
// import { PieChart } from "echarts/charts";
// import {
//   TitleComponent,
//   TooltipComponent,
//   LegendComponent
// } from "echarts/components";
// import VChart, { THEME_KEY } from "vue-echarts";
// use([
//   CanvasRenderer,
//   PieChart,
//   TitleComponent,
//   TooltipComponent,
//   LegendComponent
// ]);
import D2Badge from './components/d2-badge';
import D2Help from './components/d2-help';
import D2PageCover from './components/d2-page-cover';
export default {
  // provide: {
  //   [THEME_KEY]: "dark"
  // },
  data: function data() {
    return {// option: {
      //   title: {
      //     text: "Traffic Sources",
      //     left: "center"
      //   },
      //   tooltip: {
      //     trigger: "item",
      //     formatter: "{a} <br/>{b} : {c} ({d}%)"
      //   },
      //   legend: {
      //     orient: "vertical",
      //     left: "left",
      //     data: [
      //       "Direct",
      //       "Email",
      //       "Ad Networks",
      //       "Video Ads",
      //       "Search Engines"
      //     ]
      //   },
      //   series: [
      //     {
      //       name: "Traffic Sources",
      //       type: "pie",
      //       radius: "55%",
      //       center: ["50%", "60%"],
      //       data: [
      //         { value: 335, name: "Direct" },
      //         { value: 310, name: "Email" },
      //         { value: 234, name: "Ad Networks" },
      //         { value: 135, name: "Video Ads" },
      //         { value: 1548, name: "Search Engines" }
      //       ],
      //       emphasis: {
      //         itemStyle: {
      //           shadowBlur: 10,
      //           shadowOffsetX: 0,
      //           shadowColor: "rgba(0, 0, 0, 0.5)"
      //         }
      //       }
      //     }
      //   ]
      // }
    };
  },
  components: {
    D2Badge: D2Badge,
    D2Help: D2Help,
    D2PageCover: D2PageCover //VChart

  },
  mounted: function mounted() {
    this.$nextTick(function () {//console.log(this.$refs['chart'])
      //  this.$refs['chart'].echarts.resize()
    });
  }
};
import "core-js/modules/es.math.trunc";
import "core-js/modules/es.number.to-fixed";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import utils from '@/libs/util';
export default {
  inject: ['close'],
  name: 'PaymentRecordsUtil',
  data: function data() {
    var _this = this;

    var validateAmount = function validateAmount(rule, value, callback) {
      if (!value || value < 0) {
        callback(new Error('请输入退费金额'));
      } else if (value) {
        if (Math.trunc(parseFloat(value) * 100) > _this.payItem.amount - _this.alreadyRefundAmount) {
          callback(new Error('退费金额不得大于交费金额'));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };

    return {
      records: [],
      refundRecords: [],
      amount: 0,
      //实缴
      originalAmount: 0,
      //总缴费
      payItem: {},
      item: {},
      maxRefundAmount: 0,
      oneRefundDialogVisible: false,
      refundForm: {
        amount: "",
        way: "xianshang"
      },
      rules: {
        amount: [{
          required: true,
          validator: validateAmount,
          trigger: 'blur'
        }],
        way: [{
          required: true,
          message: '请选择活动区域',
          trigger: 'change'
        }]
      },
      supplementRefundDialogVisible: false,
      alreadyRefundAmount: 0,
      rejectedDialogVisible: false,
      rejectedForm: {
        reviewTime: 0,
        about: ""
      },
      passedDialogVisible: false,
      transferDialogVisible: false,
      transferOrderId: "",
      transferAuditingForm: {
        check: "0"
      }
    };
  },
  props: {
    masterOrderId: String
  },
  created: function created() {
    this.getSubOrderAll(); //this.getRefundOrderAll()
  },
  computed: {},
  components: {},
  methods: {
    getSubOrderAll: function getSubOrderAll() {
      var _this2 = this;

      if (this.masterOrderId) {
        this.$api.apis.masterOrderService.subOrder({
          path: {
            id: this.masterOrderId
          }
        }).then(function (data) {
          _this2.records = data;

          if (data) {
            for (var i = 0; i < data.length; i++) {
              if (data[i].relatedUser && data[i].relatedUser.relatedStudent) {
                var _relatedStudent = data[i].relatedUser.relatedStudent;

                for (var j = 0; j < _relatedStudent.length; j++) {
                  if (_relatedStudent[j].extId == data[i].relatedStudent.extId) {
                    data[i].relatedUser["parentalIdentity"] = _relatedStudent[j].parentalIdentity;
                  }
                }
              }

              _this2.amount = _this2.amount + data[i].amount;
              _this2.originalAmount = _this2.originalAmount + data[i].originalAmount;
            }
          }
        });
      }
    },
    getRefundOrderAll: function getRefundOrderAll() {
      var _this3 = this;

      if (this.masterOrderId) {
        this.$api.apis.masterOrderService.refundOrderAll({
          path: {
            id: this.masterOrderId
          }
        }).then(function (data) {
          _this3.refundRecords = data;
        });
      }
    },
    //打开转账审核弹框
    onTransferAuditingDialog: function onTransferAuditingDialog(item) {
      this.transferOrderId = item._id;
      this.transferDialogVisible = true;
    },
    //
    onTransferAuditingClose: function onTransferAuditingClose() {
      this.transferDialogVisible = false;
      this.transferOrderId = "";
      this.getSubOrderAll();
      this.getRefundOrderAll();
      this.close();
    },
    onTransferAuditingSubmit: function onTransferAuditingSubmit(formName) {
      var _this4 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          //alert('submit!');
          var state = parseInt(_this4.transferAuditingForm.check);

          _this4.$api.apis.masterOrderService.subOrderAuditing({
            body: {
              orderId: _this4.transferOrderId,
              state: state
            },
            isAes: true,
            aesKey: utils.cookies.get('salt')
          }).then(function (data) {
            _this4.$message({
              type: 'success',
              message: '操作成功!'
            });

            _this4.onTransferAuditingClose();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //查看订单号
    onOrderNo: function onOrderNo(no) {
      this.$alert(no, '订单编号', {
        confirmButtonText: '确定',
        callback: function callback(action) {// this.$message({
          //   type: 'info',
          //   message: `action: ${ action }`
          // });
        }
      });
    },
    oneRefundHandleClose: function oneRefundHandleClose() {
      this.alreadyRefundAmount = 0;

      if (!this.rejectedDialogVisible && !this.passedDialogVisible) {
        this.$refs["refundForm"].resetFields();
      }

      this.oneRefundDialogVisible = false;
      this.supplementRefundDialogVisible = false;
      this.rejectedDialogVisible = false;
      this.passedDialogVisible = false;
      this.refundForm = {
        amount: "",
        way: "xianshang"
      };
      this.rejectedForm = {
        reviewTime: 0,
        about: ""
      }, this.getSubOrderAll();
      this.getRefundOrderAll();
    },
    oneRefundSubmit: function oneRefundSubmit(formName) {
      var _this5 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          //alert('submit!');
          var refundAmount = Math.trunc(parseFloat(_this5.refundForm.amount) * 100);

          _this5.$api.apis.masterOrderService.refundRequest({
            body: {
              masterOrderId: _this5.item.masterOrderId,
              orderId: _this5.item._id,
              ikey: _this5.payItem.ikey,
              amount: refundAmount,
              way: _this5.refundForm.way
            },
            isAes: true,
            aesKey: utils.cookies.get('salt')
          }).then(function (data) {
            _this5.$message({
              type: 'success',
              message: '操作成功!'
            });

            _this5.oneRefundHandleClose();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //退款
    onRefund: function onRefund(item, it) {
      if (it.pageViewParameters && it.pageViewParameters.isRefund === 2) {
        //补充退款
        this.supplementRefundDialogVisible = true;
        this.payItem = it;
        this.item = item;
        this.alreadyRefundAmount = it.pageViewParameters.refundAmount;
      } else {
        this.payItem = it;
        this.item = item;
        this.oneRefundDialogVisible = true;
        this.alreadyRefundAmount = 0;
      }
    },
    //待审核
    onWaitAudit: function onWaitAudit(item) {
      var _this6 = this;

      this.$confirm('确定撤销退款?', '等待汇总审核', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        //撤销退款
        _this6.$api.apis.masterOrderService.revokeRefundRequest({
          body: {
            orderId: item._id
          },
          isAes: true,
          aesKey: utils.cookies.get('salt')
        }).then(function (data) {
          _this6.getRefundOrderAll();
        }); // this.$message({
        //   type: 'success',
        //   message: '删除成功!'
        // });

      }).catch(function () {// this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    //已驳回
    onRejected: function onRejected(item) {
      this.rejectedForm.reviewTime = item.refundApply.reviewTime;
      this.rejectedForm.about = item.about;
      this.rejectedDialogVisible = true;
    },
    //已完成
    onPassed: function onPassed(item) {
      this.item = item;
      this.passedDialogVisible = true;
    }
  },
  watch: {
    masterOrderId: {
      handler: function handler() {
        this.getSubOrderAll(); //this.getRefundOrderAll()
      },
      // 深度观察
      deep: true
    }
  },
  filters: {
    parentalIdentityFilter: function parentalIdentityFilter(val) {
      if (val === 0) {
        return "其他";
      } else if (val === 1) {
        return "父亲";
      } else if (val === 2) {
        return "母亲";
      } else if (val === 3) {
        return "爷爷";
      } else if (val === 4) {
        return "奶奶";
      } else if (val === 5) {
        return "外公";
      } else if (val === 6) {
        return "外婆";
      }
    },
    timeFilter: function timeFilter(val) {
      if (val > 0) {
        return moment.parseZone(val).utcOffset('+08:00').format('YYYY-MM-DD HH:mm:ss');
      } else {
        return "";
      }
    },
    amountFilter: function amountFilter(val) {
      if (val > 0) {
        return parseFloat(val / 100).toFixed(2) + "元";
      } else {
        return "0元";
      }
    },
    payMethodFilter: function payMethodFilter(val) {// if (val === 'CHANNEL_TYPE_CASH'){
      //   return '线下收款'
      // }else{
      //   return '线上交费'
      // }
      // if (val === 'CHANNEL_TYPE_CASH'){
      //   return '线下收款'
      // }else if (val === 'CHANNEL_TYPE_CASH'){
      //   return '线下收款'
      // }else{
      //   return '线上交费'
      // }
    },
    payStatusFilter: function payStatusFilter(val) {
      if (val > -1 && val <= 200) {
        return "待交费";
      } else if (val == 201) {
        return "已支付待审核";
      } else if (val == 300) {
        return "已支付";
      } else if (val == 301) {
        return "部分已支付";
      } else if (val == 501) {
        return "已退款";
      } else if (val == 500) {
        return "待退款";
      } else {
        return "";
      }
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'areaService',
  methods: [{
    name: 'all',
    url: '/area/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'listCount',
    url: '/area/method/listCount',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'homeStatisticalService',
  methods: [{
    name: 'blockStatistical',
    url: '/homeStatistical/method/blockStatistical',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'courseListStatistical',
    url: '/homeStatistical/method/courseListStatistical',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'signingTrendStatistical',
    url: '/homeStatistical/method/signingTrendStatistical',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'profitAnalysisStatistical',
    url: '/homeStatistical/method/profitAnalysisStatistical',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c("template", { slot: "header" }, [
        _c("h3", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.title))])
      ]),
      _c(
        "el-row",
        { staticStyle: { "margin-bottom": "20px" } },
        [
          _c("el-col", { attrs: { span: 24 } }, [
            _vm._v(_vm._s(_vm.studentName) + _vm._s(_vm.studentClass))
          ])
        ],
        1
      ),
      _c("paymentRecords", { attrs: { masterOrderId: _vm.masterOrderId } }),
      _c(
        "div",
        { staticClass: "mainsrp-operation" },
        [
          _c(
            "el-button",
            {
              staticClass: "dy-button-small",
              attrs: { type: "button", round: "" },
              on: { click: _vm.onReturn }
            },
            [_vm._v("返回")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'scheduleDetailService',
  methods: [{
    name: 'save',
    url: '/scheduleDetail/method/save',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/scheduleDetail/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'all',
    url: '/scheduleDetail/method/all',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
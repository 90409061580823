var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "自定义字段编辑",
        visible: _vm.dialogVisible,
        width: "40%",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c("div", { staticClass: "mainsrp-form" }, [
        _c(
          "div",
          { staticClass: "m-form" },
          [
            _c(
              "el-form",
              {
                ref: "customFieldForm",
                attrs: {
                  "label-position": "top",
                  model: _vm.formData,
                  rules: _vm.customFieldRules,
                  "label-width": "100px",
                  size: "medium"
                },
                nativeOn: {
                  submit: function($event) {
                    $event.preventDefault()
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-mn" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "字段名称",
                          prop: "name",
                          size: "small"
                        }
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入字段名称" },
                          model: {
                            value: _vm.formData.name,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData,
                                "name",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "formData.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "字段类型",
                          prop: "type",
                          size: "small"
                        }
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请选择字段类型" },
                            on: { change: _vm.onTypeChange },
                            model: {
                              value: _vm.formData.type,
                              callback: function($$v) {
                                _vm.$set(_vm.formData, "type", $$v)
                              },
                              expression: "formData.type"
                            }
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "单行输入框", value: "INPUT" }
                            }),
                            _c("el-option", {
                              attrs: { label: "多行输入框", value: "TEXTAREA" }
                            }),
                            _c("el-option", {
                              attrs: { label: "单选下拉框", value: "SELECT" }
                            }),
                            _c("el-option", {
                              attrs: { label: "单选", value: "RADIO" }
                            }),
                            _c("el-option", {
                              attrs: { label: "多选", value: "CHECKBOX" }
                            }),
                            _c("el-option", {
                              attrs: { label: "日期选择框", value: "DATE" }
                            }),
                            _c("el-option", {
                              attrs: { label: "日期区间框", value: "DATERANGE" }
                            }),
                            _c("el-option", {
                              attrs: {
                                label: "日期时间选择框",
                                value: "DATETIMESINGLE"
                              }
                            }),
                            _c("el-option", {
                              attrs: {
                                label: "日期时间区间框",
                                value: "DATETIMERANGE"
                              }
                            }),
                            _c("el-option", {
                              attrs: { label: "图片上传框", value: "PICTURE" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.formData.type === "INPUT" ||
                    _vm.formData.type === "TEXTAREA"
                      ? [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "字数限制(留空为不限)",
                                size: "small"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入最长字数（留空为不限）"
                                },
                                model: {
                                  value: _vm.formData.parameter.inputMax,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.parameter,
                                      "inputMax",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.parameter.inputMax"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.formData.type === "NUMBER"
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "数字单位", size: "small" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入数字单位（如：个）"
                                },
                                model: {
                                  value: _vm.formData.parameter.numberUnit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.formData.parameter,
                                      "numberUnit",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "formData.parameter.numberUnit"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.formData.type === "AMOUNT"
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "支持小数", size: "small" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value:
                                      _vm.formData.parameter.supportDecimal,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formData.parameter,
                                        "supportDecimal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formData.parameter.supportDecimal"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "1" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "0" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.formData.type === "SELECT" ||
                    _vm.formData.type === "MULTIPLE_SELECT" ||
                    _vm.formData.type === "RADIO" ||
                    _vm.formData.type === "CHECKBOX"
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "设置选项", size: "small" } },
                            [
                              _vm._l(_vm.formData.selVal, function(
                                item,
                                index
                              ) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: index,
                                      staticStyle: { "margin-bottom": "10px" }
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 16 } },
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  placeholder: "请输入选项名称"
                                                },
                                                model: {
                                                  value: item.name,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "name",
                                                      typeof $$v === "string"
                                                        ? $$v.trim()
                                                        : $$v
                                                    )
                                                  },
                                                  expression: "item.name"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          index < _vm.formData.selVal.length - 1
                                            ? _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px"
                                                  },
                                                  attrs: { span: 2 }
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.moveDown(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("下移")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          index > 0
                                            ? _c(
                                                "el-col",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px"
                                                  },
                                                  attrs: { span: 2 }
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.moveUp(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("上移")]
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px"
                                              },
                                              attrs: { span: 2 }
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: { color: "red" },
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.delSelVal(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("移除")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.addSelVal }
                                },
                                [_vm._v("添加选项")]
                              )
                            ],
                            2
                          )
                        ]
                      : _vm._e(),
                    _vm.formData.type === "ATTACHMENT" ||
                    _vm.formData.type === "PICTURE"
                      ? [
                          _c(
                            "el-form-item",
                            { attrs: { label: "个数限制", size: "small" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.formData.valMaxCount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.formData, "valMaxCount", $$v)
                                    },
                                    expression: "formData.valMaxCount"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "1", value: "1" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "2", value: "2" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "3", value: "3" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "4", value: "4" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "5", value: "5" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "6", value: "6" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "7", value: "7" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "8", value: "8" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    _vm.formData.valType === "ARRAY" ||
                    _vm.formData.valType === "ARRAY_INT"
                      ? _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "最多选项(0不限制)",
                              prop: "valMaxCount",
                              size: "small"
                            }
                          },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                "controls-position": "right",
                                min: 0,
                                step: 1
                              },
                              model: {
                                value: _vm.formData.valMaxCount,
                                callback: function($$v) {
                                  _vm.$set(_vm.formData, "valMaxCount", $$v)
                                },
                                expression: "formData.valMaxCount"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ]
            )
          ],
          1
        )
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'roleService',
  methods: [{
    name: 'list',
    url: '/role/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/role/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'add',
    url: '/role/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/role/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/role/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'all',
    url: '/role/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findAllPerTempIndexResource',
    url: '/role/method/findAllPerTempIndexResource',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findAllPerTempIndexResourceByNotUserRole',
    url: '/role/method/findAllPerTempIndexResourceByNotUserRole/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findTeacherAppAllPerTempIndexResource',
    url: '/role/method/findTeacherAppAllPerTempIndexResource',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findTeacherAppAllPerByNotUserRole',
    url: '/role/method/findTeacherAppAllPerByNotUserRole/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
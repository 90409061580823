import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import CryptoJS from 'crypto-js'; // 加密

export function encrypt(word, keyStr) {
  // export function encrypt (word, keyStr) {
  keyStr = keyStr || 'abcdefgabcdefg12';
  var key = CryptoJS.enc.Utf8.parse(keyStr); // Latin1 w8m31+Yy/Nw6thPsMpO5fg==

  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }); // var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  // return encrypted.toString()
  // return encrypted.ciphertext.toString()

  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
} // 解密

export function decrypt(word, keyStr) {
  // export function decrypt (word) {
  keyStr = keyStr || 'abcdefgabcdefg12';
  var parsedWordArray = CryptoJS.enc.Base64.parse(word);
  var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
  var key = CryptoJS.enc.Utf8.parse(keyStr); // Latin1 w8m31+Yy/Nw6thPsMpO5fg==

  var decrypt = CryptoJS.AES.decrypt(parsedStr, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}
export default {
  encrypt: encrypt,
  decrypt: decrypt
};
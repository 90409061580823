export default {
  namespaced: true,
  state: {
    havLoading: true
  },
  mutations: {
    SET_HAV_LOADING: function SET_HAV_LOADING(state, havLoading) {
      state.havLoading = havLoading;
    }
  },
  actions: {
    setHavLoading: function setHavLoading(_ref, havLoading) {
      var commit = _ref.commit;
      commit('SET_HAV_LOADING', havLoading);
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'merchantClassService',
  methods: [{
    name: 'list',
    url: '/merchantClass/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/merchantClass/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'add',
    url: '/merchantClass/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'batchAdd',
    url: '/merchantClass/method/batchAdd',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/merchantClass/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/merchantClass/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'batchDelete',
    url: '/merchantClass/method/batchDelete',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'all',
    url: '/merchantClass/method/all',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
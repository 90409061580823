import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'merchantService',
  methods: [{
    name: 'upById',
    url: '/staff/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upIntroduce',
    url: '/merchant/method/upIntroduce/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'findIntroduce',
    url: '/merchant/method/findIntroduce',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findSystemParameter',
    url: '/merchant/method/findSystemParameter',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upSystemParameter',
    url: '/merchant/method/upSystemParameter',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
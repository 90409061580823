import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'showerRuleService',
  methods: [{
    name: 'info',
    url: '/showerRule/method/info/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'up',
    url: '/showerRule/method/up',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'adminList',
    url: '/showerRule/method/adminList/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'adminBinding',
    url: '/showerRule/method/admin/binding',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'adminDel',
    url: '/showerRule/method/admin/del',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tree-transfer", {
    attrs: {
      title: _vm.titles,
      from_data: _vm.values,
      to_data: _vm.toData,
      defaultProps: {
        key: "id",
        label: "title_type"
      },
      mode: "transfer",
      height: "540px",
      filter: "",
      openAll: ""
    },
    on: { addBtn: _vm.add, removeBtn: _vm.remove }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.suitableApplys, function(item, index) {
        return [
          _c(
            "el-row",
            {
              key: index,
              staticStyle: {
                border: "1px solid #eee",
                "border-radius": "4px",
                "margin-bottom": "8px"
              }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#fafafa",
                    padding: "2px 16px",
                    "font-size": "14px",
                    "font-weight": "700",
                    "border-bottom": "1px solid #eee",
                    color: "#666"
                  },
                  attrs: { span: 24 }
                },
                [_vm._v(_vm._s(item.menuGroupName))]
              ),
              _c(
                "el-col",
                { staticStyle: { padding: "2px 16px" }, attrs: { span: 24 } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: { change: _vm.handleChange },
                      model: {
                        value: _vm.checkList,
                        callback: function($$v) {
                          _vm.checkList = $$v
                        },
                        expression: "checkList"
                      }
                    },
                    [
                      _vm._l(item.groups, function(gitem, gindex) {
                        return [
                          _c(
                            "el-col",
                            { key: gindex, attrs: { span: 4 } },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: gitem.groupId } },
                                [_vm._v(_vm._s(gitem.groupName))]
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
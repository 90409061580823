var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "设置字段关联",
        visible: _vm.dialogVisible,
        width: "40%",
        "close-on-click-modal": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose
      }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", staticStyle: { width: "95%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [_c("span", [_vm._v("关联项")])]
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.onFatherRadioChange },
                          model: {
                            value: _vm.fatherRadio,
                            callback: function($$v) {
                              _vm.fatherRadio = $$v
                            },
                            expression: "fatherRadio"
                          }
                        },
                        _vm._l(_vm.fatherData, function(fd, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              staticStyle: { "margin-top": "10px" },
                              attrs: { span: 24 }
                            },
                            [
                              _c("el-radio", { attrs: { label: fd.value } }, [
                                _vm._v(_vm._s(fd.name))
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-card",
                { staticClass: "box-card", staticStyle: { width: "95%" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clearfix",
                      attrs: { slot: "header" },
                      slot: "header"
                    },
                    [
                      _c("span", [_vm._v("被关联项")]),
                      _c(
                        "el-button",
                        {
                          staticStyle: { float: "right", padding: "3px 0" },
                          attrs: { type: "text" },
                          on: { click: _vm.onClear }
                        },
                        [_vm._v("清空")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          on: { change: _vm.onSonCheckboxChange },
                          model: {
                            value: _vm.sonCheck,
                            callback: function($$v) {
                              _vm.sonCheck = $$v
                            },
                            expression: "sonCheck"
                          }
                        },
                        _vm._l(_vm.sonData, function(sd) {
                          return _c(
                            "el-col",
                            { key: sd.key, attrs: { span: 24 } },
                            [
                              _c("el-checkbox", { attrs: { label: sd.key } }, [
                                _vm._v(_vm._s(sd.name))
                              ])
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [_vm._v("确 定")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.dom-collections.iterator";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import treeTransfer from 'el-tree-transfer'; // 引入

export default {
  name: 'RoleJurisdictionTreeUtil',
  data: function data() {
    return {
      val: [],
      values: [],
      titles: ['可选', '已选'],
      data: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    var _this = this;

    this.val = this.dataSource[this.conf.valName] ? this.dataSource[this.conf.valName] : [];

    if (this.$route.params.id) {
      this.$api.apis.roleService.findAllPerTempIndexResourceByNotUserRole({
        path: {
          id: this.$route.params.id
        }
      }).then(function (data) {
        // this.data = data
        //console.debug(data)
        var lm = [];
        data.forEach(function (r) {
          r.id = r.id;
          r.pid = r.groupId;
          r.title_type = r.title;

          if (r.type === 2) {
            r.title_type = r.title + '(按钮)';
          } else {
            r.title_type = r.title + '(页面)';
          }

          if (r.groupId) {
            var t = true;

            for (var i = 0; i < lm.length; i++) {
              if (r.groupId === lm[i].groupId) {
                lm[i].children.push(r);
                t = false;
                break;
              }
            }

            if (lm.length <= 0 || t) {
              var g = {
                groupName: r.groupName,
                groupId: r.groupId,
                menuGroupName: r.menuGroupName,
                menuGroupIcon: r.menuGroupIcon,
                title: r.groupName,
                title_type: r.groupName,
                id: r.groupId,
                pid: 0,
                children: [r]
              };
              lm.push(g);
            }
          } else {
            lm.push(r);
          }
        });
        _this.values = lm;
      });
    }

    this.$api.apis.roleService.findAllPerTempIndexResource({
      params: {}
    }).then(function (data) {
      _this.data = data; //console.debug(data)

      var lm = [];
      data.forEach(function (r) {
        r.id = r.id;
        r.pid = r.groupId;
        r.title_type = r.title;

        if (r.type === 2) {
          r.title_type = r.title + '(按钮)';
        } else {
          r.title_type = r.title + '(页面)';
        }

        if (r.groupId) {
          var t = true;

          for (var i = 0; i < lm.length; i++) {
            if (r.groupId === lm[i].groupId) {
              lm[i].children.push(r);
              t = false;
              break;
            }
          }

          if (lm.length <= 0 || t) {
            var g = {
              groupName: r.groupName,
              groupId: r.groupId,
              menuGroupName: r.menuGroupName,
              menuGroupIcon: r.menuGroupIcon,
              title: r.groupName,
              title_type: r.groupName,
              id: r.groupId,
              pid: 0,
              children: [r]
            };
            lm.push(g);
          }
        } else {
          lm.push(r);
        }
      });

      if (!_this.$route.params.id) {
        _this.values = lm;
      }
    });
  },
  computed: {
    toData: function toData() {
      var _this2 = this;

      var data = [];
      var d = this.dataSource[this.conf.valName] ? this.dataSource[this.conf.valName] : [];
      d.forEach(function (i) {
        _this2.data.forEach(function (da) {
          if (i === da.id) {
            data.push(da);
          }
        });
      });
      var lm = [];
      data.forEach(function (r) {
        r.id = r.id;
        r.pid = r.groupId;
        r.title_type = r.title;

        if (r.type === 2) {
          r.title_type = r.title + '(按钮)';
        } else {
          r.title_type = r.title + '(页面)';
        }

        if (r.groupId) {
          var t = true;

          for (var i = 0; i < lm.length; i++) {
            if (r.groupId === lm[i].groupId) {
              lm[i].children.push(r);
              t = false;
              break;
            }
          }

          if (lm.length <= 0 || t) {
            var g = {
              groupName: r.groupName,
              groupId: r.groupId,
              menuGroupName: r.menuGroupName,
              menuGroupIcon: r.menuGroupIcon,
              title: r.groupName,
              title_type: r.groupName,
              id: r.groupId,
              pid: 0,
              children: [r]
            };
            lm.push(g);
          }
        } else {
          lm.push(r);
        }
      });
      return lm;
    }
  },
  components: {
    treeTransfer: treeTransfer
  },
  methods: {
    add: function add(fromData, toData) {
      this.editorChange(toData);
    },
    remove: function remove(fromData, toData) {
      this.editorChange(toData);
    },
    editorChange: function editorChange(toData) {
      var vl = [];
      toData.forEach(function (td) {
        if (td.children) {
          td.children.forEach(function (r) {
            vl.push(r.id);
          });
        } else {
          vl.push(td.id);
        }
      });
      this.$emit('on-change', vl, this.conf.valName);
    } // renderFunc (h, { node, data, store }) {
    //   if (data.pid === 0 || data.pid === '0') {
    //     return h('a', {}, node.label)
    //   } else {
    //     return h('a', { domProps: { title: data.explanation }, style: { width: '100px' } }, node.label)
    //   }
    // }

  },
  watch: {// dataSource:{
    //   handler(){
    //     this.val = this.dataSource[this.conf.valName]
    //   },
    //   // 深度观察
    //   deep:true
    // }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'teachingTagService',
  methods: [{
    name: 'list',
    url: '/teachingTag/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/teachingTag/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'add',
    url: '/teachingTag/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/teachingTag/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/teachingTag/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'deleteAll',
    url: '/teachingTag/method/deleteAll',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'all',
    url: '/teachingTag/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'removeStudent',
    url: '/teachingTag/method/removeStudent/{id}/{studentId}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'batchRemoveStudent',
    url: '/teachingTag/method/batchRemoveStudent/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'addStudent',
    url: '/teachingTag/method/addStudent',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'addStaff',
    url: '/teachingTag/method/addStaff',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'removeStaff',
    url: '/teachingTag/method/removeStaff/{id}/{staffId}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'batchRemoveStaff',
    url: '/teachingTag/method/batchRemoveStaff/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'addTeachingMember',
    url: '/teachingTag/method/addTeachingMember',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'removeTeachingMember',
    url: '/teachingTag/method/removeTeachingMember/{id}/{staffId}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'batchTeachingMember',
    url: '/teachingTag/method/batchTeachingMember/{id}',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'OtherParentUtil',
  data: function data() {
    return {
      parentalIdentitys: [{
        text: "父亲",
        value: "1"
      }, {
        text: "母亲",
        value: "2"
      }, {
        text: "爷爷",
        value: "3"
      }, {
        text: "奶奶",
        value: "4"
      }, {
        text: "外公",
        value: "5"
      }, {
        text: "外婆",
        value: "6"
      }, {
        text: "其他",
        value: "0"
      }],
      otherParents: [],
      disabled: false
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    onAdd: function onAdd() {
      this.otherParents.push({
        id: "",
        name: "",
        account: "",
        parentalIdentity: ""
      });
    },
    initData: function initData() {
      if (this.conf.disabled) {
        this.disabled = this.conf.disabled;
      }

      if (this.dataSource.parents) {
        for (var i = 0; i < this.dataSource.parents.length; i++) {
          this.otherParents.push({
            id: this.dataSource.parents[i].id,
            name: this.dataSource.parents[i].name,
            account: this.dataSource.parents[i].account,
            parentalIdentity: String(this.dataSource.parents[i].parentalIdentity)
          });
        }

        this.handleChange();
      }
    },
    getSubject: function getSubject() {
      var _this = this;

      return new Promise(function (resolve) {
        _this.$api.apis.subjectService.getById({
          path: {
            id: _this.$route.params.id
          }
        }).then(function (data) {
          if (data) {
            resolve(data);
          }
        });
      });
    },
    //排序
    compare: function compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    handleChange: function handleChange() {
      for (var i = 0; i < this.otherParents.length; i++) {
        this.$set(this.otherParents, i, this.otherParents[i]);
      }

      this.$emit('on-change', this.otherParents, this.conf.valName);
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'masterOrderService',
  methods: [{
    name: 'list',
    url: '/paymentMasterOrder/method/list/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/paymentMasterOrder/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'subOrder',
    url: '/paymentMasterOrder/method/subOrder/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'refundOrderAll',
    url: '/paymentMasterOrder/method/refundOrder/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'subjectItem',
    url: '/paymentMasterOrder/method/subjectItem/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'subjectUser',
    url: '/paymentMasterOrder/method/subjectUser/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'cashReceipts',
    url: '/paymentMasterOrder/method/cashReceipts',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'payItemAmount',
    url: '/paymentMasterOrder/method/modify/payItemAmount',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'refundRequest',
    url: '/paymentMasterOrder/method/refundRequest',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'immediatelyRefund',
    url: '/paymentMasterOrder/method/immediatelyRefund',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'revokeRefundRequest',
    url: '/paymentMasterOrder/method/revokeRefundRequest',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'subOrderAuditing',
    url: '/paymentMasterOrder/method/subOrderAuditing',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'getSplitPayOrder',
    url: '/paymentMasterOrder/method/splitPayOrder/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'splitRefundRequest',
    url: '/paymentMasterOrder/method/splitRefundRequest',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
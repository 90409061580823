var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "cropper-content" }, [
      _c(
        "div",
        { staticClass: "cropper" },
        [
          _c("vueCropper", {
            ref: "cropper",
            attrs: {
              img: _vm.option.img,
              outputSize: _vm.option.size,
              outputType: _vm.option.outputType,
              info: true,
              full: _vm.option.full,
              canMove: _vm.canMove,
              canMoveBox: _vm.canMoveBox,
              original: _vm.option.original,
              autoCrop: _vm.option.autoCrop,
              autoCropWidth: _vm.option.autoCropWidth,
              autoCropHeight: _vm.option.autoCropHeight,
              fixedBox: _vm.fixedBox,
              fixed: _vm.fixed,
              fixedNumber: _vm.fixedNumber
            },
            on: { realTime: _vm.realTime }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "show-preview",
          style: {
            width: "300px",
            height: "300px",
            overflow: "hidden",
            margin: "0 25px",
            display: "flex",
            "align-items": "center"
          }
        },
        [
          _c("div", { staticClass: "preview", style: _vm.previews.div }, [
            _c("img", {
              style: _vm.previews.img,
              attrs: { src: _vm.previews.url }
            })
          ])
        ]
      )
    ]),
    _c("div", { staticClass: "footer-btn" }, [
      _c(
        "div",
        { staticClass: "scope-btn" },
        [
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-zoom-in" },
            on: {
              click: function($event) {
                return _vm.changeScale(1)
              }
            }
          }),
          _c("el-button", {
            attrs: { type: "primary", icon: "el-icon-zoom-out" },
            on: {
              click: function($event) {
                return _vm.changeScale(-1)
              }
            }
          }),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.rotateLeft } },
            [_vm._v("逆时针旋转")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.rotateRight } },
            [_vm._v("顺时针旋转")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "upload-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.uploadImg()
                }
              }
            },
            [_vm._v("上传")]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'MessageUtil',
  data: function data() {
    return {// val: "",
    };
  },
  props: {
    row: Object,
    valName: String
  },
  computed: {
    message: function message() {
      if (this.row.message.length > 10) {
        return this.row.message.substring(0, 10) + "......";
      } else {
        return this.row.message;
      }
    }
  },
  created: function created() {},
  components: {},
  mounted: function mounted() {
    this.$nextTick(function () {});
  },
  methods: {},
  watch: {
    'row._id': {
      handler: function handler() {},
      // 深度观察
      deep: true
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'deviceGroupService',
  methods: [{
    name: 'xyAll',
    url: '/deviceGroup/method/xy/all',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'teacherAuthorityService',
  methods: [{
    name: 'add',
    url: '/teacherAuthority/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/teacherAuthority/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'fastList',
    url: '/teacherAuthority/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'all',
    url: '/teacherAuthority/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/teacherAuthority/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'staffTree',
    url: '/teacherAuthority/method/staffTree',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%", "margin-bottom": "16px" },
          attrs: { placeholder: "请选择" },
          on: { change: _vm.onChange },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        [
          _vm._l(_vm.options, function(item) {
            return _c("el-option", {
              key: item.value,
              attrs: { label: item.text, value: item.value }
            })
          }),
          _c("el-option", {
            attrs: { label: "校外基地", value: "offCampusBase" }
          })
        ],
        2
      ),
      _vm.value !== "offCampusBase"
        ? _c("div", { staticClass: "mainsrp-form" }, [
            _c(
              "div",
              { staticClass: "m-form" },
              [
                _c(
                  "el-form",
                  {
                    ref: "positionTypeForm",
                    attrs: {
                      "label-position": "top",
                      model: _vm.positionTypeForm,
                      rules: _vm.positionTypeRules,
                      "show-message": false,
                      "label-width": "100px",
                      size: "medium"
                    },
                    nativeOn: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "form-mn" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "位置类型",
                              prop: "positionType",
                              size: "small"
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "margin-bottom": "16px"
                                },
                                attrs: { placeholder: "请选择" },
                                on: { change: _vm.onChange },
                                model: {
                                  value: _vm.positionTypeForm.positionType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.positionTypeForm,
                                      "positionType",
                                      $$v
                                    )
                                  },
                                  expression: "positionTypeForm.positionType"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "楼宇", value: "0" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "场地", value: "1" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
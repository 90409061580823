var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dy-index" }, [
      _c("img", {
        staticStyle: { width: "100%" },
        attrs: { src: require("@/assets/images/001.jpg") }
      }),
      _c("div", { staticClass: "dy-index-layer" }),
      _c("p", { staticClass: "dy-index-layer-text" }, [
        _vm._v("此区域即将开放")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
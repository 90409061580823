import "core-js/modules/es.array.every";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.iterator";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getToken } from 'api/qiniu'
import util from '@/libs/util.js';
import axios from 'axios';
export default {
  name: 'EditorSlideUpload',
  props: {
    color: {
      type: String,
      default: '#1890ff'
    },
    upFile: Function,
    upFileUrl: String,
    geFile: Function
  },
  data: function data() {
    return {
      dialogVisible: false,
      listObj: {},
      fileList: [] // upFile: this.$api.apis.method.upFile

    };
  },
  methods: {
    upload: function upload(val) {
      var file = val.file;
      var self = this;
      var reader = new window.FileReader();

      reader.onload = function (ef) {
        if (self.upFileUrl) {
          var fileType = "img";
          var formData = new FormData();
          formData.append('file', file);
          formData.append('fileType', fileType); // console.log(self.upFileUrl)
          // 采用简介上传

          axios({
            headers: {
              Authorization: "Bearer ".concat(util.cookies.get('token')),
              'Content-Type': 'multipart/form-data;charse=UTF-8'
            },
            url: self.upFileUrl,
            method: 'post',
            data: formData,
            processData: false,
            // 告诉axios不要去处理发送的数据(重要参数)
            contentType: false // 告诉axios不要去设置Content-Type请求头

          }).then(function (res) {
            var currentPic = res.data;

            if (self.geFile) {
              currentPic = self.geFile({
                path: {
                  id: res.data
                }
              });
            } // const currentPic = da.FILE_VIEW_URL + res.data.data


            var uid = file.uid;
            var objKeyArr = Object.keys(self.listObj);

            for (var i = 0, len = objKeyArr.length; i < len; i++) {
              if (self.listObj[objKeyArr[i]].uid === uid) {
                self.listObj[objKeyArr[i]].url = currentPic;
                self.listObj[objKeyArr[i]].hasSuccess = true;
                return;
              }
            }
          }).catch(function () {
            self.handleRemove(file);
          }); // const data = {
          //   filename: file.name,
          //   type: file.type,
          //   content: ef.target.result,
          //   group: 'editor',
          //   channel: 'oss'
          // }
          // self.upFile()
          //   .then((da) => {
          //     const formData = new FormData()
          //     formData.append('file', file)
          //     formData.append('uploadSubPath', data.group)
          //     axios({
          //       headers: {
          //         Authorization: `Bearer ${da.token}`,
          //         'Content-Type': 'multipart/form-data;charse=UTF-8'
          //       },
          //       url: da.FILE_UPDATE_URL,
          //       method: 'post',
          //       data: formData,
          //       processData: false, // 告诉axios不要去处理发送的数据(重要参数)
          //       contentType: false // 告诉axios不要去设置Content-Type请求头
          //     }).then((res) => {
          //       const currentPic = da.FILE_VIEW_URL + res.data.data
          //       const uid = file.uid
          //       const objKeyArr = Object.keys(self.listObj)
          //       for (let i = 0, len = objKeyArr.length; i < len; i++) {
          //         if (self.listObj[objKeyArr[i]].uid === uid) {
          //           self.listObj[objKeyArr[i]].url = currentPic
          //           self.listObj[objKeyArr[i]].hasSuccess = true
          //           return
          //         }
          //       }
          //     }).catch(() => {
          //       self.handleRemove(file)
          //     })
          //   }).catch(() => {
          //     self.handleRemove(file)
          //   })
          // self.upFile({ body: data })
          //   .then((da) => {
          //     const uid = file.uid
          //     const objKeyArr = Object.keys(self.listObj)
          //     for (let i = 0, len = objKeyArr.length; i < len; i++) {
          //       if (self.listObj[objKeyArr[i]].uid === uid) {
          //         self.listObj[objKeyArr[i]].url = da.url
          //         self.listObj[objKeyArr[i]].hasSuccess = true
          //         return
          //       }
          //     }
          //   }).catch(() => {
          //     self.handleRemove(file)
          //   })
        } else {
          self.handleRemove(file);
        }
      };

      reader.readAsDataURL(file);
    },
    checkAllSuccess: function checkAllSuccess() {
      var _this = this;

      return Object.keys(this.listObj).every(function (item) {
        return _this.listObj[item].hasSuccess;
      });
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      var arr = Object.keys(this.listObj).map(function (v) {
        return _this2.listObj[v];
      });

      if (!this.checkAllSuccess()) {
        this.$message('Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!');
        return;
      }

      this.$emit('successCBK', arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    handleSuccess: function handleSuccess(response, file) {
      var uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);

      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          this.listObj[objKeyArr[i]].url = response.files.file;
          this.listObj[objKeyArr[i]].hasSuccess = true;
          return;
        }
      }
    },
    handleRemove: function handleRemove(file) {
      var uid = file.uid;
      var objKeyArr = Object.keys(this.listObj);

      for (var i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]];
          return;
        }
      }
    },
    beforeUpload: function beforeUpload(file) {
      var _self = this;

      var _URL = window.URL || window.webkitURL;

      var fileName = file.uid;
      this.listObj[fileName] = {};
      return new Promise(function (resolve) {
        var img = new Image();
        img.src = _URL.createObjectURL(file);

        img.onload = function () {
          _self.listObj[fileName] = {
            hasSuccess: false,
            uid: file.uid,
            width: this.width,
            height: this.height
          };
        };

        resolve(true);
      });
    }
  }
};
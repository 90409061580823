import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'adminService',
  methods: [{
    name: 'findMerchant',
    url: '/admin/method/findMerchant',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'saveSelectionMerchant',
    url: '/admin/method/saveSelectionMerchant',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.join";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";

/*
会自动生成 api.service.getxxx({path,params,body,headers})方法
 */
import config from './config';
import * as httpTypes from './httpTypes';
import CryptoJS from 'crypto-js'; // 加密

export function encrypt(word, keyStr) {
  // export function encrypt (word, keyStr) {
  keyStr = keyStr || 'abcdefgabcdefg12';
  var key = CryptoJS.enc.Utf8.parse(keyStr); // Latin1 w8m31+Yy/Nw6thPsMpO5fg==

  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  }); // var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
  // return encrypted.toString()
  // return encrypted.ciphertext.toString()

  return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
} // 解密

export function decrypt(word, keyStr) {
  // export function decrypt (word) {
  keyStr = keyStr || 'abcdefgabcdefg12';
  var parsedWordArray = CryptoJS.enc.Base64.parse(word);
  var parsedStr = parsedWordArray.toString(CryptoJS.enc.Utf8);
  var key = CryptoJS.enc.Utf8.parse(keyStr); // Latin1 w8m31+Yy/Nw6thPsMpO5fg==

  var decrypt = CryptoJS.AES.decrypt(parsedStr, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

function install(Vue, globalOptions) {
  if (globalOptions) {
    Object.assign(config, globalOptions);
  } // Object.keys(globalOptions).map(function (key) {
  //     config[key] = globalOptions[key]
  // })


  var $api = {
    httpTypes: httpTypes
  };
  /**
   * 这里添加等待效果
   */
  //
  // Vue.component('loading', loadingTem)
  //

  var _isTest = config.isTest;
  var _http = config.http;
  var _baseUrl = config.base;
  var _apis = config.apis;
  $api.baseUrl = _baseUrl;
  var _api = {};

  _apis.forEach(function (s) {
    var serviceName = s.serviceName;
    var methods = s.methods;
    var se = {};
    methods.forEach(function (m) {
      // {path, params, body}
      se[m.name] = function (parameter) {
        if (!parameter) {
          parameter = {};
        }

        var promise = new Promise(function (resolve, reject) {
          var _h = {};
          var url = m.url;
          var path = parameter.path;
          var params = parameter.params;
          var body = parameter.body;
          var isAes = parameter.isAes; // 是否需要加密

          var aesKey = parameter.aesKey; // 加密用到的key

          if (path) {
            for (var p in path) {
              var r = new RegExp('{' + p + '}', 'g');
              url = url.replace(r, path[p]);
            }
          }

          if (_isTest) {
            //console.debug(m.testReturn)
            resolve(m.testReturn);
          } else {
            if (m.type === httpTypes.GET) {
              _h = _http.get(_baseUrl + url, {
                params: params
              });
            }

            if (m.type === httpTypes.POST) {
              //TODO  如果需要加密，
              if (isAes && isAes != '' && aesKey && aesKey != '') {
                var _body = '';

                if (body) {
                  _body = encrypt(JSON.stringify(body), aesKey);
                } else {
                  _body = encrypt(JSON.stringify({}), aesKey);
                }

                _h = _http.post(_baseUrl + url, {
                  data: _body
                }, {
                  params: params
                });
              } else {
                _h = _http.post(_baseUrl + url, body, {
                  params: params
                });
              }
            }

            if (m.type === httpTypes.PUT) {
              //TODO  如果需要加密，
              if (isAes && isAes != '' && aesKey && aesKey != '') {
                var _body2 = '';

                if (body) {
                  _body2 = encrypt(JSON.stringify(body), aesKey);
                } else {
                  _body2 = encrypt(JSON.stringify({}), aesKey);
                }

                _h = _http.put(_baseUrl + url, {
                  data: _body2
                }, {
                  params: params
                });
              } else {
                _h = _http.put(_baseUrl + url, body, {
                  params: params
                });
              }
            }

            if (m.type === httpTypes.PATCH) {
              //TODO  如果需要加密，
              if (isAes && isAes != '' && aesKey && aesKey != '') {
                var _body3 = '';

                if (body) {
                  _body3 = encrypt(JSON.stringify(body), aesKey);
                } else {
                  _body3 = encrypt(JSON.stringify({}), aesKey);
                }

                _h = _http.patch(_baseUrl + url, {
                  data: _body3
                }, {
                  params: params
                });
              } else {
                _h = _http.patch(_baseUrl + url, body, {
                  params: params
                });
              }
            }

            if (m.type === httpTypes.DELETE) {
              _h = _http.delete(_baseUrl + url, {
                params: params
              });
            }

            _h.then(function (response) {
              // 响应成功回调
              // //console.debug(response)
              //TODO  如果需要加密，
              // if (isAes && isAes != '' && aesKey && aesKey != '') {
              //   response.data['data']
              //   if (response.data['isAes']) {
              //     let _body = decrypt(response.data['data'], aesKey)
              //     resolve(JSON.parse(_body))
              //   } else {
              //     resolve(response.data)
              //   }
              // } else {
              //   resolve(response.data)
              // }
              if (response.data['isAes']) {
                var _body4 = decrypt(response.data['data'], aesKey);

                resolve(JSON.parse(_body4));
              } else {
                resolve(response.data);
              }
            }, function (response) {
              // 响应错误回调
              // //console.debug(response)
              reject(response);
            });
          }
        });
        return promise;
      }; // se[m['name'] + 'Url'] = _baseUrl + m.url


      se[m.name + 'Url'] = function (parameter) {
        if (!parameter) {
          parameter = {};
        }

        var url = m.url;
        var path = parameter.path;
        var params = parameter.params;

        if (path) {
          for (var p in path) {
            var r = new RegExp('{' + p + '}', 'g');
            url = url.replace(r, path[p]);
          }
        }

        url = _baseUrl + url;

        if (params) {
          var ps = [];

          for (var k in parameter.params) {
            ps.push(k + '=' + encodeURI(parameter.params[k]));
          }

          if (ps.length > 0) {
            url = url + '?' + ps.join('&');
          }
        }

        return url;
      };
    });
    _api[serviceName] = se;
    $api.apis = _api;
  }); //


  Vue.prototype.$api = $api;
  console.log('安装api插件成功');
}

export default install;
/*
* 通用配置
* */
function copy(val) {
  var json = JSON.stringify(val);
  return JSON.parse(json);
}

export var queryConfigBase = {
  title: '',
  hasQuery: true,
  // 是否有查询
  globalOperation: [{
    icon: 'el-icon-search',
    // 按钮图标
    labelName: '',
    // 操作名称
    doFunc: function doFunc(val) {//console.debug(val)
    } // 操作方法

  }],
  query: [{
    name: '标题',
    valName: 'name',
    type: 'text',
    // text/select/date/number/select_filterable
    selValue: [{
      value: '',
      text: ''
    }],
    asyncValue: function asyncValue(val) {//console.debug(val)
    },
    value: '',
    opt: 'LIKE' // EQ/LIKE/IN/LE/GE/NE/LT/GT/LE/GE/LIKE/ISNULL/NOTNULL/IN/BETWEEN

  }]
};
export var listConfigBase = {
  title: '',
  expand: {
    // 扩展
    has: false,
    // 是否有扩展，默认没有false
    asyncValue: function asyncValue(val) {//console.debug(val)
    },
    // 会对 row数据中增加 endExpandAsyncValue：true ,表示已经异步加载过
    models: [{
      labelName: 'test',
      valName: 'test',
      filter: function filter(val) {
        // 单个 字段进行过滤
        //console.debug(val)
        return val;
      },
      filterRow: function filterRow(val) {
        // 整行数据进行过滤显示
        //console.debug(val)
        return val;
      }
    }]
  },
  hasOperation: true,
  // 是否有操作
  hasPagination: true,
  // 是否有分页
  pageSourceUrl: '',
  pageSourceFunc: Function,
  pageSourceFuncArgs: {
    path: null
  },
  sort: '',
  // String KEY_ASC = "-";String KEY_DESC = "+"; -sort
  sortFunc: Function,
  // 配合sort-change 排序变更方法
  // defaultSort:{prop: 'date', order: 'descending'}, 配合element 的默认排序
  page: 0,
  pageSize: 10,
  multiSelect: false,
  // 是否显示多选按钮默认否,
  multiSelectValue: [],
  operation: [{
    labelName: '修改',
    // 操作名称
    showFunc: function showFunc(val) {
      //console.debug(val)
      return true;
    },
    doFunc: function doFunc(val) {//console.debug(val)
    } // 操作方法

  }],
  column: [{
    labelName: '',
    valName: '',
    type: 'label',
    // label/custom(自定义）
    component: {},
    // 只有custom（自定义才有用）
    fixed: false,
    filter: function filter(val) {
      //console.debug(val)
      return val;
    },
    asyncValue: function asyncValue(val) {//console.debug(val)
    },
    sortable: false // 是否支持排序

  }],
  multistageColumnCount: 0,
  multistageColumn: [{
    labelName: '',
    valName: '',
    type: 'label',
    // label/custom(自定义）
    component: {},
    // 只有custom（自定义才有用）
    fixed: false,
    filter: function filter(val) {
      //console.debug(val)
      return val;
    },
    asyncValue: function asyncValue(val) {//console.debug(val)
    },
    sortable: false,
    // 是否支持排序
    column: [{
      labelName: '',
      valName: '',
      type: 'label',
      // label/custom(自定义）
      component: {},
      // 只有custom（自定义才有用）
      fixed: false,
      filter: function filter(val) {
        //console.debug(val)
        return val;
      },
      asyncValue: function asyncValue(val) {//console.debug(val)
      },
      sortable: false,
      // 是否支持排序
      column: []
    }]
  }]
};
export var pageSourceConfigBase = {
  totalElements: 0,
  // 所有数据的条目
  totalPages: 0,
  // 设置分页的总页数,
  size: 10,
  // 每一页的条目数,
  number: 0,
  // 当前页号,
  content: [],
  // 分页数据内容,
  sort: {},
  // 排序,
  last: true,
  // 末页,
  numberOfElements: 0,
  // 当前页的数据条目数
  first: true // 首页

};
export var modelConfigBase = {
  title: '',
  sourceUrl: '',
  sourceFunc: Function,
  sourceFuncArgs: {
    path: null,
    params: null,
    body: null
  },
  ope: 'add',
  // view显示/edit修改/add添加
  models: [{
    labelName: 'test',
    valName: 'test',
    about: 'test',
    type: 'text',
    // text/select/date/label/textarea/number/email/password/phone/editor/amount/select_filterable/transfer/custom(自定义）
    value: '',
    // 值，在transfer类型下此值必须是 value: []
    selValue: [],
    component: {},
    // 只有custom（自定义才有用）
    titles: ['', ''],
    // 目前只针对transfer有用,
    required: '如果有必填，填消息',
    ClassObject: {},
    validator: function validator(rule, value, callback) {//console.debug(rule, value, callback)
    },
    asyncValue: function asyncValue(val) {//console.debug(val)
    },
    asyncData: function asyncData(val, Data) {//console.debug(val, Data)
    },
    filter: function filter(val) {
      //console.debug(val)
      return val;
    }
  }],
  hasOperation: true,
  operation: [{
    labelName: '',
    // 操作名称
    hasValidator: true,
    // 是否需要验证
    showFunc: function showFunc(val) {
      //console.debug(val)
      return true;
    },
    doFunc: function doFunc(val, config) {//console.debug(val)
      //console.debug(config)
    } // 操作方法

  }],
  afterDoSelect: function afterDoSelect(conf, val) {// 查询之后执行
    //console.debug(val)
  }
};
export var queryConfig = function queryConfig(conf) {
  var _config = copy(queryConfigBase);

  if (conf) {
    for (var k in conf) {
      _config[k] = conf[k];
    }
  }

  return _config;
};
export var listConfig = function listConfig(conf) {
  var _config = copy(listConfigBase);

  if (conf) {
    for (var k in conf) {
      _config[k] = conf[k];
    }
  }

  return _config;
};
export var pageSourceConfig = function pageSourceConfig(conf) {
  var _config = copy(pageSourceConfigBase);

  if (conf) {
    for (var k in conf) {
      _config[k] = conf[k];
    }
  }

  return _config;
};
export var modelConfig = function modelConfig(conf) {
  var _config = copy(modelConfigBase);

  if (conf) {
    for (var k in conf) {
      _config[k] = conf[k];
    }
  }

  return _config;
};
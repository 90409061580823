import "core-js/modules/es.array.join";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import cropper from '../../../components/zhc-crud/Cropper';
import utils from '@/libs/util';
export default {
  inject: ['cancel'],
  name: 'CourseUtil',
  data: function data() {
    return {
      mark: "",
      moduleType: "course",
      merchantId: "",
      classTypes: [{
        text: "新生班",
        value: "0"
      }, {
        text: "老生班",
        value: "1"
      }],
      branchMerchants: [],
      semesterTags: [],
      industryClasss: [],
      cascaderindex: 0,
      contentForm: {
        w: "1",
        contents: [{
          semesterTagId: "",
          branchMerchantId: "all",
          industryClass: ["all"],
          tags: "",
          image: "",
          classType: ""
        }]
      },
      imageItem: {
        labelName: '图片上传',
        valName: 'image',
        about: '图片上传',
        type: 'cropper',
        value: '',
        closeCropper: true,
        fileSize: 512000,
        fileSizeStr: "500KB",
        upFileUrl: this.$api.apis.fileService.upFileUrl()
      }
    };
  },
  props: {
    moduleMark: String
  },
  components: {
    cropper: cropper
  },
  created: function created() {
    this.init();
  },
  methods: {
    editorChange: function editorChange(val, valName, index) {
      if (valName === 'image') {
        this.contentForm.contents[index][valName] = val;
      }
    },
    onWchange: function onWchange() {
      if (Number(this.contentForm.w) == 1) {
        var contents = [];
        contents.push(this.contentForm.contents[0]);
        this.contentForm.contents = contents;
      } else if (Number(this.contentForm.w) == 2) {
        if (this.contentForm.contents.length >= 2) {
          var _contents2 = [];

          _contents2.push(this.contentForm.contents[0]);

          _contents2.push(this.contentForm.contents[1]);

          this.contentForm.contents = _contents2;
        } else {
          this.contentForm.contents.push({
            semesterTagId: "",
            branchMerchantId: "all",
            industryClass: ["all"],
            tags: "",
            image: "",
            classType: ""
          });
        }
      }
    },
    init: function init() {
      var _this = this;

      this.getSemesterTag();
      this.getBranchMerchant();
      this.getIndustryClass();
      this.$api.apis.wxHomeConfigService.findModuleData({
        params: {
          type: this.moduleType,
          mark: this.moduleMark
        }
      }).then(function (d) {
        _this.merchantId = d.merchantId;

        if (d && d.mark) {
          _this.mark = d.mark;
        } else {
          _this.mark = "";
        }

        _this.contentForm.w = "1";

        if (d.w != 0) {
          _this.contentForm.w = d.w + "";
        }

        if (d && d.data && d.data.length > 0) {
          // console.log("dsdsssd")
          // console.log(d.data[0].params.branchMerchantId)
          var _contents = [];

          for (var index = 0; index < d.data.length; index++) {
            var _content = d.data[index];
            var _tags = "";

            if (_content.params["tags"] && _content.params["tags"] != "") {
              _tags = _content.params["tags"].split(",").join(" ");
            }

            var _industryClass = [];

            if (_content.params["oneIndustryClass"] && _content.params["oneIndustryClass"] != "") {
              _industryClass.push(_content.params["oneIndustryClass"]);
            }

            if (_content.params["twoIndustryClass"] && _content.params["twoIndustryClass"] != "") {
              _industryClass.push(_content.params["twoIndustryClass"]);
            }

            _contents.push({
              semesterTagId: _content.params.semesterId,
              branchMerchantId: _content.params.branchMerchantId,
              industryClass: _industryClass,
              tags: _tags,
              image: _content.image,
              classType: _content.params.classType
            });
          }

          _this.contentForm.contents = _contents;
        } else {
          _this.contentForm.contents = [{
            semesterTagId: "",
            branchMerchantId: "all",
            industryClass: ["all"],
            tags: "",
            image: "",
            classType: ""
          }];
        }
      });
    },
    onCancel: function onCancel() {
      this.cancel();
    },
    onDelModule: function onDelModule() {
      var _this2 = this;

      //删除模块
      this.$confirm('是否删除课程商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        if (!_this2.mark) {
          _this2.cancel();

          return;
        }

        var _body = {
          type: _this2.moduleType,
          mark: _this2.mark
        };

        _this2.$api.apis.wxHomeConfigService.delModule({
          body: _body,
          isAes: true,
          aesKey: utils.cookies.get('salt')
        }).then(function () {
          _this2.$message({
            type: 'success',
            message: '删除成功!'
          });

          _this2.cancel();
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    onSave: function onSave() {
      var _this3 = this;

      this.$refs["contentForm"].validate(function (valid) {
        if (valid) {
          var _data = [];

          if (_this3.contentForm.contents.length > 0) {
            for (var index = 0; index < _this3.contentForm.contents.length; index++) {
              var _content = _this3.contentForm.contents[index];
              var _tags = "";

              if (_content.tags) {
                _tags = _content.tags.split(" ").join(",");
              }

              var _classId = "";
              var oneIndustryClass = "";
              var twoIndustryClass = "";

              if (_content.industryClass.length > 0) {
                if (_content.industryClass[0] != "all") {
                  _classId = _content.industryClass[0];
                }

                oneIndustryClass = _content.industryClass[0];
              }

              if (_content.industryClass.length == 2) {
                if (_content.industryClass[1] != "all") {
                  _classId = _content.industryClass[1];
                }

                twoIndustryClass = _content.industryClass[1];
              }

              var _params = {
                tags: _tags,
                semesterId: _content.semesterTagId,
                branchMerchantId: _content.branchMerchantId,
                classId: _classId,
                oneIndustryClass: oneIndustryClass,
                twoIndustryClass: twoIndustryClass,
                merchantId: _this3.merchantId,
                classType: _content.classType
              };

              _data.push({
                image: _content.image,
                params: _params
              });
            }
          }

          var _body = {
            type: _this3.moduleType,
            data: _data,
            w: Number(_this3.contentForm.w),
            mark: _this3.mark
          };

          _this3.$api.apis.wxHomeConfigService.addModuleData({
            body: _body,
            isAes: true,
            aesKey: utils.cookies.get('salt')
          }).then(function () {
            _this3.$message({
              type: 'success',
              message: '保存成功!'
            });

            _this3.init();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //学期
    getSemesterTag: function getSemesterTag() {
      var _this4 = this;

      this.semesterTags = [];
      this.$api.apis.semesterTagService.all({}).then(function (data) {
        if (data) {
          for (var i = 0; i < data.length; i++) {
            _this4.semesterTags.push({
              text: data[i].name,
              value: data[i]._id
            });
          }
        }
      });
    },
    //机构
    getBranchMerchant: function getBranchMerchant() {
      var _this5 = this;

      this.branchMerchants = [{
        text: "全部机构",
        value: "all"
      }];
      this.$api.apis.merchantService.findBranchMerchantAll({}).then(function (data) {
        if (data) {
          for (var i = 0; i < data.length; i++) {
            _this5.branchMerchants.push({
              text: data[i].abbreviation,
              value: data[i]._id
            });
          }
        }
      });
    },
    //分类
    getIndustryClass: function getIndustryClass() {
      var _this6 = this;

      this.industryClasss = [];
      ++this.cascaderindex;
      this.$api.apis.industryClassService.all({
        params: {
          "havSuperior,BOOL": false
        }
      }).then(function (data) {
        if (data) {
          (function () {
            var _industryClasss = [{
              value: "all",
              label: "全部类目",
              leaf: false
            }];

            var _loop = function _loop(i) {
              _this6.$api.apis.industryClassService.all({
                params: {
                  "superiorId": data[i]._id
                }
              }).then(function (d) {
                var _industryClass = {
                  value: data[i]._id,
                  label: data[i].name,
                  leaf: false
                };

                if (d && d.length > 0) {
                  var _children = [{
                    value: "all",
                    label: "全部科目",
                    leaf: false
                  }];

                  for (var j = 0; j < d.length; j++) {
                    _children.push({
                      value: d[j]._id,
                      label: d[j].name,
                      leaf: false
                    });
                  }

                  _industryClass["children"] = _children;
                }

                _industryClasss.push(_industryClass);
              });
            };

            for (var i = 0; i < data.length; i++) {
              _loop(i);
            }

            _this6.industryClasss = _industryClasss;
          })();
        }
      });
    }
  },
  watch: {
    moduleMark: {
      handler: function handler() {
        this.init();
      },
      // 深度观察
      deep: true
    }
  }
};
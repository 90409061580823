import "core-js/modules/es.array-buffer.slice";
import "core-js/modules/es.function.name";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.split";
import "core-js/modules/es.typed-array.uint8-array";
import "core-js/modules/es.typed-array.copy-within";
import "core-js/modules/es.typed-array.every";
import "core-js/modules/es.typed-array.fill";
import "core-js/modules/es.typed-array.filter";
import "core-js/modules/es.typed-array.find";
import "core-js/modules/es.typed-array.find-index";
import "core-js/modules/es.typed-array.for-each";
import "core-js/modules/es.typed-array.includes";
import "core-js/modules/es.typed-array.index-of";
import "core-js/modules/es.typed-array.iterator";
import "core-js/modules/es.typed-array.join";
import "core-js/modules/es.typed-array.last-index-of";
import "core-js/modules/es.typed-array.map";
import "core-js/modules/es.typed-array.reduce";
import "core-js/modules/es.typed-array.reduce-right";
import "core-js/modules/es.typed-array.reverse";
import "core-js/modules/es.typed-array.set";
import "core-js/modules/es.typed-array.slice";
import "core-js/modules/es.typed-array.some";
import "core-js/modules/es.typed-array.sort";
import "core-js/modules/es.typed-array.subarray";
import "core-js/modules/es.typed-array.to-locale-string";
import "core-js/modules/es.typed-array.to-string";
import _typeof from "/Users/liuyutao/opt/wuxi1/campus_sys_merchant/web/node_modules/@babel/runtime/helpers/esm/typeof";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
/*
* 工具类
* */

function copy(val) {
  var json = JSON.stringify(val);
  return JSON.parse(json);
}

function copyTo(ol, ne) {
  for (var k in ne) {
    ol[k] = ne[k];
  }

  return ol;
}

function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

function trim(str) {
  if (str) {
    var trimLeft = /^\s+/;
    var trimRight = /\s+$/;
    var s = str.replace(trimLeft, '').replace(trimRight, '');
    return s;
  }

  return '';
}

function isNull(str) {
  var t = true;

  if (str) {
    if (str.constructor === String) {
      var s = trim(str);

      if (s.length > 0) {
        t = false;
      }
    } else if (str.constructor === Array) {
      if (str.length > 0) {
        t = false;
      }
    } else {
      t = false;
    }
  }

  return t;
}

function isNullAndNum(str) {
  var t = true;

  if (str) {
    if (str.constructor === String) {
      var s = trim(str);

      if (s.length > 0) {
        t = false;
      }
    } else if (str.constructor === Array) {
      if (str.length > 0) {
        t = false;
      }
    } else {
      t = false;
    }
  } else {
    if (str === 0) {
      return false;
    }
  }

  return t;
}

function objectCount(val) {
  var c = 0;

  if (_typeof(val) === 'object') {
    for (var k in val) {
      //console.debug(k)
      c++;
    }
  } else {
    c = val.length;
  }

  return c;
}

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], {
    type: mime
  });
} // 将blob转换为file


function blobToFile(theBlob, fileName) {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
} // 获得钱


function getPrice(val, w) {
  if (!val) {
    return 0;
  }

  var s = 0;

  if (typeof val === 'string') {
    try {
      s = Number(val);
    } catch (e) {
      console.log(e);
    }
  } else {
    s = val;
  }

  s = s * 1;
  s = s / 100.0;

  if (s >= 10000 && w) {
    s = s / 10000.0;
    return s.toFixed(2) + w;
  } else {
    return s.toFixed(2);
  }
} // 时间戳格式化


function dayjsformat(val, format) {
  if (!format) {
    format = 'YYYY-MM-DD HH:mm:ss';
  }

  return dayjs(val).tz('Asia/Shanghai').format(format);
}

export default {
  copy: copy,
  copyTo: copyTo,
  isExternal: isExternal,
  trim: trim,
  isNull: isNull,
  objectCount: objectCount,
  dataURLtoBlob: dataURLtoBlob,
  blobToFile: blobToFile,
  getPrice: getPrice,
  isNullAndNum: isNullAndNum,
  dayjsformat: dayjsformat
};
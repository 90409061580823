import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'studentService',
  methods: [{
    name: 'list',
    url: '/student/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/student/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'add',
    url: '/student/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/student/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/student/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'deleteAll',
    url: '/student/method/deleteAll',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'all',
    url: '/student/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'stop',
    url: '/student/method/stop/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'recovery',
    url: '/student/method/recovery/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'audit',
    url: '/student/method/audit/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'tree',
    url: '/student/method/tree',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'verifyUesrSameChild',
    url: '/student/method/verifyUesrSameChild',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
import "core-js/modules/es.object.to-string";
//
//
//
//
//
//
//
//
//
export default {
  name: 'CustomPayChannelUtil',
  data: function data() {
    return {
      isDisabled: false,
      payChannels: [],
      payChannelIds: []
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.initData();
  },
  computed: {},
  components: {},
  methods: {
    initData: function initData() {
      var _this = this;

      //缴费渠道
      this.payChannels = [];
      this.payChannelIds = [];

      if (this.dataSource.items && this.dataSource.items.length > 0) {
        for (var i = 0; i < this.dataSource.items.length; i++) {
          var _item = this.dataSource.items[i];

          if (_item.itemId != "") {
            this.getPaymentChanne(_item.itemId).then(function (data) {
              if (data && data.length > 0) {
                for (var _i in data) {
                  var isExistence = false;

                  for (var j in _this.payChannels) {
                    if (_this.payChannels[j] == data[_i]) {
                      isExistence = true;
                      break;
                    }
                  }

                  if (!isExistence) {
                    _this.payChannels.push(data[_i]);
                  }
                }
              }
            });
          }
        }
      } //this.getPaymentItem()

    },
    initPayChannelId: function initPayChannelId() {
      if (this.conf.disabled) {
        this.isDisabled = true;
      }

      if (this.dataSource._id && this.dataSource.isCustomPayChannel == 1) {
        this.payChannelIds = this.dataSource.customChannelTypes;
        this.handleChange();
      }
    },
    getPaymentChanne: function getPaymentChanne(itemId) {
      var _this2 = this;

      return new Promise(function (resolve) {
        _this2.$api.apis.paymentItemService.findPaymentChanneByItemId({
          path: {
            id: itemId
          }
        }).then(function (data) {
          resolve(data);
        });
      });
    },
    handleChange: function handleChange() {
      this.$emit('on-change', this.payChannelIds, this.conf.valName);
    }
  },
  watch: {
    "dataSource.items": {
      handler: function handler() {
        this.initData();
      },
      // 深度观察
      deep: true
    },
    "dataSource._id": {
      handler: function handler() {
        this.initPayChannelId();
      },
      // 深度观察
      deep: true
    }
  },
  filters: {
    payChannelFilter: function payChannelFilter(val) {
      if (val === 'CHANNEL_TYPE_GDYAOYAOWX') {
        return "光大微信支付";
      } else if (val === 'CHANNEL_TYPE_WX') {
        return "微信支付";
      } else if (val === 'CHANNEL_TYPE_TJ_BANK_APP') {
        return "天津银行app";
      } else if (val === 'CHANNEL_TYPE_CASH') {
        return "现金";
      } else if (val === 'CHANNEL_TYPE_ACCOUNT') {
        return "账户（转发渠道）";
      } else if (val === 'CHANNEL_TYPE_CASH_TRANSFER_REMITTANCE') {
        return "转账汇款";
      } else if (val === 'CHANNEL_TYPE_CASH_ON_SITE_PAYMENT') {
        return "到校付款";
      } else if (val === 'CHANNEL_TYPE_CASH_CARD') {
        return "校一卡通";
      } else if (val === 'CHANNEL_TYPE_TJ_BANK_QJ') {
        return "天行微信支付";
      }
    }
  }
};
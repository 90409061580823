var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticStyle: { padding: "12px 0" } },
      [
        _c("el-tree", {
          ref: "selectStudentsTree",
          attrs: {
            data: _vm.treeData,
            props: _vm.props,
            load: _vm.loadNode,
            "highlight-current": true,
            lazy: "",
            "node-key": "id",
            "show-checkbox": "",
            "default-checked-keys": _vm.defaultCheckedKeys,
            "default-expanded-keys": _vm.defaultExpandedKeys
          },
          on: { "check-change": _vm.handleCheckChange }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'masterWagesService',
  methods: [{
    name: 'list',
    url: '/masterWages/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/masterWages/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/masterWages/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/masterWages/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'wagesTableColumn',
    url: '/masterWages/method/wagesTableColumn/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'wagesInfoFastList',
    url: '/masterWages/method/wagesInfoFastList/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
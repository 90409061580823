//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BottomOtherData',
  data: function data() {
    return {
      bottomOtherDatas: []
    };
  },
  props: {
    pageSource: Object,
    value: Object
  },
  created: function created() {
    this.bottomOtherDatas = this.pageSource.bottomOtherDatas;
  },
  computed: {},
  components: {},
  methods: {},
  watch: {
    pageSource: {
      handler: function handler() {
        this.bottomOtherDatas = this.pageSource.bottomOtherDatas;
      },
      // 深度观察
      deep: true
    }
  }
};
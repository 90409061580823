//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'ClassNameUtil',
  data: function data() {
    return {
      options: [{
        value: '1',
        label: '年级名称+班号（如一年级1班）'
      }, {
        value: '2',
        label: '入学年份+班号（如20级1班）'
      }, {
        value: '0',
        label: '自定义班级名称（如向日葵班）'
      }],
      nameType: '',
      classNameForm: {
        className: ""
      },
      classNameRules: {
        className: [{
          required: true,
          message: '请填写班级名称',
          trigger: 'blur'
        }]
      }
    };
  },
  props: {
    dataSource: Object,
    conf: Object
  },
  created: function created() {
    this.init();
  },
  computed: {},
  components: {},
  methods: {
    editorChange: function editorChange() {
      var _v = {
        nameType: this.nameType,
        className: this.classNameForm.className
      };
      this.$emit('on-change', _v, this.conf.valName);
    },
    init: function init() {
      if (this.dataSource[this.conf.valName]) {
        this.nameType = this.dataSource[this.conf.valName].nameType;
        this.classNameForm.className = this.dataSource[this.conf.valName].className;
        this.editorChange();
      }
    }
  },
  watch: {
    "dataSource._id": {
      handler: function handler() {
        this.init();
      },
      // 深度观察
      deep: true
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'studentWalletService',
  methods: [{
    name: 'list',
    url: '/studentWallet/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/studentWallet/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'card',
    url: '/studentWallet/method/card/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'binding',
    url: '/studentWallet/method/binding',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'bindingReading',
    url: '/studentWallet/method/bindingReading',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'unbind',
    url: '/studentWallet/method/unbind',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'loss',
    url: '/studentWallet/method/loss',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'revokeLoss',
    url: '/studentWallet/method/revokeLoss',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'bindingNew',
    url: '/studentWallet/method/bindingNew',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'bindingReadingNew',
    url: '/studentWallet/method/bindingReadingNew',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'cashReceiptsInfo',
    url: '/studentWallet/method/cashReceiptsInfo/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'cashReceipts',
    url: '/studentWallet/method/cashReceipts',
    type: types.POST,
    testReturn: {}
  }]
}];
export default apis;
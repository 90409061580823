var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    attrs: {
      placeholder: _vm.conf.name,
      props: _vm.props,
      filterable: "",
      clearable: "",
      size: "small"
    },
    on: { change: _vm.handleChange },
    model: {
      value: _vm.value,
      callback: function($$v) {
        _vm.value = $$v
      },
      expression: "value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("query2", { ref: "query", attrs: { config: _vm.queryConfig } }, [
            _c("h3", { staticClass: "tit" }, [_vm._v(_vm._s(_vm.title))])
          ])
        ],
        1
      ),
      _c("list2", { ref: "list", attrs: { config: _vm.listConfig } }),
      _c("EntityCardUtil", {
        attrs: { entityCardVisible: _vm.entityCardVisible }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
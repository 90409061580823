var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "margin-left": "10px" } },
    [
      _vm._l(_vm.contentDatas, function(o, i) {
        return [
          _c(
            "el-row",
            { key: i, staticStyle: { "margin-top": "10px" } },
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", [_vm._v(_vm._s(o.name))])
              ]),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949"
                    },
                    on: {
                      change: function($event) {
                        return _vm.onSwitchChange(i)
                      }
                    },
                    model: {
                      value: o.switchValue,
                      callback: function($$v) {
                        _vm.$set(o, "switchValue", $$v)
                      },
                      expression: "o.switchValue"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        placeholder: "请输入餐单价",
                        disabled: o.isDisabled
                      },
                      on: { change: _vm.onAmountChange },
                      model: {
                        value: o.amount,
                        callback: function($$v) {
                          _vm.$set(o, "amount", $$v)
                        },
                        expression: "o.amount"
                      }
                    },
                    [_c("template", { slot: "append" }, [_vm._v("元")])],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      }),
      _c(
        "el-row",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c("el-col", { attrs: { span: 4 } }, [
            _c("span", [_vm._v("餐标总价")])
          ]),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入餐单价" },
                  on: { change: _vm.onTotalAmountChange },
                  model: {
                    value: _vm.totalAmount,
                    callback: function($$v) {
                      _vm.totalAmount = $$v
                    },
                    expression: "totalAmount"
                  }
                },
                [_c("template", { slot: "append" }, [_vm._v("元")])],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.handleChange },
          model: {
            value: _vm.payChannelIds,
            callback: function($$v) {
              _vm.payChannelIds = $$v
            },
            expression: "payChannelIds"
          }
        },
        _vm._l(_vm.payChannels, function(pc) {
          return _c(
            "el-checkbox",
            { key: pc, attrs: { label: pc, disabled: _vm.isDisabled } },
            [_vm._v(_vm._s(_vm._f("payChannelFilter")(pc)))]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
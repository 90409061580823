var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-checkbox-group",
        {
          on: { change: _vm.handleChange },
          model: {
            value: _vm.checkList,
            callback: function($$v) {
              _vm.checkList = $$v
            },
            expression: "checkList"
          }
        },
        [
          _vm._l(_vm.grades, function(item, index) {
            return [
              _c(
                "el-row",
                { key: index },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c("el-checkbox", { attrs: { label: item._id } }, [
                        _vm._v(_vm._s(item.name))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
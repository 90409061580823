//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import utils from '@/libs/util';
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, LineChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, DataZoomComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
use([CanvasRenderer, BarChart, LineChart, TitleComponent, TooltipComponent, GridComponent, LegendComponent, DataZoomComponent]);
export default {
  mixins: [utils.mixin.modelMixin, utils.mixin.modelEditMixin],
  name: 'studentQuestionnaireStatistics',
  data: function data() {
    return {
      countryColors: ['#00008b', '#f00', '#ffde00', '#002a8f', '#003580', '#ed2939', '#000', '#003897', '#f93', '#bc002d', '#024fa2', '#000', '#00247d', '#ef2b2d', '#dc143c', '#d52b1e', '#e30a17', '#00247d', '#b22234'],
      barOptions: [],
      statisticsData: {
        studentCount: "0",
        studentSubmitCount: "0"
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.$nextTick(function () {
      this.initData();
      this.initEcharts();
    });
  },
  components: {
    VChart: VChart
  },
  methods: {
    //组装横向柱状图数据
    barHorizontalEcharts: function barHorizontalEcharts(title, data) {
      var yAxis = [];
      var seriesData = [];
      var series = [];

      if (data) {
        for (var i = 0; i < data.length; i++) {
          var _d = data[i];
          yAxis.push(_d.selValName); //seriesData.push(_d.count)

          seriesData.push({
            value: _d.count,
            itemStyle: {
              color: this.countryColors[i]
            }
          });
        }

        if (yAxis.length > 0) {
          yAxis.reverse();
          seriesData.reverse();
        }

        series.push({
          name: '',
          type: 'bar',
          label: {
            show: true
          },
          data: seriesData
        });
      }

      var barOption = {
        title: {
          text: title
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        // axisLabel: {
        //   padding: [10, 0, 0,50], // 上右下左
        // },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 1.0]
        },
        yAxis: {
          type: 'category',
          data: yAxis
        },
        series: series
      };
      return barOption;
    },
    initData: function initData() {
      var _this = this;

      this.$api.apis.studentQuestionnaireService.statistics({
        path: {
          id: this.$route.params.id
        }
      }).then(function (data) {
        if (data) {
          _this.statisticsData.studentCount = String(data.studentCount);
          _this.statisticsData.studentSubmitCount = String(data.studentSubmitCount); ///console.log(data)

          _this.barOptions = [];

          if (data.statisticsCustomForm) {
            for (var key in data.statisticsCustomForm) {
              console.log("111111");
              var _customForm = data.statisticsCustomForm[key];
              console.log("2222");

              var barOption = _this.barHorizontalEcharts(key, _customForm);

              _this.barOptions.push(barOption);
            }
          }

          console.log(_this.barOptions);
        }
      });
    }
  }
};
import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'studentQuestionnaireManageService',
  methods: [{
    name: 'list',
    url: '/studentQuestionnaire/manage/method/list/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'info',
    url: '/studentQuestionnaire/manage/method/info/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("span", { staticStyle: { "margin-right": "10px" } }, [
            _vm._v(_vm._s(_vm.teacherName))
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSelect } },
            [_vm._v("请选择")]
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "按部门选择职员(可多选)",
            visible: _vm.dialogVisible,
            width: "25%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c("el-input", {
            staticStyle: {
              width: "80%",
              "margin-left": "8%",
              "margin-bottom": "10px"
            },
            attrs: { size: "medium", placeholder: "查询部门、职员" },
            model: {
              value: _vm.filterText,
              callback: function($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText"
            }
          }),
          _c("el-tree", {
            ref: "teacherTree",
            attrs: {
              data: _vm.treeData,
              props: _vm.props,
              "highlight-current": true,
              "node-key": "id",
              "show-checkbox": "",
              "filter-node-method": _vm.filterNode,
              "default-checked-keys": _vm.teacherIds,
              "default-expanded-keys": _vm.teacherIds
            },
            on: { "check-change": _vm.handleCheckChange }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
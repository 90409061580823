var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-select",
        {
          staticStyle: { width: "100%" },
          attrs: { remote: "", placeholder: _vm.conf.about },
          on: { change: _vm.onChange },
          model: {
            value: _vm.value,
            callback: function($$v) {
              _vm.value = $$v
            },
            expression: "value"
          }
        },
        _vm._l(_vm.options, function(item) {
          return _c("el-option", {
            key: item.value,
            attrs: { label: item.text, value: item.value }
          })
        }),
        1
      ),
      _vm.value != "2"
        ? _c(
            "el-form",
            {
              ref: "minAmountForm",
              attrs: {
                "label-position": "left",
                model: _vm.minAmountModel,
                size: "medium"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-mn" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: {
                        "margin-top": "16px",
                        "margin-bottom": "0px"
                      },
                      attrs: { label: "最小金额", size: "small" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入最小充值金额（留空为不限制）"
                        },
                        on: { blur: _vm.onChange },
                        model: {
                          value: _vm.minAmountModel.minAmount,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.minAmountModel,
                              "minAmount",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "minAmountModel.minAmount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
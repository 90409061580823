import * as types from '@/plugin/api/httpTypes';
var apis = [{
  serviceName: 'paymentItemService',
  methods: [{
    name: 'list',
    url: '/paymentItem/method/list',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'getById',
    url: '/paymentItem/method/{id}',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'upById',
    url: '/paymentItem/method/modify/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'add',
    url: '/paymentItem/add',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'delete',
    url: '/paymentItem/method/delete/{id}',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'deleteAll',
    url: '/paymentItem/method/deleteAll',
    type: types.POST,
    testReturn: {}
  }, {
    name: 'findPaymentChanneAll',
    url: '/paymentItem/method/findPaymentChanneAll',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findCASHPaymentChanneAll',
    url: '/paymentItem/method/findCASHPaymentChanneAll',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'all',
    url: '/paymentItem/method/all',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findSuitableApplyAll',
    url: '/paymentItem/method/findSuitableApplyAll',
    type: types.GET,
    testReturn: {}
  }, {
    name: 'findPaymentChanneByItemId',
    url: '/paymentItem/method/findPaymentChanne/{id}',
    type: types.GET,
    testReturn: {}
  }]
}];
export default apis;